import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row
} from "reactstrap";
import logo from "../../../assets/img/huddlespot-logo.png";
import { fotgotPassword } from "../../../http/http-calls";
import { ToastsStore } from "react-toasts";
class ForgotPassword extends Component {
  state = {
    admin: {
      handle: ""
    },
    errors: {},
    isDirty: {
      handle: false
    },
    loading: false
  };
  /**
   * To go back to login
   */
  _backToLogin = () => {
    this.props.history.push("login");
  };

  /**
   * To handle in input change and set the value to state
   */
  _handleOnChange = ({ currentTarget }) => {
    const { admin, isDirty } = this.state;
    admin[currentTarget.name] = currentTarget.value;
    isDirty[currentTarget.name] = true;
    this.setState({ admin });
    this._validateForm();
  };

  /**
   * To handle the form submit and validate it
   */
  _handleOnSubmit = event => {
    event.preventDefault();
    const { isDirty } = this.state;
    isDirty.handle = true;
    this.setState({ isDirty });
    console.log(this.state.isDirty);
    let errors = this._validateForm();
    console.log(errors);
    if (!errors) {
      console.log("Make API call");
      this._fotgotPassword();
    }
  };

  /**
   * To validate the form
   */
  _validateForm() {
    const { admin, errors, isDirty } = this.state;
    // console.log(admin, isDirty);
    Object.keys(admin).forEach(each => {
      if (each === "handle" && isDirty.handle) {
        if (!admin.handle.trim().length) {
          errors.handle = "Email is Required";
        } else if (
          admin.handle.trim().length &&
          !new RegExp(
            "^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$"
          ).test(admin.handle)
        ) {
          errors.handle = "Please enter valid Email";
        } else {
          delete errors[each];
          isDirty.handle = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  /**
   * To manage the loading
   */
  _manageLoading = value => {
    let { loading } = this.state;
    loading = value;
    this.setState({ loading });
  };

  /**
   * Make forgot password api call
   */
  _fotgotPassword = () => {
    this._manageLoading(true);
    fotgotPassword(this.state.admin).then(
      response => {
        console.log(response);
        ToastsStore.success("", 3000);
        this._manageLoading(false);
      },
      error => {
        console.log(error);
        ToastsStore.error(error.reason, 3000);
        this._manageLoading(false);
      }
    );
  };
  render() {
    return (
      <div>
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="5">
                <CardGroup>
                  <Card className="p-4 login-page">
                    <CardBody>
                      <Form onSubmit={this._handleOnSubmit} noValidate>
                        <p className="logo">
                          <img src={logo} alt="huddle spot logo" />
                        </p>
                        <h2>Forgot Password</h2>
                        <div className="mb-3">
                          <InputGroup className="">
                            <Input
                              type="text"
                              placeholder="Enter email"
                              autoComplete="off"
                              name="handle"
                              onChange={this._handleOnChange}
                              className={
                                this.state.errors && this.state.errors.handle
                                  ? "invalid-input"
                                  : "valid-input"
                              }
                            />
                          </InputGroup>
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.handle}
                            </div>
                          )}
                        </div>

                        <Row>
                          <Col xs="12" className="text-right forgot-pass">
                            <Button
                              type="button"
                              color="link"
                              className="px-0"
                              onClick={this._backToLogin}
                            >
                              Back to login
                            </Button>
                          </Col>
                          <Col xs="12">
                            <Button
                              type="submit"
                              color="primary"
                              className="px-0"
                              disabled={this.state.loading}
                            >
                               { this.state.loading && <i className="fa fa-spinner fa-spin mr5"/>}
                              Send
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
        <footer className="app-footer app-new-footer">
          <span>
            Powered by{" "}
            <a
              href="https://www.logic-square.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Logic Square Technologies
            </a>
          </span>
        </footer>
      </div>
    );
  }
}

export default ForgotPassword;
