import {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  // makeDeleteRequests
} from "./http-service";
import config from "../config/index";

export const login = ({ handle, password }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseUrl + "login", false, {
      handle,
      password,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const fotgotPassword = ({ handle }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseUrl + "sendchangepassmail", false, {
      handle,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
// add new User
export const addUser = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.userBaseUrl + "signup", false, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllRenters = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseUrl + "renters", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getUserDetails = (id, data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseUrl + "user/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const changeUserStatus = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseUrl + "user/change/status/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateUser = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseUrl + "user/update/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllSettings = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseUrl + "settings", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateAllSettings = (data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseUrl + "update/settings", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllAmenities = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseUrl + "facilities", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addAmenity = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseUrl + "add/facility", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateAmenity = (data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseUrl + "/edit/facility/" + data._id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteAmenity = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseUrl + "delete/facility/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllRentees = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseUrl + "rentees", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const googlePlaceSearch = (searchValue) => {
  return new Promise((resolve, reject) => {
    if (searchValue) {
      const searchedPlaces = [];
      let config = {
        types: ["(cities)"],
        input: searchValue,
      };
      const googleService = new window.google.maps.places.AutocompleteService();
      googleService.getPlacePredictions(config, (predictions) => {
        console.log("Predictions", predictions);
        if (predictions) {
          predictions.forEach((prediction) => {
            const country =
              prediction.structured_formatting.secondary_text &&
              (prediction.structured_formatting.secondary_text.split(", ")[1] ||
                prediction.structured_formatting.secondary_text.split(", ")[0]);
            const location =
              prediction.structured_formatting.main_text + ", " + country;
            if (
              !searchedPlaces.includes(location) &&
              location !== searchValue
            ) {
              searchedPlaces.push({
                location: location,
                placeId: prediction.place_id,
              });
            }

            // searchedPlaces.push(prediction);
          });
        }
        resolve(searchedPlaces);
      });
    } else {
      resolve([]);
    }
  });
};
export const googleLocationSearch = (searchValue) => {
  return new Promise((resolve, reject) => {
    if (searchValue) {
      const searchedPlaces = [];
      let config = {
        types: ["address"],
        input: searchValue,
      };
      const googleService = new window.google.maps.places.AutocompleteService();
      googleService.getPlacePredictions(config, (predictions) => {
        console.log("Predictions", predictions);
        resolve(predictions ?? []);
      });
    } else {
      resolve([]);
    }
  });
};
function onSelectAddress(placeId, callback) {
  var geocoder = new window.google.maps.Geocoder();
  geocoder.geocode({ placeId: placeId }, function (results, status) {
    if (status == window.google.maps.GeocoderStatus.OK) {
      callback({
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng(),
      });
    } else {
      callback(null);
    }
  });
}

// add new Space
export const addProperty = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.userBaseUrl + "property", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllProperties = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseUrl + "properties", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const changePropertyStatus = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseUrl + "property/change/status/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getPropertyDetails = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseUrl + "property/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllBookings = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseUrl + "bookings", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateProperty = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseUrl + "update/property/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getBookingDetails = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseUrl + "booking/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const cancelBooking = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(config.baseUrl + "booking/cancel/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllChatThreads = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseUrl + "chat/threads", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getChatHistory = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseUrl + `chat/thread/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getVimeoLink = (link) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseUrl + "property/video/upload", true, link)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addCredit = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseUrl + "user/credits/add", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getWeeklyPayoutHistory = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseUrl + "weeklyPayout/report", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getBookingModificationList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseUrl + "booking/modification/list", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getBookingModificationDetails = (bookingRequestId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      config.baseUrl + "booking/modification/details/" + bookingRequestId,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const getBookingCancellationList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(config.baseUrl + "booking/cancellation/list", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const getBookingCancellationDetails = (bookingCancellationRequestId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      config.baseUrl +
        "booking/cancellation/details/" +
        bookingCancellationRequestId,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const takeActionOnBookingModification = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseUrl + "booking/modification", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const takeActionOnBookingCancellation = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseUrl + "booking/cancellation", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const AddMultipleProvidersValidate = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseUrl + "rentee/check/exist", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const AddMultipleProviders = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(config.baseUrl + "create/rentee", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
