import React, { Component } from "react";
import { format } from "date-fns";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  Button,
  Label,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AppSwitch } from "@coreui/react";
import { getUserDetails, changeUserStatus } from "../../../http/http-calls";
import { ToastsStore } from "react-toasts";
class ViewRenteeDetails extends Component {
  state = {
    providerDetails: null,
    loadingData: false,
    changeStatusLoading: false,
    subRentees: [],
  };

  /**
   * To go to edit provider page
   * @memberof ViewRenteeDetails
   */
  _gotoEditProvider = () => {
    this.props.history.push(
      "../edit-provider/" + this.state.providerDetails._id
    );
  };

  /**
   * To get the provider details
   * @memberof ViewRenteeDetails
   */
  _getProviderDetails = (id) => {
    this._manageLoading("data", true);
    getUserDetails(id, { type: "Rentee" }).then(
      (response) => {
        console.log(response);
        this.setState({ providerDetails: response.user });
        this.setState({ subRentees: response.subRentees });
        this._manageLoading("data", false);
      },
      (error) => {
        console.log(error);
        this._manageLoading("data", false);
        ToastsStore.error(error.reason, 3000);
      }
    );
  };
  /**
   *

   * @memberof ViewRenteeDetails
   */
  _manageLoading = (key, value) => {
    let { loadingData, changeStatusLoading } = this.state;
    if (key === "data") {
      loadingData = value;
    } else if (key === "change-status") {
      changeStatusLoading = value;
    }
    this.setState({ loadingData, changeStatusLoading });
  };

  componentDidMount() {
    console.log(this.props.match.params.id);
    this._getProviderDetails(this.props.match.params.id);
  }

  _formatDate(date) {
    return format(new Date(date), "MMM d, yyyy");
  }

  _onStatusChange = () => {
    let { providerDetails } = this.state;
    providerDetails.isActive = !providerDetails.isActive;
    this._manageLoading("change-status", true);
    changeUserStatus(providerDetails._id, {
      status: providerDetails.isActive,
    }).then(
      (response) => {
        console.log(response);
        this.setState({ providerDetails });
        this._manageLoading("change-status", false);
        ToastsStore.success("Status updated successfully!", 3000);
      },
      (error) => {
        console.log(error);
        this._manageLoading("change-status", false);
        ToastsStore.error(error.reason, 3000);
      }
    );
  };

  _calculateCredits = () => {
    let totalCredits = this.state.providerDetails.credits.reduce(
      (acc, each) => {
        return (acc = acc + each.amount);
      },
      0
    );
    return Math.abs(totalCredits);
  };

  _backToList = () => {
    this.props.history.push("../providers");
  };

  _goToProperty = (id) => {
    this.props.history.push("../place-details/" + id);
  };

  render() {
    let { providerDetails, subRentees } = this.state;
    return (
      <div className="animated fadeIn">
        <Row className="table-panel booking-panel">
          <Col xs="12" sm="6">
            <div className="page-heading">
              <h2>View Provider</h2>
            </div>
            {!this.state.loadingData && providerDetails && (
              <Card>
                <CardHeader>
                  <strong>
                    {providerDetails.name.full}
                    {/* {this._calculateCredits() !== 0 && <span className="dabited">
                      <em>-</em> $ {this._calculateCredits()} <em>Pending</em>
                    </span>} */}
                  </strong>
                  <div>
                    <Button color="secondary mr-3" onClick={this._backToList}>
                      <i className="fa fa-arrow-left"></i> &nbsp; Back to List
                    </Button>
                    <Button color="primary" onClick={this._gotoEditProvider}>
                      Edit
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="6">
                      <img
                        src={
                          providerDetails.pictureUrl ||
                          "../../assets/img/avatars/user.png"
                        }
                        className="img-avatar"
                        alt="provider"
                        width="70"
                      />
                    </Col>
                    <Col xs="6" className="app-switch-panel">
                      <span>Status : </span>
                      <AppSwitch
                        className={"mx-1 details-status-switch"}
                        variant={"3d"}
                        outline={"alt"}
                        color={"primary"}
                        label
                        dataOn={"Active"}
                        dataOff={"Inactive"}
                        checked={providerDetails.isActive}
                        onChange={this._onStatusChange}
                        disabled={this.state.changeStatusLoading}
                      />
                      {/* <span></span>Active */}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="name">Name</Label>
                        <p className="view-text">{providerDetails.name.full}</p>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="name">Email</Label>
                        <p className="view-text">{providerDetails.email}</p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="name">Phone</Label>
                        <p className="view-text">
                          {providerDetails.phone
                            ? `${providerDetails.countryCode} ${providerDetails.phone}`
                            : "-"}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="name">Date of Birth</Label>
                        <p className="view-text">
                          {providerDetails.dateOfBirth
                            ? this._formatDate(providerDetails.dateOfBirth)
                            : "-"}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                  <Col xs="6">
                    <FormGroup>
                      <Label htmlFor="name">Company</Label>
                      <p className="view-text">{providerDetails.companyName}</p>
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <Label htmlFor="name">Role</Label>
                      <p className="view-text">{providerDetails.designation}</p>
                    </FormGroup>
                  </Col>
                </Row> */}
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="name">Location</Label>
                        <p className="view-text">
                          {providerDetails.address
                            ? `${providerDetails.address.city}, ${providerDetails.address.country}`
                            : "-"}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="name">Languages</Label>
                        <p className="view-text">
                          {providerDetails.languagePreference}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="text-details">
                    <Col xs="6">
                      <p>
                        Bookings
                        {providerDetails._booking &&
                          providerDetails._booking.length !== 0 && (
                            <Link
                              to={`/dashboard/booking?provider=${providerDetails._id}`}
                            >
                              <span>{providerDetails._booking.length}</span>
                            </Link>
                          )}
                        {providerDetails._booking.length === 0 && (
                          <em className="no-bookings">No Bookings yet.</em>
                        )}
                      </p>
                    </Col>
                    <Col xs="6">
                      <p>
                        Places
                        {providerDetails._property &&
                          providerDetails._property.length !== 0 && (
                            <Link
                              to={`/dashboard/properties?provider=${providerDetails._id}`}
                            >
                              <span>{providerDetails._property.length}</span>
                            </Link>
                          )}
                        {providerDetails._property.length === 0 && (
                          <em className="no-bookings">No Properties yet.</em>
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row className="text-details">
                    <Col xs="12">
                      {" "}
                      <p>Team Member list</p>
                    </Col>
                    <Col xs="12">
                      <Table responsive bordered style={{ fontWeight: 500 }}>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Property Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subRentees.map((member) => (
                            <tr key={member._id}>
                              <td>{member.name.full}</td>
                              <td>{member.email}</td>
                              <td>
                                {member.countryCode} {member.phone}
                              </td>
                              <td style={{ fontSize: 14 }}>
                                {member._propertyAccess.map((property) => (
                                  <a
                                    href=" "
                                    onClick={() =>
                                      this._goToProperty(property._id)
                                    }
                                    key={property._id}
                                  >
                                    {property.buildingName}
                                  </a>
                                ))}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col xs="6">
                      <Label htmlFor="photoIdProofUrl">Photo ID Proof</Label>
                      <p>
                        <a
                          href={providerDetails.photoIdProofUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                         {providerDetails.photoIdProofUrl && providerDetails.photoIdProofUrl.split('/').pop()}
                        </a>
                      </p>
                    </Col> */}
                  </Row>
                </CardBody>
              </Card>
            )}
            {this.state.loadingData && (
              <Card>
                <CardBody>
                  <div className="loading-section no-border">
                    <i className="fa fa-spinner fa-spin"></i> &nbsp; Loading
                    Provider Details..
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default ViewRenteeDetails;
