import React, { Component } from "react";
import {
  // Badge,
  Card,
  CardBody,
  // CardHeader,
  Col,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  // FormGroup,
  // FormText,
  // FormFeedback,
  // InputGroupAddon,
  // InputGroupText,
  // Label,
  Row,
  Table,
  // Form,
  // Input,
  Button,
  // select,
  // InputGroup
} from "reactstrap";
import { getAllAmenities, deleteAmenity } from '../../../http/http-calls';
import AddEditFacility from './AddEditFacility';
import DeleteFacility from './DeleteFacility';
import BasicSettings from './BasicSettings';
import { ToastsStore } from "react-toasts";

class Facilities extends Component {
  state = {
    modal: {
      isOpen: false,
      type:'add',
      initialData: {}
    },
    deleteModal:{
      isOpen: false
    },
    itemToBeDeleted:{},
    amenities:[],
    loadingAmenities: true
  };

  onToggle=(type)=> {
    console.log(type);
    let {modal} = this.state;
    modal.isOpen = !modal.isOpen;
    // modal.initialData= {};
    if(type==='add') {
      modal.type = type;
    } else if(type==='reload'){
      this._getAllAmenities();
    }
    this.setState({
      modal
    });
  }

  _onDeleteModalToggle = (type) => {
    console.log(type);
    let {deleteModal} = this.state;
    deleteModal.isOpen = !deleteModal.isOpen;
    if(type === 'delete'){
      this._deleteAmenity(deleteModal, this.state.itemToBeDeleted._id);
    } else{
      this.setState({deleteModal});
    }
  }

  _manageLoading =(status) =>{
    let {loadingAmenities} = this.state;
    loadingAmenities = status;
    this.setState({loadingAmenities});
  }

  _getAllAmenities = () => {
    this._manageLoading(true);
    getAllAmenities()
    .then((response) =>{
      console.log(response);
      this._manageLoading(false);
      this.setState({amenities:response.facilities});
    }, (error)=>{
      console.log(error);
      this._manageLoading(false);
      ToastsStore.error(error.reason, 1500);
    })
  }

  _openEditModal=(item)=> {
    console.log('here====>', item);
    let {modal} = this.state;
    modal.initialData = {
      title: item.title,
      iconUrl: item.iconUrl,
      _id: item._id
    }
    modal.isOpen = !modal.isOpen;
    modal.type = 'edit';
    this.setState({modal});
  }

  _openDeleteModal=(item)=> {
    let {deleteModal, itemToBeDeleted} = this.state;
    itemToBeDeleted = item;
    deleteModal.isOpen = !deleteModal.isOpen;
    console.log(itemToBeDeleted);
    this.setState({deleteModal, itemToBeDeleted});
  }

  _deleteAmenity =(deleteModal, id)=>{
    deleteAmenity(id)
    .then((response)=>{
      console.log(response);
      this.setState({deleteModal});
      this._getAllAmenities();
      ToastsStore.success('Amenity deleted successfully!', 3000);
    },(error)=>{
      console.log(error);
      ToastsStore.success(error.reason, 3000);
    })
  }

  componentDidMount(){
    this._getAllAmenities();
  }


  render() {
    return (
      <div className="animated fadeIn">
        <div className="page-heading"><h2>Basic Settings</h2></div> 
        <Row className="table-panel facilities-table basic-settings-panel">
          <BasicSettings></BasicSettings>                    
        </Row>
        <Row className="table-panel amenity-table basic-settings-panel">
          <Col xs="6" lg="12">
          <div className="page-heading"><h4>Amenities {this.state.loadingAmenities}</h4></div>
            <Card>
              {!this.state.loadingAmenities && <CardBody>
                <div className="table-top">
                  <Button color="primary" onClick={()=>{this.onToggle('add')}}>+ Add</Button>
                </div>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th>Name in English</th>
                      <th>Name in Spanish</th>
                      <th>Name in Portuguese</th>
                      <th>Added By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.amenities.map((item, index)=>
                    <tr key={index}>
                      <td>
                        {item.iconUrl && <img src={item.iconUrl} alt="amenity" width="30" className="mr5"></img>}&nbsp;
                        <span>{item.title.en}</span>  
                      </td>
                      <td>{item.title.es}</td>
                      <td>{item.title.pt}</td>
                      <td>{item._addedBy ? item._addedBy.name.full : 'N/A'}</td>
                      <td>
                        <Button type="button" color="warning" onClick={()=>this._openEditModal(item)}>Edit</Button>
                        <Button type="button" color="danger" onClick={()=>{this._openDeleteModal(item)}}>Delete</Button>
                      </td>
                    </tr>
                  )}
                  </tbody>
                </Table>
              </CardBody>}
              {this.state.loadingAmenities && 
              <CardBody> 
                <div className="loading-section">
                  <i className="fa fa-spinner fa-spin"></i> &nbsp;
                  Loading Amenities..
                </div> 
                </CardBody>}
            </Card>
          </Col>
        </Row>
        <AddEditFacility isOpen={this.state.modal.isOpen} initialData={this.state.modal.initialData} modalType={this.state.modal.type} toggle={this.onToggle}></AddEditFacility>
        <DeleteFacility data={this.state.itemToBeDeleted} isOpen={this.state.deleteModal.isOpen} toggle={this._onDeleteModalToggle}/>
      </div>
    );
  }
}

export default Facilities;
