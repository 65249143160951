import React, { Component } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";

// const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
import DefaultLayout from "./containers/DefaultLayout/DefaultLayout";

// Pages
// const Login = React.lazy(() => import('./views/Pages/Login'));
// const Register = React.lazy(() => import('./views/Pages/Register'));
// const Page404 = React.lazy(() => import('./views/Pages/Page404'));
// const Page500 = React.lazy(() => import('./views/Pages/Page500'));
import Login from "./views/Pages/Login/Login";
import ForgotPassword from "./views/Pages/ForgotPassword/ForgotPassword";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import PublicRoute from "./components/PublicRoute";

class App extends Component {
  render() {
    console.log("==>", this.props);
    return (
      // <HashRouter>
      //     <React.Suspense fallback={loading()}>
      //       <Switch>
      //         <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
      //         <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
      //         <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
      //         <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
      //         <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
      //       </Switch>
      //     </React.Suspense>
      // </HashRouter>
      <BrowserRouter>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <div className="App">
          {/* <Navbar /> */}
          <div className="">
            <Switch>
              {/* <Route path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} /> */}
              <PublicRoute
                exact
                path="/login"
                component={Login}
                redirectRoute={"/dashboard/renters"}
              />
              <PublicRoute
                exact
                path="/forgot-password"
                component={ForgotPassword}
                redirectRoute={"/dashboard/renters"}
              />
              <Route path="/dashboard" component={DefaultLayout} />
              <Route path="/" component={DefaultLayout} />
              <Route path="*" render={() => <Redirect to="/" />} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
