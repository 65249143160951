export default {
  // baseUrl: "http://178.128.127.115:9000/admin/v1/", //dev
  // chatUrl: "http://178.128.127.115:9000/faye", //dev

  // baseUrl: "https://api.thehuddlespot.com/admin/v1/", //prod
  // chatUrl: "https://api.thehuddlespot.com/faye", //prod

  baseUrl: "https://api.thehuddlespot.com/admin/v1/", //prod
  chatUrl: "https://api.thehuddlespot.com/faye", //prod
  userBaseUrl: "https://api.thehuddlespot.com/api/v1/", //prod

  // baseUrl: "http://localhost:3000/admin/v1/", //dev
  // chatUrl: "http://localhost:3000/faye", //dev

  // baseUrl: "http://192.168.0.126:3000/admin/v1/", //local
  // chatUrl: "http://192.168.0.126:3000/faye", //local
  preferenceList: [
    "Hot Desk",
    "Fixed Desk",
    "Private Office",
    "Conference Room",
  ],
  languageList: ["English", "Spanish", "Portuguese"],
  userStatusList: ["Active", "Inactive"],
  cycleTypeList: ["Hourly", "Daily", "Weekly", "Monthly"],
  bookingStatus: [
    "Pending Payment",
    "Payment Failed",
    "Confirmed",
    "In Progress",
    "Cancellation Request Pending",
    "Cancelled By Provider",
    "Cancelled By User",
    "Cancelled By Admin",
    "Completed",
  ],
  countryCodeList: ["+1", "+52", "+54", "+91"],
  roles: [
    "Sales",
    "Management",
    "Marketing",
    "Operations",
    "Developer",
    "Community Manager",
    "Designer",
  ],
  languageConfig: [
    { label: "English", value: "en" },
    { label: "Spanish", value: "es" },
    { label: "Portuguese", value: "pt" },
  ],
  bookingRequestStatus: [
    "Requested",
    "Cancelled By Admin",
    "Cancelled by User",
    "Pending Payment",
    "Confirmed",
  ],
  cancellationRequestStatus: [
    "Cancellation Request Pending",
    // "Cancelled By Admin",
    // "Cancelled by User",
    "Cancelled By Provider",
    // "Pending Payment",
    "Confirmed",
  ],
};
