import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  Form,
  Input,
  Button,
  InputGroupAddon,
  InputGroupText,
  Label,
  InputGroup,
} from "reactstrap";
import {
  changeUserStatus,
  googlePlaceSearch,
  addUser,
} from "../../../http/http-calls";
import { uploadFileMultiPart } from "../../../http/http-service";
import { AppSwitch } from "@coreui/react";
import CustomTable from "../../../components/CustomTable";
import { ToastsStore } from "react-toasts";
import config from "../../../config/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class AddRenterDetails extends Component {
  state = {
    renterName: "",
    renterDetails: {
      fullName: "",
      email: "",
      password: "",
      confirmPassword: "",
      countryCode: config.countryCodeList[0],
      phone: "",
      languagePreference: config.languageList[0],
      dateOfBirth: "",
      address: "",
      latitude: "",
      longitude: "",
      companyName: "",
      designation: config.roles[0],
      referralCode: "",
      location: "",
    },
    loadingData: false,
    changeStatusLoading: false,
    updateLoading: false,
    pictureLoading: false,
    photoIdProofLoading: false,
    languageList: config.languageList,
    countryCodeList: config.countryCodeList,
    roles: config.roles,
    errors: {},
    isDirty: {
      fullName: false,
      email: false,
      password: false,
      confirmPassword: false,
      phone: false,
      pictureUrl: false,
      languagePreference: false,
      dateOfBirth: false,
      isActive: false,
      address: false,
      photoIdProofUrl: false,
      companyName: false,
      designation: false,
      referralCode: false,
      countryCode: false,
    },
    showSuggestionList: false,
    locationSuggestionList: [],
  };

  _manageLoading = (key, value) => {
    let {
      loadingData,
      changeStatusLoading,
      updateLoading,
      pictureLoading,
      photoIdProofLoading,
    } = this.state;
    if (key === "data") {
      loadingData = value;
    } else if (key === "change-status") {
      changeStatusLoading = value;
    } else if (key === "update") {
      updateLoading = value;
    } else if (key === "pictureUrl") {
      pictureLoading = value;
    } else if (key === "photoIdProofUrl") {
      photoIdProofLoading = value;
    }
    this.setState({
      loadingData,
      changeStatusLoading,
      updateLoading,
      pictureLoading,
      photoIdProofLoading,
    });
  };

  //   _onStatusChange = () => {
  //     let { renterDetails } = this.state;
  //     renterDetails.isActive = !renterDetails.isActive;
  //     this._manageLoading("change-status", true);
  //     changeUserStatus(renterDetails._id, {
  //       status: renterDetails.isActive,
  //     }).then(
  //       (response) => {
  //         console.log(response);
  //         this.setState({ renterDetails });
  //         this._manageLoading("change-status", false);
  //         ToastsStore.success("Status updated successfully!", 3000);
  //       },
  //       (error) => {
  //         console.log(error);
  //         this._manageLoading("change-status", false);
  //         ToastsStore.error(error.reason, 3000);
  //       }
  //     );
  //   };

  _handleOnChange = ({ currentTarget }) => {
    const { renterDetails, isDirty } = this.state;
    renterDetails[currentTarget.name] = currentTarget.value;
    isDirty[currentTarget.name] = true;
    this.setState({ renterDetails });
    this._validateForm();
  };

  _handleDateChange = (date) => {
    const { renterDetails, isDirty } = this.state;
    renterDetails.dateOfBirth = date;
    isDirty.dateOfBirth = true;
    this.setState({ renterDetails });
    this._validateForm();
  };

  _handleOnSubmit = (event) => {
    event.preventDefault();
    let { isDirty } = this.state;
    isDirty = {
      fullName: false,
      email: false,
      phone: false,
      pictureUrl: false,
      spacePreference: false,
      languagePreference: false,
      companyName: false,
      designation: false,
      dateOfBirth: false,
      isActive: false,
      address: false,
    };
    this.setState({ isDirty });
    console.log(this.state.isDirty);
    let errors = this._validateForm();
    console.log(errors);
    // this.setState({errors});
    console.log(errors);
    if (!errors) {
      let data = Object.assign({}, this.state.renterDetails);
      data.name = {
        first: data.fullName.split(" ")[0],
        last: data.fullName.split(" ")[1],
      };
      data.dateOfBirth =
        data.dateOfBirth.getFullYear() +
        "-" +
        (parseInt(data.dateOfBirth.getMonth()) + 1) +
        "-" +
        data.dateOfBirth.getDate();
      data.address = {
        city: data.address.split(", ")[0],
        country: data.address.split(", ")[1],
      };
      data.userType = "Renter";
      data.isSocial = false;
      data.isFirstLogin = false;
      delete data.fullName;
      console.log("Make API call", JSON.stringify(data));
      this._updateUser(data);
    }
  };

  _validateForm() {
    const { renterDetails, errors, isDirty } = this.state;
    // console.log(renterDetails, isDirty);
    Object.keys(renterDetails).forEach((each) => {
      if (each === "fullName") {
        if (!renterDetails.fullName.trim().length) {
          errors.fullName = "Name is Required";
        } else {
          delete errors[each];
          isDirty.fullName = false;
        }
      } else if (each === "email") {
        if (!renterDetails.email.trim().length) {
          errors[each] = "Email is Required";
        } else if (
          renterDetails.email.trim().length &&
          !new RegExp(
            "^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$"
          ).test(renterDetails.email)
        ) {
          errors.email = "Please enter valid Email";
        } else {
          delete errors[each];
          isDirty.email = false;
        }
      } else if (each === "phone") {
        if (!renterDetails.phone.trim().length) {
          errors.phone = "Phone number is Required";
        }
        // else if(renterDetails.phone.trim().length !== 10){
        //   errors.phone = "Phone number must be of 10 digits";
        // }
        else {
          delete errors[each];
          isDirty.phone = false;
        }
      } else if (each === "dateOfBirth") {
        if (
          typeof renterDetails.dateOfBirth !== "object" ||
          !renterDetails.dateOfBirth
        ) {
          errors.dateOfBirth = "Date of birth is Required";
        } else if (renterDetails.dateOfBirth > new Date()) {
          errors.dateOfBirth = "Date of birth must be before today";
        } else {
          delete errors[each];
          isDirty.dateOfBirth = false;
        }
      } else if (each === "companyName") {
        if (!renterDetails.companyName.trim().length) {
          errors.companyName = "Company Name is Required";
        } else {
          delete errors[each];
          isDirty.companyName = false;
        }
      } else if (each === "designation") {
        if (!renterDetails.designation.trim().length) {
          errors.designation = "Designation is Required";
        } else {
          delete errors[each];
          isDirty.designation = false;
        }
      } else if (each === "address") {
        if (!renterDetails.address.trim().length) {
          errors.address = "Location is Required";
        } else {
          delete errors[each];
          isDirty.address = false;
        }
      } else if (each === "password") {
        if (!renterDetails.password.trim().length) {
          errors.password = "Password required";
        } else if (renterDetails.password.trim().length < 6) {
          errors.password = "Password must be atleast 6 characters";
        } else {
          delete errors[each];
          isDirty.password = false;
        }
      } else if (each === "confirmPassword") {
        if (!renterDetails.confirmPassword.trim().length) {
          errors.confirmPassword = "Please confirm password";
        } else if (
          renterDetails.password.trim() !== renterDetails.confirmPassword.trim()
        ) {
          errors.confirmPassword = "Password not matching";
        } else {
          delete errors[each];
          isDirty.confirmPassword = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  _updateUser = (data) => {
    this._manageLoading("update", true);
    addUser(data).then(
      (response) => {
        console.log(response);
        this._manageLoading("update", false);
        ToastsStore.success("Successfully added new provider!", 3000);
        this.setState({
          renterDetails: {
            fullName: "",
            email: "",
            password: "",
            confirmPassword: "",
            countryCode: config.countryCodeList[0],
            phone: "",
            languagePreference: config.languageList[0],
            dateOfBirth: "",
            address: "",
            latitude: "",
            longitude: "",
            companyName: "",
            designation: config.roles[0],
            referralCode: "",
            location: "",
          },
        });
        // this.props.history.push(
        //   "../provider-details/" + this.state.renterDetails._id
        // );
      },
      (error) => {
        console.log(error);
        ToastsStore.error(error.reason.en || "Something Went Wrond!", 3000);
        this._manageLoading("update", false);
      }
    );
    // this.props.history.push("provider-details");
  };

  _searchPlace = ({ currentTarget }) => {
    const { renterDetails, isDirty } = this.state;
    console.log(currentTarget.value);
    renterDetails.address = currentTarget.value;
    isDirty.address = true;
    this.setState({ renterDetails });
    this._validateForm();
    googlePlaceSearch(renterDetails.address).then((response) => {
      console.log(response);
      this.setState({
        locationSuggestionList: response,
        showSuggestionList: true,
      });
    });
  };

  _selectLocation = (each) => {
    console.log(each);
    let { renterDetails, showSuggestionList, isDirty } = this.state;
    renterDetails.address = each.location;
    renterDetails.location = each.location;

    this.onSelectAddress("placeId", each.placeId, function (location) {
      //Do something with location
      if (location) {
        renterDetails.latitude = location.latitude;
        renterDetails.longitude = location.longitude;
      }
    });
    showSuggestionList = false;
    isDirty.address = true;
    this.setState({ renterDetails, showSuggestionList });
    this._validateForm();
  };

  //   to find lat and lng from place_id
  onSelectAddress(type, content, callback) {
    var geocoder = new window.google.maps.Geocoder();
    if (type === "placeId") {
      geocoder.geocode({ placeId: content }, function (results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
          callback({
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          });
        } else {
          callback(null);
        }
      });
    } else if (type === "address") {
      geocoder.geocode({ address: content }, function (results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
          callback({
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          });
        } else {
          callback(null);
        }
      });
    }
  }

  //   _openFileBrowser = () => {
  //     this.refs.fileUploader.click();
  //   };

  //   _imageChange = (event, key) => {
  //     // console.log(event.target.files[0]);
  //     const file = event.target.files[0];
  //     if (file) {
  //       this._uploadImage(event.target.files[0], key);
  //     } else {
  //       const { renterDetails } = this.state;
  //       renterDetails[key] = "";
  //       this.setState({ renterDetails });
  //     }
  //   };

  //   _uploadImage = (file, key) => {
  //     var fd = new FormData();
  //     fd.append("file", file);
  //     this._manageLoading(key, true);
  //     uploadFileMultiPart(false, fd).then(
  //       (response) => {
  //         console.log(response.secure_url);
  //         const { renterDetails } = this.state;
  //         renterDetails[key] = response.secure_url;
  //         this.setState({ renterDetails });
  //         this._manageLoading(key, false);
  //         // this._validateForm();
  //       },
  //       (error) => {
  //         console.log(error);
  //         this._manageLoading(key, false);
  //         ToastsStore.error(error.reason || "Something Went Wrong!", 3000);
  //       }
  //     );
  //   };

  _backToDetails = () => {
    this.props.history.push("renters");
  };
  render() {
    let { renterDetails } = this.state;
    return (
      <div className="animated fadeIn">
        <div className="page-heading">
          <h2>Add Renter</h2>
        </div>
        <Row className="table-panel booking-panel">
          <Col xs="12" sm="6">
            {!this.state.loadingData && renterDetails && (
              <Card>
                <CardHeader>
                  <strong>{this.state.renterName}</strong>
                  <Button color="secondary" onClick={this._backToDetails}>
                    <i className="fa fa-arrow-left"></i> &nbsp; Back
                  </Button>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this._handleOnSubmit} noValidate>
                    {/* <Row>
                      <Col xs="6">
                        <img
                          src={
                            renterDetails.pictureUrl ||
                            "../../assets/img/avatars/user.png"
                          }
                          alt="provider"
                          width="70"
                          className={
                            this.state.pictureLoading
                              ? "disbale-file-select"
                              : "img-avatar"
                          }
                        />
                        {this.state.pictureLoading && (
                          <i className="fa fa-spinner fa-spin uploading-image-loading"></i>
                        )}
                        {!this.state.pictureLoading && (
                          <span
                            onClick={this._openFileBrowser}
                            className="update-profile-image"
                          >
                            <i className="fa fa-pencil"></i>
                            <input
                              type="file"
                              id="file"
                              ref="fileUploader"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) =>
                                this._imageChange(e, "pictureUrl")
                              }
                            />
                          </span>
                        )}
                      </Col>
                      <Col xs="6" className="app-switch-panel">
                        <span>Status :</span>
                        <AppSwitch
                          className={"mx-1 details-status-switch"}
                          variant={"3d"}
                          outline={"alt"}
                          color={"primary"}
                          label
                          dataOn={"Active"}
                          dataOff={"Inactive"}
                          checked={renterDetails.isActive}
                          onChange={this._onStatusChange}
                          disabled={this.state.changeStatusLoading}
                        />
                      </Col>
                    </Row> */}
                    <Row>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="name">Name</Label>
                          <Input
                            type="text"
                            id="name"
                            name="fullName"
                            placeholder="Enter your name"
                            value={renterDetails.fullName}
                            onChange={this._handleOnChange}
                            className={
                              this.state.errors && this.state.errors.fullName
                                ? "invalid-input"
                                : "valid-input"
                            }
                          />
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.fullName}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="name">Email</Label>
                          <Input
                            type="text"
                            id="name"
                            name="email"
                            placeholder="Enter your Email"
                            value={renterDetails.email}
                            onChange={this._handleOnChange}
                            autoComplete="off"
                            className={
                              this.state.errors && this.state.errors.email
                                ? "invalid-input"
                                : "valid-input"
                            }
                          />
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.email}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="name">Password</Label>
                          <Input
                            type="text"
                            id="password"
                            name="password"
                            placeholder="Enter password"
                            value={renterDetails.password}
                            onChange={this._handleOnChange}
                            className={
                              this.state.errors && this.state.errors.password
                                ? "invalid-input"
                                : "valid-input"
                            }
                          />
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.password}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="name">Confirm Password</Label>
                          <Input
                            type="text"
                            id="confirmPassword"
                            name="confirmPassword"
                            placeholder="Confirm password"
                            value={renterDetails.confirmPassword}
                            onChange={this._handleOnChange}
                            autoComplete="off"
                            className={
                              this.state.errors &&
                              this.state.errors.confirmPassword
                                ? "invalid-input"
                                : "valid-input"
                            }
                          />
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.confirmPassword}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="companyName">Company</Label>
                          <Input
                            type="text"
                            id="companyName"
                            placeholder="Enter your Company"
                            required
                            name="companyName"
                            value={renterDetails.companyName}
                            onChange={this._handleOnChange}
                            className={
                              this.state.errors && this.state.errors.companyName
                                ? "invalid-input"
                                : "valid-input"
                            }
                          />
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.companyName}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="designation">Role</Label>
                          {/* <Input
                        type="text"
                        id="designation"
                        placeholder="Enter your Role"
                        name="designation"
                        required
                        value={renterDetails.designation}
                        onChange={this._handleOnChange}
                        className={this.state.errors && this.state.errors.designation ? 'invalid-input' : 'valid-input'}
                      /> */}
                          <Input
                            type="select"
                            name="designation"
                            id="designation"
                            value={renterDetails.designation}
                            onChange={this._handleOnChange}
                          >
                            {this.state.roles.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </Input>
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.designation}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3">
                        <FormGroup>
                          <Label htmlFor="countryCode">Country Code</Label>
                          <FormGroup>
                            <Input
                              type="select"
                              name="countryCode"
                              id="countryCode"
                              value={renterDetails.countryCode}
                              onChange={this._handleOnChange}
                            >
                              {this.state.countryCodeList.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                      <Col xs="3">
                        <FormGroup>
                          <Label htmlFor="name">Phone</Label>
                          <Input
                            type="number"
                            id="name"
                            name="phone"
                            placeholder="Enter your Phone Number"
                            value={renterDetails.phone}
                            onChange={this._handleOnChange}
                            className={
                              this.state.errors && this.state.errors.phone
                                ? "invalid-input"
                                : "valid-input"
                            }
                          />
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.phone}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="dateOfBirth">Date of Birth</Label>
                          <InputGroup
                            className={
                              this.state.errors && this.state.errors.dateOfBirth
                                ? "invalid-input"
                                : "valid-input"
                            }
                          >
                            <DatePicker
                              className="form-control"
                              selected={renterDetails.dateOfBirth}
                              name="dateOfBirth"
                              onSelect={this._handleDateChange}
                              dateFormat="MMM d, yyyy"
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <i className="fa fa-calendar"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.dateOfBirth}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row>
                  <Col xs="6">
                    <FormGroup>
                      <Label htmlFor="name">Company</Label>
                      <Input
                        type="text"
                        id="name"
                        placeholder="Enter your Company"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <Label htmlFor="name">Role</Label>
                      <Input
                        type="text"
                        id="name"
                        placeholder="Enter your Role"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                    <Row>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="name">Location</Label>
                          <InputGroup
                            className={
                              this.state.errors && this.state.errors.address
                                ? "invalid-input"
                                : "valid-input"
                            }
                          >
                            <Input
                              type="text"
                              id="input1-group1"
                              name="input1-group1"
                              placeholder="Enter your Location"
                              autoComplete="off"
                              value={renterDetails.address}
                              onChange={this._searchPlace}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <i className="fa fa-map-marker"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.address}
                            </div>
                          )}
                          {this.state.locationSuggestionList.length !== 0 &&
                            this.state.showSuggestionList && (
                              <ul className="location-suggestion-ul">
                                {this.state.locationSuggestionList.map(
                                  (each) => (
                                    <li
                                      key={each.placeId}
                                      onClick={() => this._selectLocation(each)}
                                    >
                                      <i className="fa fa-map-marker"></i>&nbsp;
                                      {each.location}
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="languagePreference">Languages</Label>
                          <FormGroup>
                            <Input
                              type="select"
                              name="languagePreference"
                              id="languagePreference"
                              value={renterDetails.languagePreference}
                              onChange={this._handleOnChange}
                            >
                              {this.state.languageList.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                      {/* <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="language">Photo ID Proof</Label>
                          <FormGroup>
                            <Input
                              type="file"
                              name="photoIdProofUrl"
                              id="photoIdProofUrl"
                              accept="image/*"
                              disabled={this.state.photoIdProofLoading}
                              onChange={(e) =>
                                this._imageChange(e, "photoIdProofUrl")
                              }
                            ></Input>
                          </FormGroup>
                          {this.state.photoIdProofLoading && (
                            <i className="fa fa-spinner fa-spin uploading-id-loading"></i>
                          )}
                        </FormGroup>
                        {renterDetails.photoIdProofUrl &&
                          !this.state.photoIdProofLoading && (
                            <a
                              href={renterDetails.photoIdProofUrl}
                              target="_blank"
                              download
                              rel="noopener noreferrer"
                            >
                              Photo ID Proof
                            </a>
                          )}
                      </Col> */}
                    </Row>
                    <Row className="submit-panel">
                      <Col xs="12">
                        <FormGroup>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={this.state.updateLoading}
                          >
                            {this.state.updateLoading && (
                              <i className="fa fa-spinner fa-spin mr5"></i>
                            )}
                            Save
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            )}
            {this.state.loadingData && (
              <Card>
                <CardBody>
                  <div className="loading-section no-border">
                    <i className="fa fa-spinner fa-spin"></i> &nbsp; Loading
                    Provider Details..
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddRenterDetails;
