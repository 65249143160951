import React, { Component } from "react";
// import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  // InputGroupAddon, InputGroupText,
  Row,
} from "reactstrap";
import logo from "../../../assets/img/huddlespot-logo.png";
import { ToastsStore } from "react-toasts";
import { login } from "../../../http/http-calls";
class Login extends Component {
  state = {
    admin: {
      handle: "",
      password: "",
    },
    errors: {},
    isDirty: {
      handle: false,
      password: false,
    },
    loading: false,
  };

  /**
   * _handleOnChange
   * To handle input on change and set the values to state
   */
  _handleOnChange = ({ currentTarget }) => {
    const { admin, isDirty } = this.state;
    admin[currentTarget.name] = currentTarget.value;
    isDirty[currentTarget.name] = true;
    this.setState({ admin });
    this._validateForm();
  };

  /**
   * To handle submit of the form and validate it
   */
  _handleOnSubmit = (event) => {
    event.preventDefault();
    const { isDirty } = this.state;
    isDirty.handle = true;
    isDirty.password = true;
    this.setState({ isDirty });
    console.log(this.state.isDirty);
    let errors = this._validateForm();
    console.log(errors);
    // this.setState({errors});
    if (!errors) {
      console.log("Make API call");
      this._login();
    }
  };

  /**
   * To Validate the form and show the error messages
   */
  _validateForm() {
    const { admin, errors, isDirty } = this.state;
    // console.log(admin, isDirty);
    Object.keys(admin).forEach((each) => {
      if (each === "handle" && isDirty.handle) {
        if (!admin.handle.trim().length) {
          errors.handle = "Email is Required";
        } else if (
          admin.handle.trim().length &&
          !new RegExp(
            "^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$"
          ).test(admin.handle)
        ) {
          errors.handle = "Please enter valid Email";
        } else {
          delete errors[each];
          isDirty.handle = false;
        }
      } else if (each === "password" && isDirty.password) {
        if (!admin.password.trim().length) {
          // console.log(admin.password);
          errors[each] = "Password is Required";
        } else {
          delete errors[each];
          isDirty.password = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  /**
   * Make login api call and navigate to dashboard after login
   */
  _login = () => {
    let { admin, loading } = this.state;
    loading = true;
    this.setState({ loading });
    login(admin).then(
      (response) => {
        console.log(response);
        localStorage.huddleSpotAdminToken = response.token;
        loading = false;
        this.setState({ loading });
        ToastsStore.success("Successfully logged in!", 3000);
        this.props.history.push("dashboard/renters");
      },
      (error) => {
        console.log(error);
        loading = false;
        this.setState({ loading });
        ToastsStore.error(error.reason, 1500);
      }
    );
  };

  /**
   * To navigate to Forgot password
   */
  _goToForgotPassword = () => {
    this.props.history.push("forgot-password");
  };

  render() {
    return (
      <div>
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="5">
                <CardGroup>
                  <Card className="p-4 login-page">
                    <CardBody>
                      <Form onSubmit={this._handleOnSubmit} noValidate>
                        <p className="logo">
                          <img src={logo} alt="huddle spot logo" />
                        </p>
                        <h2>Welcome</h2>
                        <div className="mb-3">
                          <InputGroup className="">
                            <Input
                              type="text"
                              placeholder="Enter email"
                              autoComplete="off"
                              name="handle"
                              onChange={this._handleOnChange}
                              className={
                                this.state.errors && this.state.errors.handle
                                  ? "invalid-input"
                                  : "valid-input"
                              }
                            />
                          </InputGroup>
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.handle}
                            </div>
                          )}
                        </div>
                        <div className="mb-3">
                          <InputGroup className="">
                            <Input
                              type="password"
                              placeholder="Enter password"
                              autoComplete="off"
                              name="password"
                              onChange={this._handleOnChange}
                              className={
                                this.state.errors && this.state.errors.password
                                  ? "invalid-input"
                                  : "valid-input"
                              }
                            />
                          </InputGroup>
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.password}
                            </div>
                          )}
                        </div>

                        <Row>
                          <Col xs="12" className="text-right forgot-pass">
                            <Button
                              type="button"
                              color="link"
                              className="px-0"
                              onClick={this._goToForgotPassword}
                            >
                              Forgot password?
                            </Button>
                          </Col>
                          <Col xs="12">
                            <Button
                              type="submit"
                              color="primary"
                              className="px-0"
                              disabled={this.state.loading}
                            >
                              {this.state.loading && (
                                <i className="fa fa-spinner fa-spin mr5" />
                              )}
                              Login
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
        <footer className="app-footer app-new-footer">
          <span>
            Powered by{" "}
            <a
              href="https://www.logic-square.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Logic Square Technologies
            </a>
          </span>
        </footer>
      </div>
    );
  }
}

export default Login;
