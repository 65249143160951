import React, { Component } from "react";
import { format } from "date-fns";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  // FormText,
  // FormFeedback,
  // Input,
  // Img,
  Button,
  // InputGroupAddon,
  // InputGroupButtonDropdown,
  // InputGroupText,
  Label,
  // InputGroup
} from "reactstrap";
// import { AppSwitch } from '@coreui/react'
import { getBookingDetails } from "../../../http/http-calls";
import { Link } from "react-router-dom";
import { ToastsStore } from "react-toasts";
class ViewBooking extends Component {
  state = {
    bookingDetails: null,
    loadingData: false,
  };

  _manageLoading = (key, value) => {
    console.log(key);
    let { loadingData } = this.state;
    if (key === "data") {
      loadingData = value;
    }
    this.setState({ loadingData });
  };

  _getBookingDetails = (id) => {
    this._manageLoading("data", true);
    getBookingDetails(id).then(
      (response) => {
        console.log(response);
        this.setState({ bookingDetails: response.booking });
        this._manageLoading("data", false);
      },
      (error) => {
        console.log(error);
        this._manageLoading("date", false);
      }
    );
  };

  componentDidMount() {
    console.log(this.props.match.params.id);
    this._getBookingDetails(this.props.match.params.id);
  }

  editBookingDetails = () => {
    ToastsStore.info("Under development");
    // this.props.history.push("edit-booking");
  };

  _formatDate(date) {
    return format(new Date(date), "MMM d, yyyy");
  }

  _backToList = () => {
    this.props.history.push("../booking");
  };

  render() {
    let { bookingDetails, loadingData } = this.state;
    return (
      <div className="animated fadeIn">
        <Row className="table-panel booking-panel edit-booking">
          <Col xs="12" sm="6">
            <div className="page-heading">
              <h2>View Booking {loadingData}</h2>
            </div>
            {!loadingData && bookingDetails && (
              <Card>
                <CardHeader>
                  <strong>Booking</strong>
                  <Button color="secondary mr-3" onClick={this._backToList}>
                    <i className="fa fa-arrow-left"></i> &nbsp; Back to List
                  </Button>
                  {/* <Button color="primary" onClick={this.editBookingDetails}>
                    Edit
                  </Button> */}
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Place</Label>
                        <p className="view-text">
                          <Link
                            to={`/dashboard/place-details/${bookingDetails._space._property._id}`}
                          >
                            {bookingDetails._space._property.buildingName}
                          </Link>
                        </p>
                        <p>
                          {bookingDetails._space._property.streetAddress},{" "}
                          {bookingDetails._space._property.city},{" "}
                          {bookingDetails._space._property.country}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Renter</Label>
                        <p className="view-text">
                          <Link
                            to={`/dashboard/renter-details/${bookingDetails._renter?._id}`}
                          >
                            {bookingDetails.isOffline
                              ? bookingDetails.renterName
                              : bookingDetails._renter
                              ? bookingDetails._renter.name.full
                              : ""}
                          </Link>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Provider</Label>
                        <p className="view-text">
                          <Link
                            to={`/dashboard/provider-details/${bookingDetails._rentee?._id}`}
                          >
                            {bookingDetails._rentee?.name.full}
                          </Link>
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Start Date</Label>
                        <p className="view-text">
                          {this._formatDate(bookingDetails.startDate)}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">End Date</Label>
                        <p className="view-text">
                          {this._formatDate(bookingDetails.endDate)}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Cycle</Label>
                        <p className="view-text capitalize">
                          {bookingDetails.rentType}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4" className="booking-seats">
                      <FormGroup>
                        <Label htmlFor="name">Booking</Label>
                        <p className="view-text">
                          {bookingDetails._space.spaceType} x{" "}
                          <span>{bookingDetails.noOfSeats}</span>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Amount</Label>
                        <p className="view-text">
                          $ {bookingDetails.invoice.totalAmount}
                          {/* <a
                            rel="noopener noreferrer"
                            href={`https://res.cloudinary.com/soumyanil/image/upload/v1568185617/v88jxrvrlfbngykz9lb7.png`}
                            target="_blank"
                          >
                            <img
                              src="../../assets/img/pdf-icon.png"
                              width="40"
                              alt="pdf icon"
                            />
                          </a> */}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Status</Label>
                        <p className="view-text">{bookingDetails.status}</p>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
            {loadingData && (
              <Card>
                <CardBody>
                  <div className="loading-section no-border">
                    <i className="fa fa-spinner fa-spin"></i> &nbsp; Loading
                    Booking Details..
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default ViewBooking;
