import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import CustomTable from "../../../components/CustomTable";
// import  CustomDateRangePicker  from '../../../components/date-range-picker';
import { format } from "date-fns";
// import moment from "moment";
import { getWeeklyPayoutHistory } from "../../../http/http-calls";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// const res = {
//   error: false,
//   report: {
//     _id: "5dfa0a3aec3e75480f88607e",
//     fromDate: "2019-12-01T10:06:55.851Z",
//     toDate: "2019-12-18T11:15:06.905Z",
//     summaryList: [
//       {
//         _id: "5dfa0a3aec3e75480f886080",
//         _to: "5df1e88ff851d03697b199a5",
//         name: {
//           first: "Sankar",
//           last: "prasad biswas",
//           full: "Sankar prasad biswas",
//         },
//         phone: "9038549891",
//         email: "indrajit+1@logic-square.com",
//         bankDetails: {
//           accountHolderName: "TEST",
//           accountNo: "000111111116",
//           bankName: "STRIPE TEST BANK",
//           fingerprint: "6zaC0RnWIhqcLeQV",
//           routingNo: "110000000",
//         },
//         countryCode: "+91",
//         amount: 20,
//         currency: "usd",
//       },
//       {
//         _id: "5dfa0a3aec3e75480f88607f",
//         _to: "5df1e88ff851d03697b199a5",
//         name: {
//           first: "Sankar",
//           last: "prasad biswas",
//           full: "Sankar prasad biswas",
//         },
//         phone: "9038549891",
//         email: "indrajit+1@logic-square.com",
//         bankDetails: {
//           accountHolderName: "TEST",
//           accountNo: "000111111116",
//           bankName: "STRIPE TEST BANK",
//           fingerprint: "6zaC0RnWIhqcLeQV",
//           routingNo: "110000000",
//         },
//         countryCode: "+91",
//         amount: 202.5,
//         currency: "ars",
//       },
//     ],
//     transactionList: [
//       {
//         _id: "5dfa0a3aec3e75480f886082",
//         createdAt: "12th Dec 2019",
//         buildingName: "Sun and sand building",
//         shortBookingId: "3l2joqi",
//         renteeName: {
//           first: "Sankar",
//           last: "prasad biswas",
//           full: "Sankar prasad biswas",
//         },
//         renterName: {
//           first: "Indrajit",
//           last: "Roy",
//           full: "Indrajit Roy",
//         },
//         remarks: "Provider credit",
//         currency: "usd",
//         amount: 20,
//       },
//       {
//         _id: "5dfa0a3aec3e75480f886081",
//         createdAt: "13th Dec 2019",
//         buildingName: "Sun and sand building",
//         shortBookingId: "0o2dojo",
//         renteeName: {
//           first: "Sankar",
//           last: "prasad biswas",
//           full: "Sankar prasad biswas",
//         },
//         renterName: {
//           first: "Indrajit",
//           last: "Roy",
//           full: "Indrajit Roy",
//         },
//         remarks: "Provider credit",
//         currency: "ars",
//         amount: 202.5,
//       },
//     ],
//     createdAt: "2019-12-18T11:15:06.933Z",
//     __v: 0,
//     id: "5dfa0a3aec3e75480f88607e",
//   },
// };
class WeeklyPayoutSummary extends Component {
  state = {
    loading: {
      summary: false,
      transaction: false,
    },
    summaryHeaderKeys: [
      { id: "name", label: "Name" },
      { id: "email", label: "Email" },
      { id: "phone", label: "Phone" },
      { id: "amount", label: "Amount" },
      { id: "banktDetails", label: "Account Details" },
    ],
    transactionHeaderKeys: [
      { id: "name", label: "Date" },
      { id: "buildingName", label: "Place" },
      { id: "shortBookingId", label: "Booking ID" },
      { id: "renter", label: "Renter" },
      { id: "remarks", label: "Transaction Details" },
      { id: "amount", label: "Credits Due" },
    ],
    // dateRange: {
    //   startDate:moment().subtract(7,'days'),
    //   endDate:moment(),
    //   focusedInput:null,
    //   startDateId:'startDate',
    //   endDateId:'endDate'
    // },
    selecteDate: new Date(),
    report: {},
  };

  // _onDatesChange=(startDate, endDate)=>{
  //   console.log('on date change', startDate, endDate);
  //   let { dateRange } = this.state;
  //   dateRange.startDate = startDate;
  //   dateRange.endDate = endDate;
  //   this.setState({ dateRange });
  //   if(dateRange.startDate && dateRange.endDate){
  //     console.log('range selction complete');
  //   }
  // }

  // _onFocusChange = (input) =>{
  //   console.log(input);
  //   let { dateRange } = this.state;
  //   dateRange.focusedInput = input;
  //   this.setState({ dateRange });
  // }

  _handleDateChange = (date) => {
    let { selecteDate } = this.state;
    selecteDate = date;
    this.setState({ selecteDate });
    console.log(selecteDate);
    this._getWeeklyPayoutHistory(new Date(selecteDate).toISOString());
  };

  _manageLoading = (key, value) => {
    let { summary, transaction } = this.state.loading;
    if (key === "summary") {
      summary = value;
    } else if (key === "transaction") {
      transaction = value;
    }
    this.setState({ loading: { summary, transaction } });
  };

  _formatDate(date, dateFormat) {
    return format(new Date(date), dateFormat);
  }

  _dataFormat = (cell, row, header) => {
    // console.log(cell, row, header);
    if (header === "name") {
      return <>{row.name.full}</>;
    } else if (header === "banktDetails") {
      return (
        <span style={{ fontSize: "12px" }}>
          Account No: {row.bankDetails.accountNo}, <br />
          Routing No : {row.bankDetails.routingNo}
        </span>
      );
    } else {
      return cell;
    }
  };

  _transactionDataFormat = (cell, row, header) => {
    console.log(cell, row, header);
    if (header === "name") {
      return <>{row.createdAt}</>;
    } else if (header === "renter") {
      return <span>{row.renterName.full}</span>;
    } else {
      return cell;
    }
  };

  _getWeeklyPayoutHistory(date) {
    this._manageLoading("summary", true);
    // this._manageLoading('transaction', true);
    getWeeklyPayoutHistory({ queryDate: date }).then(
      (response) => {
        console.log(response);
        this.setState({ report: response.report });
        this._manageLoading("summary", false);
        // this._manageLoading('transaction', false);
      },
      (error) => {
        console.log(error);
        this._manageLoading("summary", false);
        // this._manageLoading('transaction', false);
      }
    );
  }

  componentDidMount() {
    // this._manageLoading('transaction', true);
    // this._manageLoading('summary', true);
    // setTimeout(()=>{
    //   this._manageLoading('summary', false);
    //   this._manageLoading('transaction', false);
    //   this.setState({ report: response.report });
    // }, 5000)
    this._getWeeklyPayoutHistory(new Date().toISOString());
  }

  render() {
    const { summaryHeaderKeys, transactionHeaderKeys, loading, report } =
      this.state;
    return (
      <div className="animated fadeIn">
        <div className="page-heading">
          <h2>Weekly Payout Summary</h2>
        </div>
        <Row className="table-panel">
          <Col xs="12" lg="12">
            <Card>
              {!loading.summary && (
                <CardBody>
                  <div className="">
                    <Form noValidate className="mb-4">
                      Select Date: &nbsp;
                      {/* <CustomDateRangePicker dateRange={dateRange} onDatesChange={this._onDatesChange}
                     onFocusChange={this._onFocusChange}></CustomDateRangePicker>*/}
                      <InputGroup
                        className="mb-4 booking-start-date-filter"
                        style={{ width: 300 }}
                      >
                        <DatePicker
                          className="form-control"
                          autoComplete="off"
                          placeholderText="Select Date"
                          selected={this.state.selecteDate}
                          name="selecteDate"
                          onSelect={this._handleDateChange}
                          dateFormat="MMM d, yyyy"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fa fa-calendar"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Form>
                    {report && report.fromDate && report.toDate && (
                      <p>
                        Here are weekly settelments due for credit transactions
                        on Huddlespot for the period from
                        <b>
                          {" "}
                          {this._formatDate(report.fromDate, "d MMM, yyyy")}
                        </b>{" "}
                        to{" "}
                        <b>{this._formatDate(report.toDate, "d MMM, yyyy")}</b>
                      </p>
                    )}
                  </div>
                  <CustomTable
                    tableData={report && report.summaryList}
                    headerKeys={summaryHeaderKeys}
                    dataFormat={this._dataFormat}
                  ></CustomTable>
                </CardBody>
              )}
              {loading.summary && (
                <CardBody>
                  <div className="loading-section list-loading">
                    <i className="fa fa-spinner fa-spin"></i> &nbsp; Loading..
                  </div>
                </CardBody>
              )}
            </Card>
            <Card>
              {!loading.summary && (
                <CardBody>
                  <p>
                    Here are the details of transactions involved during the
                    same period
                  </p>
                  <CustomTable
                    tableData={report && report.transactionList}
                    headerKeys={transactionHeaderKeys}
                    dataFormat={this._transactionDataFormat}
                  ></CustomTable>
                </CardBody>
              )}
              {/* { loading.summary && 
            <CardBody> 
            <div className="loading-section list-loading">
              <i className="fa fa-spinner fa-spin"></i> &nbsp;
              Loading..
            </div> 
            </CardBody>
          } */}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default WeeklyPayoutSummary;
