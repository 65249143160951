import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  // FormText,
  // FormFeedback,
  Input,
  // Img,
  Button,
  InputGroupAddon,
  // InputGroupButtonDropdown,
  InputGroupText,
  Label,
  InputGroup,
} from "reactstrap";
// import { AppSwitch } from '@coreui/react'

class EditBooking extends Component {
  state = {};
  saveBookingDetails = () => {
    this.props.history.push("booking-details");
  };
  render() {
    return (
      <div className="animated fadeIn">
        <Row className="table-panel booking-panel edit-booking">
          <Col xs="12" sm="6">
            <div className="page-heading">
              <h2>Edit Booking</h2>
            </div>
            <Card>
              <CardHeader>
                <strong>Booking 1234</strong>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="4">
                    <FormGroup>
                      <Label htmlFor="name">Place</Label>
                      <FormGroup>
                        <Input type="select" name="ccmonth" id="ccmonth">
                          <option value="1">ABC</option>
                          <option value="2">DEF</option>
                        </Input>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Label htmlFor="name">Renter</Label>
                      <FormGroup>
                        <Input type="select" name="ccmonth" id="ccmonth">
                          <option value="1">Ram</option>
                          <option value="2">Rahim</option>
                        </Input>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Label htmlFor="name">Provider</Label>
                      <FormGroup>
                        <Input type="select" name="ccmonth" id="ccmonth">
                          <option value="1">Rohan</option>
                          <option value="2">Mohan</option>
                        </Input>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="4">
                    <FormGroup>
                      <Label htmlFor="name">Start Date</Label>
                      <InputGroup>
                        <Input
                          type="text"
                          id="input1-group1"
                          name="input1-group1"
                          placeholder="DD/MM/YY"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fa fa-calendar"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Label htmlFor="name">End Date</Label>
                      <InputGroup>
                        <Input
                          type="text"
                          id="input1-group1"
                          name="input1-group1"
                          placeholder="DD/MM/YY"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fa fa-calendar"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Label htmlFor="name">Cycle</Label>
                      <FormGroup>
                        <Input type="select" name="ccmonth" id="ccmonth">
                          <option value="1">Hourly</option>
                          <option value="2">Daily</option>
                          <option value="3">Weekly</option>
                          <option value="4">Monthly</option>
                        </Input>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="5" className="booking-seats">
                    <FormGroup>
                      <Label htmlFor="name">Booking</Label>
                      <FormGroup>
                        <Input type="select" name="ccmonth" id="ccmonth">
                          <option value="1">Hot Desk</option>
                          <option value="2">Fixed Desk</option>
                          <option value="3">Private office</option>
                          <option value="4">Conference Room</option>
                        </Input>
                        <Input
                          type="number"
                          id="name"
                          placeholder="0"
                          required
                        />
                      </FormGroup>
                    </FormGroup>
                  </Col>
                  <Col xs="3">
                    <FormGroup>
                      <Label htmlFor="name">Amount</Label>
                      <Input type="text" id="name" placeholder="$" required />
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Label htmlFor="name">Status</Label>
                      <FormGroup>
                        <Input type="select" name="ccmonth" id="ccmonth">
                          <option value="1">PendingPayment</option>
                          <option value="2">PaymentFailed</option>
                          <option value="3">Confirmed</option>
                          <option value="4">In Progress</option>
                          <option value="5">Cancelled By Rentee</option>
                          <option value="6">Cancelled By Renter</option>
                          <option value="5">Cancelled By Admin</option>
                          <option value="6">Completed</option>
                        </Input>
                      </FormGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="submit-panel">
                  <Col xs="12">
                    <FormGroup>
                      <Button
                        type="button"
                        color="primary"
                        onClick={this.saveBookingDetails}
                      >
                        Save
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EditBooking;
