import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  Form,
  // FormFeedback,
  Input,
  // Img,
  Button,
  // InputGroupAddon,
  // InputGroupButtonDropdown,
  // InputGroupText,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// import { AppSwitch } from '@coreui/react'
import {
  getPropertyDetails,
  getAllAmenities,
  googlePlaceSearch,
  updateProperty,
  getVimeoLink,
  getAllRentees,
  addProperty,
  googleLocationSearch,
} from "../../../http/http-calls";
import { uploadFileMultiPart } from "../../../http/http-service";
import { ToastsStore } from "react-toasts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { element } from "prop-types";

let propertyDetails_initial = {
  renteeId: "",
  buildingName: "",
  description: "",
  streetAddress: "",
  picUrls: [],
  videoUrls: [],
  termsAndConditions: "",
  contactNo: "",
  holidays: {
    workingDays: [],
    recurringDays: [],
    customDays: [],
  },
  city: "",
  country: "",
  location: {
    type: "Point",
    coordinates: [],
  },
  workingHours: { from: "", end: "" },
  spaces: [
    {
      spaceType: "Hot Desk",
      noOfSeats: 0,
      rates: [
        { currency: "usd", amount: 0, period: "hourly" },
        { currency: "usd", amount: 0, period: "daily" },
        { currency: "usd", amount: 0, period: "weekly" },
        { currency: "usd", amount: 0, period: "monthly" },
      ],
    },
    {
      spaceType: "Fixed Desk",
      noOfSeats: 0,
      rates: [
        { currency: "usd", amount: 0, period: "hourly" },
        { currency: "usd", amount: 0, period: "daily" },
        { currency: "usd", amount: 0, period: "weekly" },
        { currency: "usd", amount: 0, period: "monthly" },
      ],
    },
    {
      spaceType: "Private Office",
      spaceName: "",
      noOfSeats: 0,
      roomCapacity: 0,
      rates: [
        { currency: "usd", amount: 0, period: "hourly" },
        { currency: "usd", amount: 0, period: "daily" },
        { currency: "usd", amount: 0, period: "weekly" },
        { currency: "usd", amount: 0, period: "monthly" },
      ],
    },
    {
      spaceType: "Conference Room",
      spaceName: "",
      noOfSeats: 0,
      roomCapacity: 0,
      rates: [
        { currency: "usd", amount: 0, period: "hourly" },
        { currency: "usd", amount: 0, period: "daily" },
        { currency: "usd", amount: 0, period: "weekly" },
        { currency: "usd", amount: 0, period: "monthly" },
      ],
    },
  ],
  facilities: "",
};
class AddNewPlace extends Component {
  state = {
    loadingData: false,
    savingData: false,
    uploadingImage: false,
    uploadingVideo: false,
    uploadingTermsAndConditions: false,
    propertyDetails: {
      renteeId: "",
      buildingName: "",
      description: "",
      streetAddress: "",
      picUrls: [],
      videoUrls: [],
      termsAndConditions: "",
      contactNo: "",
      holidays: {
        workingDays: [],
        recurringDays: [],
        customDays: [],
      },
      city: "",
      country: "",
      location: {
        type: "Point",
        coordinates: [],
      },
      workingHours: { from: "", end: "" },
      spaces: [
        {
          spaceType: "Hot Desk",
          noOfSeats: 0,
          rates: [
            { currency: "usd", amount: 0, period: "hourly" },
            { currency: "usd", amount: 0, period: "daily" },
            { currency: "usd", amount: 0, period: "weekly" },
            { currency: "usd", amount: 0, period: "monthly" },
          ],
        },
        {
          spaceType: "Fixed Desk",
          noOfSeats: 0,
          rates: [
            { currency: "usd", amount: 0, period: "hourly" },
            { currency: "usd", amount: 0, period: "daily" },
            { currency: "usd", amount: 0, period: "weekly" },
            { currency: "usd", amount: 0, period: "monthly" },
          ],
        },
        {
          spaceType: "Private Office",
          spaceName: "",
          noOfSeats: 0,
          roomCapacity: 0,
          rates: [
            { currency: "usd", amount: 0, period: "hourly" },
            { currency: "usd", amount: 0, period: "daily" },
            { currency: "usd", amount: 0, period: "weekly" },
            { currency: "usd", amount: 0, period: "monthly" },
          ],
        },
        {
          spaceType: "Conference Room",
          spaceName: "",
          noOfSeats: 0,
          roomCapacity: 0,
          rates: [
            { currency: "usd", amount: 0, period: "hourly" },
            { currency: "usd", amount: 0, period: "daily" },
            { currency: "usd", amount: 0, period: "weekly" },
            { currency: "usd", amount: 0, period: "monthly" },
          ],
        },
      ],
      facilities: "",
    },
    propertyName: "",
    amenities: [],
    workingDays: [
      { day: "Monday", isSelected: true },
      { day: "Tuesday", isSelected: true },
      { day: "Wednesday", isSelected: true },
      { day: "Thursday", isSelected: true },
      { day: "Friday", isSelected: true },
      { day: "Saturday", isSelected: false },
      { day: "Sunday", isSelected: false },
    ],
    providerList: [],
    errors: {},
    isDirty: {
      buildingName: false,
      picUrls: false,
      videoUrls: false,
      description: false,
      facilities: false,
      contactNo: false,
      workingDays: false,
      streetAddress: false,
    },
    showSuggestionList: false,
    locationSuggestionList: [],
    spaceType: ["Hot Desk", "Fixed Desk", "Private Office", "Conference Room"],
    rateTypes: ["hourly", "daily", "weekly", "monthly"],
    confIndexs: [],
    holidayList: [],
  };
  componentDidMount() {
    //   this._setPageName(this.props.location.pathname);
    this._getAllRentees();
  }

  //   _setPageName(path) {
  //     let pageName = path.split("/")[2];
  //     console.log(pageName);
  //     if (pageName === "edit-place") {
  //       pageName = "Place Edit";
  //     } else {
  //       pageName = "Place Add";
  //     }
  //     this.setState({ pageName });
  //   }
  _getAllRentees() {
    this._manageLoading("load-data", true);
    getAllRentees().then(
      (response) => {
        console.log(response);
        let providerList = response.rentees.map((each) => {
          return {
            id: each._id,
            name: each.name.full,
            // email: each.email,
            // phone:
            //   each.countryCode && each.phone
            //     ? each.countryCode + " " + each.phone
            //     : "N/A",
            // address: each.address
            //   ? each.address.city + ", " + each.address.country
            //   : "Not Provided",
            // booking: each._booking ? each._booking.length : 0,
            // places: each._property.length,
            // isActive: each.isActive,
            // languagePreference: each.languagePreference,
            // action: "",
          };
        });
        const { propertyDetails } = this.state;
        propertyDetails.renteeId = providerList[0].id;
        this.setState({ providerList, propertyDetails });
        this._getAllAmenities();
      },
      (error) => {
        console.log(error);
        this._manageLoading("load-data", false);
      }
    );
  }
  _manageLoading = (key, value) => {
    let {
      loadingData,
      savingData,
      uploadingImage,
      uploadingVideo,
      uploadingTermsAndConditions,
    } = this.state;
    if (key === "load-data") {
      loadingData = value;
    } else if (key === "save-data") {
      savingData = value;
    } else if (key === "upload-image") {
      uploadingImage = value;
    } else if (key === "upload-video") {
      uploadingVideo = value;
    } else if (key === "upload-file") {
      uploadingTermsAndConditions = value;
    }
    this.setState({
      loadingData,
      savingData,
      uploadingImage,
      uploadingVideo,
      uploadingTermsAndConditions,
    });
  };

  //   _getPropertyDetails = (id) => {
  //     getPropertyDetails(id).then(
  //       (response) => {
  //         console.log(response);
  //         this.setState({ propertyName: response.property.buildingName });
  //         let propertyDetails = {
  //           buildingName: response.property.buildingName,
  //           description: response.property.description,
  //           _id: response.property._id,
  //           address: response.property.city + ", " + response.property.country,
  //           picUrls: response.property.picUrls,
  //           videoUrls: response.property.videoUrls,
  //           _spaces: response.property._spaces,
  //           facilities: response.property.facilities,
  //         };
  //         let { amenities, rateTypes } = this.state;
  //         let confIndexs = [];
  //         amenities.forEach((each) => {
  //           let facility = propertyDetails.facilities.find((item) => {
  //             return (
  //               item.title === each.title.en || item.title.en === each.title.en
  //             );
  //           });
  //           if (facility) {
  //             each.isSelected = true;
  //           } else {
  //             each.isSelected = false;
  //           }
  //         });
  //         propertyDetails._spaces.forEach((space, spaceIndex) => {
  //           if (space.isActive && space.spaceType !== "Conference Room") {
  //             rateTypes.forEach((item, j) => {
  //               let rate = space.rates.find((e) => {
  //                 return e.period === item;
  //               });
  //               if (!rate) {
  //                 space.rates.push({ period: item, amount: 0, currency: "usd" });
  //               }
  //             });
  //           } else {
  //             confIndexs.push(spaceIndex);
  //             ["hourly", "daily"].forEach((item, j) => {
  //               let rate = space.rates.find((e) => {
  //                 return e.period === item;
  //               });
  //               if (!rate) {
  //                 space.rates.unshift({
  //                   period: item,
  //                   amount: 0,
  //                   currency: "usd",
  //                 });
  //               }
  //             });
  //           }
  //         });
  //         // let _spaces=[]
  //         // spaceType.forEach((each, index)=>{
  //         //   if(propertyDetails._spaces[index]){
  //         //     rateTypes.forEach((item, j)=>{
  //         //       if(!propertyDetails._spaces[index].rates[j]) {
  //         //         propertyDetails._spaces[index].rates[j] = {period:item, amount:0};
  //         //       }
  //         //     })
  //         //     _spaces[index] = propertyDetails._spaces[index];
  //         //     } else{
  //         //       _spaces[index] = {
  //         //         spaceType: each,
  //         //         noOfSeats:0,
  //         //         rates:[{period:'hourly', amount:0}, {period:'daily', amount:0}, {period:'weekly', amount:0}, {period:'monthly', amount:0}]
  //         //       }
  //         //     }
  //         //   })
  //         // propertyDetails._spaces = _spaces;
  //         // console.log("_spaces", _spaces);
  //         this.setState({ propertyDetails, amenities, confIndexs });
  //         console.log("amenities", amenities);
  //         this._manageLoading("load-data", false);
  //       },
  //       (error) => {
  //         console.log(error);
  //         this._manageLoading("load-data", false);
  //       }
  //     );
  //   };

  _getAllAmenities = () => {
    getAllAmenities().then(
      (response) => {
        console.log(response);
        let amenities = response.facilities.map((each) => {
          delete each.addedOn;
          delete each._addedBy;
          delete each._id;
          return each;
        });
        this.setState({ amenities });
        // this._getPropertyDetails(this.props.match.params.id);
      },
      (error) => {
        console.log(error);
        ToastsStore.error(error.reason, 1500);
      }
    );
    this._manageLoading("load-data", false);
  };

  _handleOnChange = ({ currentTarget }) => {
    const { propertyDetails, isDirty } = this.state;
    propertyDetails[currentTarget.name] = currentTarget.value;
    isDirty[currentTarget.name] = true;
    this.setState({ propertyDetails });
    this._validateForm();
  };

  _handleCheckChange = ({ currentTarget }, index) => {
    const { amenities, isDirty } = this.state;
    console.log(currentTarget, index);
    amenities[index].isSelected = !amenities[index].isSelected;
    isDirty[currentTarget.name] = true;
    this.setState({ amenities });
    this._validateForm();
  };

  _handleWorkingDaysChange = ({ currentTarget }, index) => {
    const { workingDays, isDirty } = this.state;
    console.log(currentTarget, index);
    workingDays[index].isSelected = !workingDays[index].isSelected;
    isDirty[currentTarget.name] = true;
    this.setState({ workingDays });
    this._validateForm();
  };

  _handleSpaceChange = ({ currentTarget }, key, index, rateIndex) => {
    console.log(currentTarget, key, index, rateIndex);
    const { propertyDetails } = this.state;
    if (key === "noOfSeats") {
      if (!currentTarget.value.match(/^[-+]?[0-9]+\.[0-9]+$/)) {
        propertyDetails.spaces[index][key] = currentTarget.value;
      }
    } else if (key === "rates") {
      propertyDetails.spaces[index][key][rateIndex].amount =
        currentTarget.value;
    } else if (key === "roomCapacity") {
      propertyDetails.spaces[index][key] = currentTarget.value;
    } else if (key === "spaceName") {
      propertyDetails.spaces[index][key] = currentTarget.value;
    }
    this.setState({ propertyDetails });
  };

  _fileChange = (event, key) => {
    const file = event.target.files[0];
    if (file) {
      this._uploadFile(event.target.files[0], key);
    } else {
    }
  };

  _uploadFile = (file, key) => {
    var fd = new FormData();
    fd.append("file", file);
    if (key === "videoUrls") this._manageLoading("upload-video", true);
    else if (key === "fileUrls") this._manageLoading("upload-file", true);
    else this._manageLoading("upload-image", true);
    uploadFileMultiPart(
      false,
      fd,
      key === "videoUrls" ? "video" : "image"
    ).then(
      (response) => {
        console.log(response.secure_url);
        const { propertyDetails } = this.state;
        if (key === "videoUrls") {
          propertyDetails[key][0] = "";
          propertyDetails[key][0] = response.secure_url;
          // this._getVimeoLink(response.secure_url);
        } else if (key === "fileUrls") {
          propertyDetails.termsAndConditions = response.secure_url;
        } else {
          propertyDetails[key].push(response.secure_url);
        }
        this.setState({ propertyDetails });
        if (key === "videoUrls") this._manageLoading("upload-video", false);
        else if (key === "fileUrls") this._manageLoading("upload-file", false);
        else this._manageLoading("upload-image", false);
      },
      (error) => {
        console.log(error);
        if (key === "videoUrls") this._manageLoading("upload-video", false);
        else if (key === "fileUrls") this._manageLoading("upload-file", false);
        else this._manageLoading("upload-image", false);
        ToastsStore.error(error.reason || "Something Went Wrong!", 3000);
      }
    );
  };

  _removePropertyImage = (index) => {
    let { propertyDetails } = this.state;
    propertyDetails.picUrls.splice(index, 1);
    this.setState({ propertyDetails });
  };

  _searchPlace = ({ currentTarget }) => {
    const { propertyDetails, isDirty } = this.state;
    console.log(currentTarget.value);
    propertyDetails.streetAddress = currentTarget.value;
    isDirty.streetAddress = true;
    this.setState({ propertyDetails });
    this._validateForm();
    googleLocationSearch(propertyDetails.streetAddress).then((response) => {
      console.log(response);
      this.setState({
        locationSuggestionList: response,
        showSuggestionList: true,
      });
    });
  };

  _selectLocation = (each) => {
    console.log(each);
    let { propertyDetails, showSuggestionList, isDirty } = this.state;
    propertyDetails.streetAddress = each.description;
    showSuggestionList = false;
    isDirty.streetAddress = true;

    this.onSelectAddress(each.place_id, function (loc) {
      //Do something with location
      if (loc) {
        propertyDetails.location.coordinates = [loc.latitude, loc.longitude];
      }
    });
    console.log(each.terms[each.terms.length - 3].value);
    propertyDetails.city = each.terms[each.terms.length - 3].value;
    propertyDetails.country = each.terms[each.terms.length - 1].value;
    this.setState({ propertyDetails, showSuggestionList });
    this._validateForm();
  };
  //   to find lat and lng from place_id
  onSelectAddress(placeId, callback) {
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId: placeId }, function (results, status) {
      if (status == window.google.maps.GeocoderStatus.OK) {
        callback({
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng(),
        });
      } else {
        callback(null);
      }
    });
  }
  _validateForm() {
    const { propertyDetails, amenities, errors, isDirty, workingDays } =
      this.state;
    console.log(propertyDetails, isDirty);
    Object.keys(propertyDetails).forEach((each) => {
      if (each === "buildingName") {
        if (!propertyDetails.buildingName.trim().length) {
          errors.buildingName = "Building Name is Required";
        } else {
          delete errors[each];
          isDirty.buildingName = false;
        }
      } else if (each === "streetAddress") {
        if (!propertyDetails.streetAddress.trim().length) {
          errors.streetAddress = "Address is Required";
        } else {
          delete errors[each];
          isDirty.streetAddress = false;
        }
      } else if (each === "contactNo") {
        if (!propertyDetails.contactNo.trim().length) {
          errors.contactNo = "Phone number is Required";
        }
        // else if(renterDetails.phone.trim().length !== 10){
        //   errors.phone = "Phone number must be of 10 digits";
        // }
        else {
          delete errors[each];
          isDirty.contactNo = false;
        }
      } else if (each === "description") {
        if (!propertyDetails.description.trim().length) {
          errors.description = "Description is Required";
        } else {
          delete errors[each];
          isDirty.description = false;
        }
      } else if (each === "facilities") {
        if (
          !amenities.filter((each) => {
            return each.isSelected;
          }).length
        ) {
          errors.facilities = "Please select atleast one Amenity";
        } else {
          delete errors[each];
          isDirty.facilities = false;
        }
      } else if (each === "workingHours") {
        console.log(propertyDetails.workingHours);
        if (!propertyDetails.workingHours.from) {
          errors.startWorkingHours = "Please select working time";
        } else {
          delete errors.startWorkingHours;
        }
        if (!propertyDetails.workingHours.end) {
          errors.endWorkingHours = "Please select working time";
        } else if (
          propertyDetails.workingHours.end < propertyDetails.workingHours.from
        ) {
          errors.endWorkingHours = "Please select time after start time";
        } else {
          delete errors.endWorkingHours;
        }
      }
    });
    if (
      !workingDays.filter((each) => {
        return each.isSelected;
      }).length
    ) {
      errors.workingDays = "Please select atleast one working day";
    } else {
      delete errors[workingDays];
      isDirty.workingDays = false;
    }
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  _handleOnSubmit = (event) => {
    event.preventDefault();
    let { isDirty } = this.state;
    isDirty = {
      buildingName: false,
      streetAddress: false,
      picUrls: false,
      videoUrls: false,
      description: false,
    };
    this.setState({ isDirty });
    console.log(this.state.isDirty);
    let errors = this._validateForm();
    console.log(errors);
    // this.setState({errors});
    if (!errors) {
      let data = Object.assign({}, this.state.propertyDetails);
      data.facilities = this.state.amenities
        .filter((each) => each.isSelected)
        .map(({ iconUrl, title }) => {
          return {
            iconUrl,
            title,
          };
        });
      data.holidays.workingDays = this.state.workingDays
        .filter((each) => each.isSelected)
        .map(({ day }) => day);

      data.spaces = data.spaces.filter((each) => {
        return each.noOfSeats > 0;
      });
      data.spaces.forEach((each) => {
        each.rates = each.rates.filter((item) => {
          return item.amount > 0;
        });
      });
      data.spaces.forEach((each) => {
        each.noOfSeats = Number(each.noOfSeats);
        each.rates.forEach((item) => {
          item.amount = Number(item.amount);
        });
      });
      data.workingHours = {
        from: this.state.propertyDetails.workingHours.from
          ? moment(this.state.propertyDetails.workingHours.from).format("HHmm")
          : "0000",
        end: this.state.propertyDetails.workingHours.end
          ? moment(this.state.propertyDetails.workingHours.end).format("HHmm")
          : "0000",
      };
      this.state.holidayList.forEach((each, index) => {
        if (each.isRecurring)
          data.holidays.recurringDays.push({
            date: moment(each.date).format("MM-DD"),
            label: each.label,
            fullDate: moment(each.date).format("MMM DD, YYYY"),
          });
        else
          data.holidays.customDays.push({
            date: moment(each.date).format("YYYY-MM-DD"),
            label: each.label,
            fullDate: moment(each.date).format("MMM DD, YYYY"),
          });
      });
      console.log("Make API call", data);
      this._updateProperty(data);
    }
  };

  _updateProperty = (data) => {
    this._manageLoading("save-data", true);
    addProperty(data).then(
      (response) => {
        console.log(response);
        this._manageLoading("save-data", false);
        ToastsStore.success("Property updated successfully!", 3000);
        this.setState({
          propertyDetails: propertyDetails_initial,
          holidayList: [],
        });
        // this.props.history.push(
        //   "../place-details/" + this.state.propertyDetails._id
        // );
      },
      (error) => {
        console.log(error);
        ToastsStore.error(error.reason, 3000);
        this._manageLoading("save-data", false);
      }
    );
  };

  savePlaceDetails = () => {
    this.props.history.push("place-details");
  };

  _backToDetails = () => {
    this.props.history.push("properties");
  };

  _getVimeoLink = (link) => {
    getVimeoLink({ link }).then(
      (response) => {
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  _handleDateChange = (type, date) => {
    console.log(type, date);
    const { propertyDetails, isDirty } = this.state;
    if (type === "from") {
      propertyDetails.workingHours.from = date;
      isDirty.from = true;
    } else {
      propertyDetails.workingHours.end = date;
      isDirty.end = true;
    }
    this.setState({ propertyDetails });
    this._validateForm();
  };
  //   holiday
  _handleHolidayChange = (date) => {
    this.setState((prevState) => ({
      holidayList: [
        ...prevState.holidayList,
        {
          date: date,
          label: null,
          isRecurring: false,
        },
      ],
    }));
  };
  _handleDeleteHoliday = (index) => {
    var array = [];
    this.state.holidayList.forEach((element, i) => {
      if (index !== i) array.push(element);
    });
    this.setState({ holidayList: array });
  };
  _handleHolidayRepeat = (index) => {
    const { holidayList } = this.state;
    holidayList[index].isRecurring = !holidayList[index].isRecurring;
    this.setState({ holidayList });
  };
  _handleHolidayLabel = (index, label) => {
    const { holidayList } = this.state;
    holidayList[index].label = label;
    this.setState({ holidayList });
  };
  render() {
    let { propertyDetails, propertyName, amenities } = this.state;
    console.log(this.state.holidayList);
    return (
      <div className="animated fadeIn">
        <Row className="table-panel booking-panel edit-booking">
          <Col xs="12" sm="6">
            <div className="page-heading">
              <h2>{"Add Place"}</h2>
            </div>
            {!this.state.loadingData && (
              <Card>
                <CardHeader>
                  <strong>Place {propertyName}</strong>
                  <Button color="secondary" onClick={this._backToDetails}>
                    <i className="fa fa-arrow-left"></i> &nbsp; Back
                  </Button>
                </CardHeader>
                <Form onSubmit={this._handleOnSubmit} noValidate>
                  <CardBody>
                    <Row>
                      <Col xs="4">
                        <FormGroup>
                          <Label htmlFor="renteeId">Provider</Label>
                          <Input
                            type="select"
                            name="renteeId"
                            id="renteeId"
                            //   value={}
                            placeholder="Select Provider"
                            onChange={this._handleOnChange}
                          >
                            {this.state.providerList.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Input>
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.renteeId}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs="4">
                        <FormGroup>
                          <Label htmlFor="buildingName">Place</Label>
                          <FormGroup>
                            <Input
                              type="text"
                              name="buildingName"
                              id="buildingName"
                              value={propertyDetails.buildingName}
                              onChange={this._handleOnChange}
                              className={
                                this.state.errors &&
                                this.state.errors.buildingName
                                  ? "invalid-input"
                                  : "valid-input"
                              }
                            ></Input>
                            {this.state.errors && (
                              <div className="validation-error">
                                {this.state.errors.buildingName}
                              </div>
                            )}
                          </FormGroup>
                        </FormGroup>
                      </Col>
                      <Col xs="4">
                        <FormGroup>
                          <Label htmlFor="name">Location</Label>
                          <Input
                            type="text"
                            id="input1-group1"
                            name="address"
                            placeholder="Location"
                            value={propertyDetails.streetAddress}
                            onChange={this._searchPlace}
                          />
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.streetAddress}
                            </div>
                          )}
                          {this.state.locationSuggestionList &&
                            this.state.locationSuggestionList.length !== 0 &&
                            this.state.showSuggestionList && (
                              <ul className="location-suggestion-ul">
                                {this.state.locationSuggestionList.map(
                                  (each) => (
                                    <li
                                      key={each.place_id}
                                      onClick={() => this._selectLocation(each)}
                                    >
                                      <i className="fa fa-map-marker"></i>&nbsp;
                                      {each.description}
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                        </FormGroup>
                      </Col>
                    </Row>
                    {propertyDetails.spaces.map((each, index) => (
                      <div key={index}>
                        <Row>
                          {(each.spaceType === "Hot Desk" ||
                            each.spaceType === "Fixed Desk") && (
                            <Col xs="12">
                              <Row className="seat-details">
                                <Col xs="3">
                                  <FormGroup>
                                    <Label htmlFor="name">
                                      {each.spaceType + "(s)"}
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col xs="4">
                                  <FormGroup>
                                    <InputGroup>
                                      <Input
                                        type="number"
                                        id="name"
                                        placeholder="Enter No. of Seats"
                                        name="noOfSeats"
                                        value={each.noOfSeats}
                                        onChange={(e) =>
                                          this._handleSpaceChange(
                                            e,
                                            "noOfSeats",
                                            index
                                          )
                                        }
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                {each.rates.map((item, j) => (
                                  <Col xs="3" key={j}>
                                    <FormGroup>
                                      <Label
                                        htmlFor="name"
                                        className="capitalize"
                                      >
                                        {item.period}
                                      </Label>
                                      <Input
                                        type="number"
                                        id="name"
                                        placeholder="Enter amount in $"
                                        disabled={each.noOfSeats <= 0}
                                        value={item.amount}
                                        onChange={(e) =>
                                          this._handleSpaceChange(
                                            e,
                                            "rates",
                                            index,
                                            j
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                ))}
                              </Row>
                            </Col>
                          )}
                          {(each.spaceType === "Conference Room" ||
                            each.spaceType === "Private Office") && (
                            <Col xs="12">
                              <Row className="seat-details">
                                <Col xs="6">
                                  <FormGroup>
                                    <Label htmlFor="name">
                                      {each.spaceType + "(s)"}
                                    </Label>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="conference-form">
                                <Col xs="3">
                                  <FormGroup>
                                    <Label htmlFor="name">No of Seats</Label>
                                    <Input
                                      type="number"
                                      id="name"
                                      placeholder="0"
                                      value={each.noOfSeats}
                                      onChange={(e) =>
                                        this._handleSpaceChange(
                                          e,
                                          "noOfSeats",
                                          index
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs="3">
                                  <FormGroup>
                                    <Label htmlFor="name">No of Rooms</Label>
                                    <Input
                                      type="number"
                                      id="name"
                                      placeholder="0"
                                      value={each.roomCapacity}
                                      disabled={each.noOfSeats <= 0}
                                      onChange={(e) =>
                                        this._handleSpaceChange(
                                          e,
                                          "roomCapacity",
                                          index
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col xs="3">
                                  <FormGroup>
                                    <Label htmlFor="name">Space Name</Label>
                                    <Input
                                      type="text"
                                      id="name"
                                      placeholder="Name"
                                      value={each.spaceName}
                                      disabled={each.noOfSeats <= 0}
                                      onChange={(e) =>
                                        this._handleSpaceChange(
                                          e,
                                          "spaceName",
                                          index
                                        )
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                {each.rates.map((rate, rateIndex) => (
                                  <Col xs="3" key={rateIndex}>
                                    <FormGroup>
                                      <Label htmlFor="name">
                                        {rate.period} ({rate.currency})
                                      </Label>
                                      <Input
                                        type="text"
                                        id="name"
                                        placeholder="name"
                                        disabled={each.noOfSeats <= 0}
                                        value={rate.amount}
                                        onChange={(e) =>
                                          this._handleSpaceChange(
                                            e,
                                            "rates",
                                            index,
                                            rateIndex
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                ))}
                              </Row>
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}

                    {/* <Col xs="12" className="text-center">
                    <FormGroup>
                    <Button type="button" color="primary" >Add</Button>
                    </FormGroup>
                  </Col>
                  <button type="button" className="button-delete"><span className="button-inner"><i className="fa fa-trash" aria-hidden="true"></i></span></button> */}
                    <Row>
                      <Col md="3">
                        <Label>Amenities</Label>
                      </Col>
                      <Col md="12" className="facilities-panel">
                        {amenities.map((each, index) => (
                          <FormGroup key={index} check inline>
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id={`facility` + index}
                              name="facilities"
                              checked={each.isSelected}
                              onChange={(e) =>
                                this._handleCheckChange(e, index)
                              }
                            />
                            <Label
                              className="form-check-label"
                              check
                              htmlFor={`facility` + index}
                            >
                              {each.title.en}
                            </Label>
                          </FormGroup>
                        ))}
                        {this.state.errors && (
                          <div className="validation-error">
                            {this.state.errors.facilities}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <Label>Working Days</Label>
                      </Col>
                      <Col md="12" className="workingdays-panel">
                        {this.state.workingDays.map((each, index) => (
                          <FormGroup key={index} check inline>
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id={`workingday` + index}
                              name="workingdays"
                              checked={each.isSelected}
                              onChange={(e) =>
                                this._handleWorkingDaysChange(e, index)
                              }
                            />
                            <Label
                              className="form-check-label"
                              check
                              htmlFor={`workingdays` + index}
                            >
                              {each.day}
                            </Label>
                          </FormGroup>
                        ))}
                        {this.state.errors && (
                          <div className="validation-error">
                            {this.state.errors.workingDays}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <Label htmlFor="startWorkingHours">Working Hours</Label>
                        <Row>
                          <FormGroup>
                            <InputGroup
                              className={
                                this.state.errors &&
                                this.state.errors.startWorkingHours
                                  ? "invalid-input"
                                  : "valid-input"
                              }
                            >
                              <DatePicker
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Start Time"
                                timeFormat="HH:mm"
                                dateFormat="HH:mm"
                                className="form-control"
                                selected={propertyDetails.workingHours.from}
                                name="startWorkingHours"
                                onChange={(e) =>
                                  this._handleDateChange("from", e)
                                }
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fa fa-calendar"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                            {this.state.errors && (
                              <div className="validation-error">
                                {this.state.errors.startWorkingHours}
                              </div>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <InputGroup
                              className={
                                this.state.errors &&
                                this.state.errors.endWorkingHours
                                  ? "invalid-input"
                                  : "valid-input"
                              }
                            >
                              <DatePicker
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="End Time"
                                timeFormat="HH:mm"
                                dateFormat="HH:mm"
                                className="form-control"
                                selected={propertyDetails.workingHours.end}
                                name="endWorkingHours"
                                onChange={(e) =>
                                  this._handleDateChange("end", e)
                                }
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fa fa-calendar"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                            {this.state.errors && (
                              <div className="validation-error">
                                {this.state.errors.endWorkingHours}
                              </div>
                            )}
                          </FormGroup>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <Label htmlFor="holidays">Holidays</Label>
                        <FormGroup>
                          <InputGroup
                            className={
                              this.state.errors && this.state.errors.holidays
                                ? "invalid-input"
                                : "valid-input"
                            }
                          >
                            <DatePicker
                              className="form-control"
                              //   selected={}
                              name="holidays"
                              onSelect={(e) => this._handleHolidayChange(e)}
                              dateFormat="MMM d, yyyy"
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <i className="fa fa-calendar"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.holidays}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    {this.state.holidayList &&
                      this.state.holidayList.map((each, index) => (
                        <div key={index}>
                          {/* <FormGroup> */}

                          <Row
                            style={{
                              margin: "10px",
                              backgroundColor: "#dddddd",
                              borderRadius: "6px",
                              padding: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Col>
                              {each.isRecurring
                                ? moment(each.date).format("MMM Do")
                                : moment(each.date).format("MMM Do YY")}
                            </Col>
                            <Col>
                              <Input
                                type="text"
                                name="holidayLabel"
                                id="holidayLabel"
                                placeholder="Enter Holiday Name"
                                value={each.label ?? ""}
                                onChange={(e) =>
                                  this._handleHolidayLabel(
                                    index,
                                    e.target.value
                                  )
                                }
                                className={
                                  this.state.errors && this.state.errors.label
                                    ? "invalid-input"
                                    : "valid-input"
                                }
                              />
                            </Col>
                            <Col>
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id={`repeat`}
                                name="facilities"
                                checked={each.isRecurring}
                                onChange={(e) =>
                                  this._handleHolidayRepeat(index)
                                }
                              />
                              <Label
                                className="form-check-label"
                                check
                                htmlFor={`repeat`}
                              >
                                Repeat?
                              </Label>
                            </Col>
                            <Col>
                              <span
                                onClick={() => this._handleDeleteHoliday(index)}
                              >
                                Delete
                              </span>
                            </Col>
                          </Row>
                          {/* </FormGroup> */}
                        </div>
                      ))}
                    <Row>
                      <Col xs="4">
                        <FormGroup>
                          <Label htmlFor="contactNo">Contact Number</Label>
                          <FormGroup>
                            <Input
                              type="text"
                              name="contactNo"
                              id="contactNo"
                              value={propertyDetails.contactNo}
                              onChange={this._handleOnChange}
                              className={
                                this.state.errors && this.state.errors.contactNo
                                  ? "invalid-input"
                                  : "valid-input"
                              }
                            ></Input>
                            {this.state.errors && (
                              <div className="validation-error">
                                {this.state.errors.contactNo}
                              </div>
                            )}
                          </FormGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label htmlFor="name">About</Label>
                          <Input
                            type="textarea"
                            name="description"
                            id="textarea-input"
                            rows="9"
                            placeholder="Content..."
                            value={propertyDetails.description}
                            onChange={this._handleOnChange}
                            className={
                              this.state.errors && this.state.errors.description
                                ? "invalid-input"
                                : "valid-input"
                            }
                          />
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.description}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="4">
                        <FormGroup>
                          <Label htmlFor="termsandconditions">
                            Terms and Conditions &nbsp;
                            {this.state.uploadingTermsAndConditions && (
                              <i className="fa fa-spinner fa-spin"></i>
                            )}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs="8">
                        <FormGroup>
                          <InputGroup>
                            <Input
                              type="file"
                              id="termsandconditions"
                              placeholder="0"
                              accept="application/pdf"
                              name="fileUrls"
                              disabled={this.state.uploadingTermsAndConditions}
                              onChange={(e) => this._fileChange(e, "fileUrls")}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="4">
                        <FormGroup>
                          <Label htmlFor="name">
                            Pictures &nbsp;
                            {this.state.uploadingImage && (
                              <i className="fa fa-spinner fa-spin"></i>
                            )}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs="8">
                        <FormGroup>
                          <InputGroup>
                            <Input
                              type="file"
                              id="name"
                              placeholder="0"
                              accept="image/*"
                              name="picUrls"
                              disabled={this.state.uploadingImage}
                              onChange={(e) => this._fileChange(e, "picUrls")}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col xs="12" className="edit-property">
                        {propertyDetails.picUrls.map((each, index) => (
                          <p className="image-upload" key={each}>
                            <img src={each} alt="pic" />
                            {propertyDetails.picUrls.length > 1 && (
                              <button
                                type="button"
                                className="button-delete"
                                onClick={() => this._removePropertyImage(index)}
                              >
                                <span className="button-inner">
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </button>
                            )}
                          </p>
                        ))}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="4">
                        <FormGroup>
                          <Label htmlFor="name">
                            Video &nbsp;
                            {/* {this.state.uploadingVideo && <i className="fa fa-spinner fa-spin"></i> } */}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs="8">
                        <FormGroup>
                          <InputGroup>
                            <Input
                              type="file"
                              id="name"
                              name="videoUrls"
                              placeholder="0"
                              accept="video/mp4,video/x-m4v,video/*"
                              disabled={this.state.uploadingVideo}
                              onChange={(e) => this._fileChange(e, "videoUrls")}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col xs="12">
                        <p className="video-section">
                          {!this.state.uploadingVideo && (
                            <video width="320" height="240" controls>
                              <source
                                src={propertyDetails.videoUrls[0]}
                              ></source>
                            </video>
                          )}
                          {this.state.uploadingVideo && (
                            <span>
                              {" "}
                              <i className="fa fa-spinner fa-spin"></i> &nbsp;
                              Uploading Video
                            </span>
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Row className="submit-panel">
                      <Col xs="12">
                        <FormGroup>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={this.state.savingData}
                          >
                            {this.state.savingData && (
                              <i className="fa fa-spinner fa-spin mr5"></i>
                            )}
                            Save
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Form>
              </Card>
            )}
            {this.state.loadingData && (
              <Card>
                <CardBody>
                  <div className="loading-section no-border">
                    <i className="fa fa-spinner fa-spin"></i> &nbsp; Loading
                    Property Details..
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddNewPlace;
