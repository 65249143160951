import React, { Component } from "react";
import {
  // Card, CardBody, CardHeader, Col, Row,
  //   FormGroup,
  // FormText,
  // FormFeedback,
  Input,
  Label,
  // Img,
  //   Button,
  // InputGroupAddon,
  // InputGroupButtonDropdown,
  // InputGroupText,
  // Label,
  //   Table,
  //  InputGroup
} from "reactstrap";
import { ToastsStore } from "react-toasts";
import { getAllChatThreads, getChatHistory } from "../../../http/http-calls";
import { uploadFileMultiPart } from "../../../http/http-service";
import config from "../../../config/index";
var Faye = window.Faye;
// function buildFileSelector(){
//   const fileSelector = document.createElement('input');
//   fileSelector.setAttribute('type', 'file');
//   fileSelector.setAttribute('onChange', this._fileChange );
//   return fileSelector;
// }
class Chat extends Component {
  state = {
    chatThreads: [],
    client: {},
    message: "",
    activeChatThread: null,
    subscribedThreads: [],
    chatImage: "",
    uploadingImage: false,
  };

  _getAllChatThreads = () => {
    getAllChatThreads().then(
      (response) => {
        console.log(response);
        let chatThreads = response.threads.map((each) => {
          return {
            _id: each._id,
            participant: each._participants.filter((item) => {
              return !item.isAdmin;
            })[0],
            admin: each._participants.filter((item) => {
              return item.isAdmin;
            })[0],
          };
        });
        this.setState({ chatThreads }, () => {
          if (chatThreads.length) {
            this._startChat(chatThreads[0]);
          }
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  _configureChatClient = () => {
    let client = new Faye.Client(config.chatUrl);
    this.setState({ client });
    console.log(client);
  };

  _startChat = (currentChartThread) => {
    if (!this.state.subscribedThreads.includes(currentChartThread._id)) {
      this.state.client
        .subscribe(`/chat/${currentChartThread._id}`, (newMessage) => {
          console.log("New Message: ", newMessage, currentChartThread._id);
          let { chatThreads } = this.state;
          let chatThread = chatThreads.find((each) => {
            return each._id === currentChartThread._id;
          });
          chatThread._messages.push({
            what: newMessage.what,
            by:
              chatThread.admin._id === newMessage.by
                ? chatThread.admin
                : chatThread.participant,
            forWhom:
              chatThread.admin._id === newMessage.forWhom
                ? chatThread.admin
                : chatThread.participant,
            whoami: newMessage.whoami,
            images: newMessage.images,
          });

          chatThread = JSON.parse(JSON.stringify(chatThread));
          console.log(chatThread);

          // chatThread['id'] = chatThread._id;
          this.setState({ activeChatThread: chatThread }, () => {
            console.log("=====>", this.state.activeChatThread._id);
          });
        })
        .then(() => {
          console.log("Subscription is now active!", currentChartThread._id);
          let { subscribedThreads } = this.state;
          subscribedThreads.push(currentChartThread._id);
          this.setState({ subscribedThreads });
          // this.setState({activeChatThread: {}},()=>{
          //   console.log('set activeChatThread', this.state);
          //   setTimeout(()=>{
          //     console.log('after set activeChatThread', this.state);
          //   },2000)
          // });
          this._getChatHistory(currentChartThread._id);
        });
    } else {
      this._getChatHistory(currentChartThread._id);
    }
  };

  _getChatHistory = (chatThreadId) => {
    getChatHistory(chatThreadId).then(
      (response) => {
        console.log(response);
        let { activeChatThread, chatThreads } = this.state;
        let chatThread = chatThreads.find((each) => {
          return each._id === chatThreadId;
        });
        console.log("chatThread====>", chatThread);
        chatThread._messages = response.thread._messages;

        activeChatThread = JSON.parse(JSON.stringify(chatThread));
        console.log("asasasasas", activeChatThread);

        this.setState({ activeChatThread }, () => {
          console.log("after state update: ", this.state.activeChatThread);
        });
        // setInterval(() => {
        //   console.log(this.state.activeChatThread._id);

        // }, 2000);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  _messageOnChange = ({ currentTarget }) => {
    let { message } = this.state;
    message = currentTarget.value;
    this.setState({ message });
  };

  _publishMessage = () => {
    console.log(this.state.activeChatThread);
    this.state.client
      .publish(`/chat/${this.state.activeChatThread._id}`, {
        what: this.state.message,
        by: this.state.activeChatThread.admin._id,
        forWhom: this.state.activeChatThread.participant._id,
        whoami: this.state.activeChatThread.admin.name.full,
        images: this.state.chatImage.length ? [this.state.chatImage] : [],
      })
      .then(() => {
        console.log("Message Published!");
        let { message, chatImage } = this.state;
        message = "";
        chatImage = "";
        this.setState({ message, chatImage });
      })
      .catch((err) => console.log(err));
  };

  _isDisabledSendBtn = () => {
    return !this.state.message.trim().length;
  };

  _clearImage = () => {
    this.setState({ chatImage: "" });
  };

  _fileChange = (event) => {
    console.log(event);
    const file = event.target.files[0];
    if (file) {
      this._uploadFile(event.target.files[0]);
    }
  };
  _uploadFile = (file) => {
    console.log(file);
    var fd = new FormData();
    fd.append("file", file);
    this.setState({ uploadingImage: true });
    uploadFileMultiPart(false, fd, "image").then(
      (response) => {
        console.log(response.secure_url);
        let { chatImage, uploadingImage } = this.state;
        chatImage = response.secure_url;
        uploadingImage = false;
        this.setState({ chatImage, uploadingImage });
      },
      (error) => {
        console.log(error);
        this.setState({ uploadingImage: false });
        ToastsStore.error(error.reason || "Something Went Wrong!", 3000);
      }
    );
  };

  componentDidMount() {
    // this.fileSelector = buildFileSelector();
    this._configureChatClient();
    this._getAllChatThreads();
  }

  render() {
    let { chatThreads, activeChatThread, chatImage, uploadingImage } =
      this.state;
    console.log("in render:: ", activeChatThread);
    return (
      <div className="animated fadeIn">
        <div className="chat-section">
          <div className="chat-left">
            <h2>Chats</h2>
            <div className="chat-panel">
              {chatThreads.map((each) => (
                <div
                  key={each._id}
                  onClick={() => this._startChat(each)}
                  className={`chat-item ${
                    each._id === activeChatThread?._id ? "active" : ""
                  }`}
                >
                  <img
                    src={
                      (each.participant && each.participant.pictureUrl) ||
                      "../../assets/img/avatars/user.png"
                    }
                    className="img-avatar"
                    alt="admin@bootstrapmaster.com"
                  />
                  <div className="chats">
                    <p>
                      <span>
                        {each.participant && each.participant.name.full}
                      </span>{" "}
                      {each.participant && each.participant.userType}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="chat-right">
            <h2>
              {`${
                activeChatThread?.participant?.name?.full ?? ""
              } (Conversation)`}
            </h2>
            <div className="chat-panel">
              {activeChatThread &&
                activeChatThread._messages &&
                activeChatThread._messages.map((message, msgIndex) => (
                  <div
                    key={msgIndex}
                    className={
                      (message.by && message.by._id) ===
                      (activeChatThread.admin && activeChatThread.admin._id)
                        ? "chat-item admin-message"
                        : "chat-item"
                    }
                  >
                    <img
                      src={
                        (message.by && message.by.pictureUrl) ||
                        "../../assets/img/avatars/user.png"
                      }
                      className={
                        msgIndex === 0 ||
                        message.by._id !==
                          activeChatThread._messages[msgIndex - 1].by._id
                          ? "img-avatar"
                          : "image-avatar hide-image"
                      }
                      alt="user"
                    />
                    <div>
                      <p>{message.what}</p>
                      <div>
                        {message.images && message.images.length > 0 && (
                          <img
                            className="chat-image-content"
                            src={message.images[0]}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {chatImage && (
              <div className="chat-box chat-image-preview">
                <span>
                  <img src={chatImage} alt=""></img>
                  <i className="fa fa-times" onClick={this._clearImage}></i>
                </span>
              </div>
            )}
            <div className="chat-box">
              <textarea
                rows="1"
                placeholder="Leave a Reply"
                value={this.state.message}
                onChange={(e) => this._messageOnChange(e)}
              ></textarea>
              <Label className="select-image mr-2">
                <Input
                  type="file"
                  id="name"
                  accept="image/*"
                  name="picUrls"
                  disabled={uploadingImage || chatImage.length}
                  onChange={(e) => this._fileChange(e)}
                />
                {!uploadingImage && <i className="fa fa-file-image-o"></i>}
                {uploadingImage && <i className="fa fa-spinner fa-spin"></i>}
              </Label>
              <button
                onClick={() => this._publishMessage()}
                disabled={this._isDisabledSendBtn()}
              >
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Chat;
