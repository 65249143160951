import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "reactstrap";

class ConfirmAction extends Component {
  state = {
    loading: false,
  };

  _manageLoading = (status) => {
    let { loading } = this.state;
    loading = status;
    this.setState({ loading });
  };

  _closeModal = (action) => {
    console.log(action);
    // this._manageLoading(true);
    this.props.toggle(action);
  };

  componentWillReceiveProps(nextProps) {
    this._manageLoading(false);
  }

  render() {
    console.log(this.props.data);
    return (
      <Modal
        className="facility-modal"
        isOpen={this.props.isOpen}
        toggle={() => {
          this._closeModal("close");
        }}
      >
        <ModalHeader
          toggle={() => {
            this._closeModal("close");
          }}
        >
          Errors
        </ModalHeader>
        <ModalBody>
          {this.props.isArray ? (
            <ul>
              {this.props.data &&
                this.props.data.map((error) => <li>{error}</li>)}
            </ul>
          ) : (
            <p>{this.props.data}</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            color="primary"
            onClick={() => {
              this._closeModal("close");
            }}
            // disabled={this.state.loading}
            // onClick={() => {
            //   this._closeModal(this.props.data && this.props.data.action);
            // }}
          >
            {/* {this.state.loading && (
              <i className="fa fa-spinner fa-spin mr5"></i>
            )} */}
            OK
          </Button>
          {/* <Button
            type="button"
            color="secondary"
            onClick={() => {
              this._closeModal("close");
            }}
          >
            No
          </Button> */}
        </ModalFooter>
      </Modal>
    );
  }
}

export default ConfirmAction;
