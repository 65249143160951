import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "../assets/css/Table.css";
class CustomTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: props.tableData,
      options: {
        page: 1, // which page you want to show as default
        sizePerPageList: [
          {
            text: "10",
            value: 10,
          },
          {
            text: "20",
            value: 20,
          },
          {
            text: "50",
            value: 50,
          },
        ], // you can change the dropdown list for size per page
        sizePerPage: 10, // which size per page you want to locate as default
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 3, // the pagination bar size.
        prePage: "Prev", // Previous page button text
        nextPage: "Next", // Next page button text
        firstPage: "First", // First page button text
        lastPage: "Last", // Last page button text
        // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
        // paginationPosition: 'top'  // default is bottom, top and both is all available
        hideSizePerPage: true, // //You can hide the dropdown for sizePerPage
        // alwaysShowAllBtns: true // Always show next and previous button
        withFirstAndLast: true, //> Hide the going to First and Last page button
      },
    };
  }
  renderShowsTotal(start, to, total) {
    return (
      <p style={{ color: "#20a8d8" }}>
        From {start} to {to}, totals is {total}
      </p>
    );
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    let { tableData } = this.state;
    tableData = nextProps.tableData;
    this.setState({ tableData }, () => {
      console.log(this.state.tableData);
    });
  }
  // rowStyleFormat(row, rowIdx) {
  //   if (this.state) {
  //     this.state.tableData.forEach((element, index) => {
  //       if (this.state.tableData?.rawColor) {
  //         return {
  //           backgroundColor: rowIdx === index ? element.rawColor : "",
  //         };
  //       }
  //       return;
  //     });
  //   }
  // }

  render() {
    return (
      <div>
        <BootstrapTable
          data={this.state.tableData}
          pagination={true}
          options={this.state.options}
          version="4"
          responsive
          hover={true}
          // trStyle={this.rowStyleFormat}
        >
          {this.props.headerKeys.map((header) => (
            <TableHeaderColumn
              dataField={header.id}
              key={header.id}
              isKey={header.id === "name" || header.id === "Name"}
              dataAlign="left"
              dataSort={header.id !== "action"}
              dataFormat={(cell, row) =>
                this.props.dataFormat(cell, row, header.id)
              }
            >
              {header.label}
            </TableHeaderColumn>
          ))}
        </BootstrapTable>
      </div>
    );
  }
}

export default CustomTable;
