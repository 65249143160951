import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import * as router from 'react-router-dom';
import { Container } from "reactstrap";

import {
  // AppAside,
  AppFooter,
  AppHeader,
  // AppSidebar,
  // AppSidebarFooter,
  // AppSidebarForm,
  // AppSidebarHeader,
  // AppSidebarMinimizer,
  // AppBreadcrumb2 as AppBreadcrumb,
  // AppSidebarNav2 as AppSidebarNav,
} from "@coreui/react";
// sidebar nav config
// import navigation from '../../_nav';
// routes config
// import routes from '../../routes';

import Renters from "../../views/Pages/Renters/Renters";
import Rentee from "../../views/Pages/Rentee/Rentee";
import Properties from "../../views/Pages/Properties/Properties";
import Booking from "../../views/Pages/Booking/Booking";
import Facilities from "../../views/Pages/Settings/Facilities";
import StaticContent from "../../views/Pages/Settings/StaticContent";
import ViewRenterDetails from "../../views/Pages/Renters/ViewRenterDetails";
import ViewRenteeDetails from "../../views/Pages/Rentee/ViewRenteeDetails";
import EditRenterDetails from "../../views/Pages/Renters/EditRenterDetails";
import EditRenteeDetails from "../../views/Pages/Rentee/EditRenteeDetails";

import ViewBooking from "../../views/Pages/Booking/ViewBooking";
import EditBooking from "../../views/Pages/Booking/EditBooking";

import ViewPlace from "../../views/Pages/Properties/ViewPlace";
import AddEditPlace from "../../views/Pages/Properties/AddEditPlace";
import Chat from "../../views/Pages/Chat/Chat";
import WeeklyPayoutSummary from "../../views/Pages/WeeklyPayoutSummary/WeeklyPayoutSummary";
import ProtectedRoute from "../../components/ProtectedRoute";
import RequestList from "../../views/Pages/BookingModifications/RequestList";
import RequestDetails from "../../views/Pages/BookingModifications/RequestDetails";
import AddRenteeDetails from "../../views/Pages/Rentee/AddRenteeDetails";
import AddRenterDetails from "../../views/Pages/Renters/AddRenterDetails";
import AddPlace from "../../views/Pages/Properties/ViewPlace";
import AddNewPlace from "../../views/Pages/Properties/AddPlace";
import CancellationRequests from "../../views/Pages/ProviderCancellationRequests/CancellationRequests";
import ViewCancellationRequests from "../../views/Pages/ProviderCancellationRequests/ViewCancellationRequests";

// const DefaultAside = React.lazy(() => import('./DefaultAside'));
const DefaultFooter = React.lazy(() => import("./DefaultFooter"));
const DefaultHeader = React.lazy(() => import("./DefaultHeader"));

class DefaultLayout extends Component {
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  signOut(e) {
    e.preventDefault();
    this.props.history.push("/login");
  }

  render() {
    console.log(this.props.match.path);
    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense fallback={this.loading()}>
            <DefaultHeader onLogout={(e) => this.signOut(e)} {...this.props} />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          {/* <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
            // <AppSidebarNav navConfig={navigation} {...this.props} router={router}/>
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar> */}
          <main className="main">
            {/* <AppBreadcrumb appRoutes={routes} router={router}/> */}
            <Container fluid>
              {/* <Suspense fallback={this.loading()}> */}
              <Switch>
                <ProtectedRoute
                  path={`${this.props.match.path}/renters`}
                  component={Renters}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/providers`}
                  component={Rentee}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/properties`}
                  component={Properties}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/booking`}
                  component={Booking}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/amenities`}
                  component={Facilities}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/static-content`}
                  component={StaticContent}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/renter-details/:id`}
                  component={ViewRenterDetails}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/provider-details/:id`}
                  component={ViewRenteeDetails}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/add-renter`}
                  component={AddRenterDetails}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/edit-renter/:id`}
                  component={EditRenterDetails}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/add-provider`}
                  component={AddRenteeDetails}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/edit-provider/:id`}
                  component={EditRenteeDetails}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/booking-details/:id`}
                  component={ViewBooking}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/edit-booking`}
                  component={EditBooking}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/add-place`}
                  component={AddNewPlace}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/edit-place/:id`}
                  component={AddEditPlace}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/place-details/:id`}
                  component={ViewPlace}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/chat`}
                  component={Chat}
                  redirectRoute={"/login"}
                />
                <ProtectedRoute
                  path={`${this.props.match.path}/weekly-payout-summary`}
                  component={WeeklyPayoutSummary}
                  redirectRoute={"/login"}
                />
                <Route
                  path={`${this.props.match.path}/booking-modification-requests`}
                  component={RequestList}
                  redirectRoute={"/login"}
                />
                <Route
                  path={`${this.props.match.path}/request-details/:id`}
                  component={RequestDetails}
                  redirectRoute={"/login"}
                />
                <Route
                  path={`${this.props.match.path}/cancellation-requests`}
                  component={CancellationRequests}
                  redirectRoute={"/login"}
                />
                <Route
                  path={`${this.props.match.path}/cancellation-request-details/:id`}
                  component={ViewCancellationRequests}
                  redirectRoute={"/login"}
                />
                <Route
                  path="/"
                  render={() => <Redirect to="/dashboard/renters" />}
                />
              </Switch>
              {/* </Suspense> */}
            </Container>
          </main>
          {/* <AppAside fixed>
            <Suspense fallback={this.loading()}>
              <DefaultAside />
            </Suspense>
          </AppAside> */}
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
