import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {Form, FormGroup, Label, Input, Button, Row, Col } from "reactstrap";
import {uploadFileMultiPart} from '../../../http/http-service';
import {addAmenity, updateAmenity} from '../../../http/http-calls';
import { ToastsStore } from 'react-toasts';
class AddEditFacility extends Component {
  state = {
    amenity: {
      title: {en:'', es:'', pt:''},
      iconUrl: "",
      _id:""
    },
    errors:{},
    isDirty:{
      title: false,
      iconUrl: false
    },
    loading: false,
    uploadingImage:false
  };
  _handleOnChange = ({currentTarget},key, language) => {
    // console.log(currentTarget);
    const {amenity, isDirty} = this.state;
    amenity[key][language] = currentTarget.value;
    isDirty[currentTarget.name] = true;
    this.setState({amenity});
    this._validateForm();
  }

  imageChange=(event) =>{
    // console.log(event.target.files[0]);
    const file = event.target.files[0];
    if(file){
      this._uploadImage(event.target.files[0]);
    } else {
      const {amenity} = this.state;
      amenity.iconUrl = '';
      this.setState({amenity});
    }
  }

  _handleOnSubmit = (event)=> {
    event.preventDefault();
    const {isDirty} = this.state;
    isDirty.title = true;
    isDirty.iconUrl = true;
    this.setState({isDirty});
    // console.log(this.state.isDirty);
    let errors = this._validateForm();
    // console.log(errors);
    if(!errors){
      console.log('Make API call', this.state);
      if(this.props.modalType==='add'){
        this._addAmenity();
      } else{
        this._updateAmenity();
      }
    }
  }

  _validateForm() {
    let {amenity, errors, isDirty} = this.state;
    errors={};
    console.log(amenity);
    Object.keys(amenity).forEach((each)=> {
      if(each === 'title' && isDirty.title){
        if(!amenity.title.en.trim().length){
          errors.title = 'Name is Required';
        }
         else{
          delete errors[each];
          isDirty.title = false;
        }
      } else if(each === 'iconUrl' && isDirty.iconUrl) {
        if(!amenity.iconUrl.trim().length){
          // console.log(amenity.iconUrl);
          errors[each] = 'Amenity image is Required';
        } else{
          delete errors[each];
          isDirty.iconUrl = false;
        }
      }
    })
    this.setState({errors});
    return Object.keys(errors).length ? errors : null;
  }

  _uploadImage(file){
    var fd = new FormData();
    fd.append("file", file);
    this._manegeLoading('image',true);
    uploadFileMultiPart(false, fd)
    .then((response)=>{
      console.log(response.secure_url);
      const {amenity} = this.state;
      amenity.iconUrl = response.secure_url;
      this.setState({amenity});
      this._manegeLoading('image',false);
      this._validateForm();
    },(error)=>{
      console.log(error);
      this._manegeLoading('image',false);
      ToastsStore.error(error.reason || 'Something Went Wrong!', 3000);
    })
  }

  _addAmenity(){
    this._manegeLoading('amenity',true);
    addAmenity(this.state.amenity)
    .then((response)=>{
      console.log(response);
      this._manegeLoading('amenity',false);
      this._closeModal('reload');
      ToastsStore.success("Amenity added successfully!", 3000);
    },(error)=>{
      console.log(error);
      this._manegeLoading('amenity',false);
      ToastsStore.error(error.reason, 3000);
    })
  }

  _updateAmenity(){
    this._manegeLoading('amenity',true);
    updateAmenity(this.state.amenity)
    .then((response)=>{
      console.log(response);
      this._manegeLoading('amenity',false);
      this._closeModal('reload');
      ToastsStore.success("Amenity updated successfully!", 3000);
    },(error)=>{
      console.log(error);
      this._manegeLoading('amenity',false);
      ToastsStore.error(error.reason, 3000);
    })
  }

  _closeModal = (action) => {
    console.log('in _closeModal');
    let {amenity, errors} = this.state;
    amenity = { 
       title: {en:'', es:'', pt:''},
      iconUrl: "",
      _id:""
    };
    errors = null;
    this.setState({amenity, errors},()=>{
      console.log(amenity, errors)
      // this._validateForm();
      this.props.toggle(action);
    });
  }

  componentDidMount() {
    console.log('componentDidMount',this.props);
    if (this.props.modalType === "edit") {
      console.log(this.props.initialData);
    }
  }

  _manegeLoading(key, value){
    let { loading, uploadingImage } = this.state;
    if(key === 'image'){
      uploadingImage = value;
      this.setState({uploadingImage});
    } else if(key === 'amenity'){
      loading = value;
      this.setState({loading});
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log('componentWillReceiveProps',nextProps);
    let {amenity} = this.state;
    if (nextProps.modalType === "edit") {
      console.log(nextProps.initialData);
      amenity.title = nextProps.initialData.title;
      amenity.iconUrl = nextProps.initialData.iconUrl;
      amenity._id = nextProps.initialData._id;
      this.setState({amenity},()=>{
        console.log(this.state.amenity);
        this._validateForm();
      });
    } else{
      amenity = { 
         title: {en:'', es:'', pt:''},
        iconUrl: "",
        _id:""
      };
      this.setState({amenity},()=>{
        console.log(amenity)
      });
    }
}

  render() {
    return (
      <Modal
        className="facility-modal"
        isOpen={this.props.isOpen}
        toggle={()=>{this._closeModal('close')}}
      >
        <ModalHeader toggle={()=>{this._closeModal('close')}}>
          {this.props.modalType === "add" && "Add Amenities"}
          {this.props.modalType === "edit" && "Edit Amenities"}
        </ModalHeader>
        <Form onSubmit={this._handleOnSubmit} noValidate>
        <ModalBody>
          <FormGroup>
            <Label htmlFor="name">Name in English</Label>
            <Input
              type="text"
              id="name"
              name="title"
              placeholder="Enter Amenities Name"
              value={this.state.amenity.title.en}
              onChange={(e)=>this._handleOnChange(e,'title','en')}
              autoComplete="off"
              className={this.state.errors && this.state.errors.title ? 'invalid-input' : 'valid-input'}
            />
            {this.state.errors && <div className="validation-error">{this.state.errors.title}</div>}
          </FormGroup>
          <Row>
            <Col xs="6">
              <FormGroup>
                <Label htmlFor="name">Name in Spanish</Label>
                <Input
                  type="text"
                  id="name"
                  name="titleInES"
                  placeholder="Enter Amenities Name"
                  value={this.state.amenity.title.es}
                  onChange={(e)=>this._handleOnChange(e,'title','es')}
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
            <Col xs="6">
              <FormGroup>
                <Label htmlFor="name">Name in Portuguese</Label>
                <Input
                  type="text"
                  id="name"
                  name="titleInPT"
                  placeholder="Enter Amenities Name"
                  value={this.state.amenity.title.pt}
                  onChange={(e)=>this._handleOnChange(e,'title','pt')}
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup className="amenity-image-upload">
            <Label htmlFor="name">Image</Label>
            <Input type="file" name="iconUrl" id="file" accept="image/*" 
              onChange={this.imageChange} required />
            {this.state.errors && <div className="validation-error">{this.state.errors.iconUrl}</div>}
            {this.state.uploadingImage && <i className="fa fa-spinner fa-spin image-loader"></i>}
          </FormGroup>
          {this.state.amenity.iconUrl && <img src={this.state.amenity.iconUrl} alt="iconurl" width="30"></img>}
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" disabled={this.state.loading}>
            {this.state.loading && <i className="fa fa-spinner fa-spin mr5"></i> }
            Save
          </Button>
          <Button type="button" color="secondary" onClick={()=>{this._closeModal('close')}}>
            Cancel
          </Button>
        </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default AddEditFacility;
