import { Route, Redirect } from "react-router-dom";
import React from 'react';
const ProtectedRoute = ({component: Component, ...rest}) => {
  console.log(rest.path);
  return ( 
    <Route {...rest} render={props=>{
      if(localStorage.huddleSpotAdminToken){
        return <Component {...props}/>
      }else{
        return <Redirect to={{pathname: rest.redirectRoute, extras: {...rest.location}}} />
      }
    }} />
   );
}
 
export default ProtectedRoute;