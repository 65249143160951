import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "reactstrap";

class ConfirmAction extends Component {
  state = {
    loading: false,
  };

  _manageLoading = (status) => {
    let { loading } = this.state;
    loading = status;
    this.setState({ loading });
  };

  _closeModal = (action) => {
    console.log(action);
    this._manageLoading(true);
    this.props.toggle(action);
  };

  componentWillReceiveProps(nextProps) {
    this._manageLoading(false);
  }

  render() {
    console.log(this.props.data);
    return (
      <Modal
        className="facility-modal"
        isOpen={this.props.isOpen}
        toggle={() => {
          this._closeModal("close");
        }}
      >
        <ModalHeader
          toggle={() => {
            this._closeModal("close");
          }}
        >
          Confirm Booking Cancellation
        </ModalHeader>
        <ModalBody>
          <p>
            Are you sure you want to{" "}
            <strong>{this.props.data && this.props.data.action}</strong> this
            request?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            color="primary"
            disabled={this.state.loading}
            onClick={() => {
              this._closeModal(this.props.data && this.props.data.action);
            }}
          >
            {this.state.loading && (
              <i className="fa fa-spinner fa-spin mr5"></i>
            )}
            Yes
          </Button>
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              this._closeModal("close");
            }}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ConfirmAction;
