import React, { Component } from "react";
import { format } from "date-fns";
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  Row,
  Input,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  getBookingModificationDetails,
  takeActionOnBookingModification,
} from "../../../http/http-calls";
import ConfirmAction from "./ConfirmAction";
import { ToastsStore } from "react-toasts";

class RequestDetails extends Component {
  state = {
    bookingRequestDetails: null,
    fineAmount: 0,
    confirmModal: {
      isOpen: false,
      data: null,
    },
    loadingData: false,
  };

  _manageLoading = (key, value) => {
    console.log(key);
    let { loadingData } = this.state;
    if (key === "data") {
      loadingData = value;
    }
    this.setState({ loadingData });
  };

  _formatDate(date) {
    return format(new Date(date), "MMM d, yyyy");
  }

  _handleOnChange = ({ currentTarget }) => {
    let { fineAmount } = this.state;
    fineAmount = currentTarget.value;
    this.setState({ fineAmount });
  };

  _getBookingModificationDetails = (id) => {
    this._manageLoading("data", true);
    getBookingModificationDetails(id).then(
      (response) => {
        console.log("response :", response);
        this._manageLoading("data", false);
        let { bookingRequestDetails } = this.state;
        bookingRequestDetails = response.bookingRequest;
        this.setState({ bookingRequestDetails });
      },
      (error) => {
        console.log("error :", error);
        this._manageLoading("data", false);
      }
    );
  };

  _onModalToggle = (action) => {
    console.log(action);
    let { confirmModal, fineAmount } = this.state;
    confirmModal.isOpen = !confirmModal.isOpen;
    if (action) {
      console.log("action :", action);
      if (action !== "close") {
        this._takeActionOnBookingModification(
          {
            bookingRequestId: this.props.match.params.id,
            additionalCharges: Number(Number(fineAmount).toFixed(2)),
            remarks: null,
            status: action,
          },
          confirmModal
        );
      } else {
        this.setState({ confirmModal });
      }
    } else {
      this.setState({ confirmModal });
    }
  };

  _takeActionOnBookingModification = (data, confirmModal) => {
    console.log("data :", data);
    takeActionOnBookingModification(data).then(
      (response) => {
        console.log("response :", response);
        ToastsStore.success(response.message);
        this._backToList();
        this.setState({ confirmModal });
      },
      (error) => {
        console.log("error :", error);
      }
    );
  };

  _openConfirmModal = (action) => {
    let { confirmModal } = this.state;
    confirmModal.isOpen = !confirmModal.isOpen;
    confirmModal.data = {
      id: this.props.match.params.id,
      action,
    };
    console.log();
    this.setState({ confirmModal });
  };

  _calculateDiffAmount = () => {
    const { bookingRequestDetails, fineAmount } = this.state;
    return (
      bookingRequestDetails.invoice.totalAmount +
      Number(fineAmount) -
      bookingRequestDetails.previousInvoice.totalAmount
    ).toFixed(2);
  };

  _backToList = () => {
    this.props.history.push("../booking-modification-requests");
  };

  componentDidMount() {
    console.log(this.props.match.params.id);
    this._getBookingModificationDetails(this.props.match.params.id);
  }

  render() {
    const { fineAmount, confirmModal, bookingRequestDetails, loadingData } =
      this.state;
    return (
      <div className="animated fadeIn">
        <Row className="booking-panel edit-booking">
          <Col xs="12" sm="6">
            <div className="page-heading">
              <h2>Booking Request Details</h2>
            </div>
            {!loadingData && bookingRequestDetails && (
              <Card>
                <CardHeader>
                  <strong>
                    Booking{" "}
                    <span className="uppercase">
                      {bookingRequestDetails._previousBooking.shortBookingId}
                    </span>
                  </strong>
                  <Button color="secondary mr-3" onClick={this._backToList}>
                    <i className="fa fa-arrow-left"></i> &nbsp; Back to List
                  </Button>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <h3 className="headingh3">Previous Booking Info</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Place</Label>
                        <p className="view-text">
                          <Link
                            to={`/dashboard/place-details/${bookingRequestDetails._property._id}`}
                          >
                            {bookingRequestDetails._property.buildingName}
                          </Link>
                        </p>
                        <p>
                          {bookingRequestDetails._property.streetAddress},{" "}
                          {bookingRequestDetails._property.city},{" "}
                          {bookingRequestDetails._property.country}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Renter</Label>
                        <p className="view-text">
                          <Link
                            to={`/dashboard/renter-details/${bookingRequestDetails._renter._id}`}
                          >
                            {bookingRequestDetails._renter.name.full}
                          </Link>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Provider</Label>
                        <p className="view-text">
                          <Link
                            to={`/dashboard/provider-details/${bookingRequestDetails._rentee._id}`}
                          >
                            {bookingRequestDetails._rentee.name.full}
                          </Link>
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Start Date</Label>
                        <p className="view-text">
                          {this._formatDate(
                            bookingRequestDetails._previousBooking.startDate
                          )}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">End Date</Label>
                        <p className="view-text">
                          {this._formatDate(
                            bookingRequestDetails._previousBooking.endDate
                          )}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Cycle</Label>
                        <p className="view-text capitalize">
                          {bookingRequestDetails._previousBooking.rentType}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4" className="booking-seats">
                      <FormGroup>
                        <Label htmlFor="name">Booking</Label>
                        <p className="view-text">
                          {
                            bookingRequestDetails._previousBooking._space
                              .spaceType
                          }{" "}
                          x{" "}
                          <span>
                            {bookingRequestDetails._previousBooking.noOfSeats}
                          </span>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Amount</Label>
                        <p className="view-text">
                          $ {bookingRequestDetails.previousInvoice.totalAmount}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <div className="">
                        <hr />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <h3 className="headingh3">Modifications</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Start Date</Label>
                        <p className="view-text">
                          {this._formatDate(bookingRequestDetails.startDate)}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">End Date</Label>
                        <p className="view-text">
                          {this._formatDate(bookingRequestDetails.endDate)}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Cycle</Label>
                        <p className="view-text">
                          {bookingRequestDetails.rentType}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4" className="booking-seats">
                      <FormGroup>
                        <Label htmlFor="name">Booking</Label>
                        <p className="view-text">
                          {bookingRequestDetails._space.spaceType} x{" "}
                          <span>{bookingRequestDetails.noOfSeats}</span>
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Amount</Label>
                        <p className="view-text">
                          $ {bookingRequestDetails.invoice.totalAmount}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Difference</Label>
                        <p className="view-text">
                          $ {Math.abs(this._calculateDiffAmount())}
                          {this._calculateDiffAmount() > 0 && (
                            <span> (Payment Due)</span>
                          )}
                          {this._calculateDiffAmount() < 0 && (
                            <span> (Refund)</span>
                          )}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  {bookingRequestDetails.status === "Requested" ? (
                    <Row className="mt-5">
                      <Col xs="12" sm="12">
                        <Row>
                          <Col xs="8">
                            <FormGroup>
                              <Label htmlFor="name">Fine Amount</Label>
                              <Input
                                className="w-50"
                                type="number"
                                min="0"
                                id="name"
                                name="fineAmount"
                                value={fineAmount}
                                onChange={this._handleOnChange}
                                placeholder="Enter Fine Amount"
                              />
                            </FormGroup>
                          </Col>
                          <Col
                            xs="4"
                            className="d-flex justify-content-end align-items-end"
                          >
                            <FormGroup className="mr-3">
                              <Button
                                type="button"
                                color="primary"
                                onClick={() => this._openConfirmModal("Accept")}
                              >
                                Accept
                              </Button>
                            </FormGroup>
                            <FormGroup>
                              <Button
                                type="button"
                                color="secondary"
                                onClick={() => this._openConfirmModal("Reject")}
                              >
                                Reject
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col xs="4">
                        <FormGroup>
                          <Label htmlFor="name">Status</Label>
                          <p className="view-text">
                            {bookingRequestDetails.status}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            )}
            {loadingData && (
              <Card>
                <CardBody>
                  <div className="loading-section no-border">
                    <i className="fa fa-spinner fa-spin"></i> &nbsp; Loading
                    Request Details..
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
        <ConfirmAction
          isOpen={confirmModal.isOpen}
          data={confirmModal.data}
          toggle={this._onModalToggle}
        ></ConfirmAction>
      </div>
    );
  }
}

export default RequestDetails;
