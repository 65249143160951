import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  // FormText,
  // FormFeedback,
  // Input,
  // Img,
  Button,
  // InputGroupAddon,
  // InputGroupButtonDropdown,
  // InputGroupText,
  Label,
  Table,
  //  InputGroup
} from "reactstrap";
import { Link } from "react-router-dom";
import { AppSwitch } from "@coreui/react";
import {
  getPropertyDetails,
  changePropertyStatus,
  updateProperty,
} from "../../../http/http-calls";
import { ToastsStore } from "react-toasts";
import Player from "@vimeo/player/dist/player";
class AddPlace extends Component {
  state = {
    propertyDetails: null,
    loadingData: false,
    changeStatusLoading: false,
    changeVerifiedLoading: false,
    subRentees: [],
  };
  _gotoEditPlace = () => {
    this.props.history.push("../edit-place/" + this.state.propertyDetails._id);
  };

  _getPropertyDetails = (id) => {
    this._manageLoading("data", true);
    getPropertyDetails(id).then(
      (response) => {
        console.log(response);
        let propertyDetails = response.property;
        propertyDetails._spaces = propertyDetails._spaces.filter((each) => {
          return each.noOfSeats && each.rates.length;
        });
        propertyDetails.facilities = propertyDetails.facilities.map((each) => {
          return typeof each.title === "object" ? each.title.en : each.title;
        });
        this.setState({ propertyDetails });
        this.setState({ subRentees: response.subRentees });
        this._manageLoading("data", false);
        // this._initPlayer();
      },
      (error) => {
        console.log(error);
        this._manageLoading("data", false);
      }
    );
  };

  _manageLoading = (key, value) => {
    let { loadingData, changeStatusLoading, changeVerifiedLoading } =
      this.state;
    if (key === "data") {
      loadingData = value;
    } else if (key === "change-status") {
      changeStatusLoading = value;
    } else if (key === "change-verified") {
      changeVerifiedLoading = value;
    }
    this.setState({ loadingData, changeStatusLoading, changeVerifiedLoading });
  };

  _onStatusChange = () => {
    let { propertyDetails } = this.state;
    propertyDetails.isActive = !propertyDetails.isActive;
    this._manageLoading("change-status", true);
    changePropertyStatus(propertyDetails._id, {
      status: propertyDetails.isActive,
    }).then(
      (response) => {
        console.log(response);
        this.setState({ propertyDetails });
        this._manageLoading("change-status", false);
        ToastsStore.success("Status updated successfully!", 3000);
      },
      (error) => {
        console.log(error);
        this._manageLoading("change-status", false);
        ToastsStore.error(error.reason, 3000);
      }
    );
  };

  _onVerificationChange = () => {
    let { propertyDetails } = this.state;
    propertyDetails.verified = !propertyDetails.verified;
    console.log("====>>", propertyDetails);
    this._manageLoading("change-verified", true);
    updateProperty(propertyDetails.id, {
      verified: propertyDetails.verified,
    }).then(
      (response) => {
        console.log(response);
        this._manageLoading("change-verified", false);
        ToastsStore.success("Property updated successfully!", 3000);
      },
      (error) => {
        console.log(error);
        this._manageLoading("change-verified", false);
        ToastsStore.error(error.reason, 3000);
      }
    );
  };

  componentDidMount() {
    console.log(this.props.match.params.id);
    this._getPropertyDetails(this.props.match.params.id);
  }

  _backToList = () => {
    this.props.history.push("../properties");
  };

  _initPlayer = () => {
    // console.log(Player, Number(this.state.propertyDetails.videoUrls[0].split('/').pop()));
    if (this.state.propertyDetails.videoUrls.length) {
      var options = {
        id: Number(this.state.propertyDetails.videoUrls[0].split("/").pop()),
        width: 600,
        loop: true,
      };
      var player = new Player("made-in-ny", options);
      player.setVolume(0);
      // player.on('play', function() {
      //     console.log('played the video!');
      // });
    }
  };

  render() {
    let { propertyDetails, subRentees } = this.state;
    console.log(propertyDetails);
    return (
      <div className="animated fadeIn">
        <Row className="table-panel booking-panel edit-booking">
          <Col xs="12" sm="6">
            <div className="page-heading">
              <h2>View Place</h2>
            </div>
            {!this.state.loadingData && propertyDetails && (
              <Card>
                <CardHeader>
                  <strong>{propertyDetails.buildingName}</strong>
                  <div>
                    <Button color="secondary mr-3" onClick={this._backToList}>
                      <i className="fa fa-arrow-left"></i> &nbsp; Back to List
                    </Button>
                    <Button color="primary" onClick={this._gotoEditPlace}>
                      Edit
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Place</Label>
                        <p className="view-text">
                          {propertyDetails.buildingName}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Provider</Label>
                        {propertyDetails._rentee && (
                          <p className="view-text">
                            <Link
                              to={`../provider-details/${propertyDetails._rentee.id}`}
                            >
                              {propertyDetails._rentee.name.full}
                            </Link>
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Location</Label>
                        <p className="view-text">
                          {propertyDetails.city}, {propertyDetails.country}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="3">
                      <FormGroup>
                        <Label htmlFor="name">Bookings </Label>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <p className="view-text">
                        {propertyDetails._booking &&
                          propertyDetails._booking.length > 0 && (
                            <Link
                              to={`/dashboard/booking?property=${propertyDetails._id}`}
                            >
                              5
                            </Link>
                          )}
                        {(!propertyDetails._booking ||
                          propertyDetails._booking.length === 0) && (
                          <em className="no-bookings">No Bookings yet.</em>
                        )}
                      </p>
                    </Col>
                  </Row>
                  {propertyDetails._spaces.map((each) => (
                    <div key={each._id}>
                      {each.isActive && (
                        <Row>
                          <Col xs="3">
                            <FormGroup>
                              <Label htmlFor="name">
                                {each.spaceType + "(s)"}
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col xs="4">
                            {/* <p className="view-text">{each.noOfSeats}</p> */}
                            {each.spaceType === "Conference Room" && (
                              <p className="view-text">
                                {each.noOfSeats} room(s) of Capacity:{" "}
                                {each.conferenceRoomCapacity}
                              </p>
                            )}
                            {each.spaceType !== "Conference Room" && (
                              <p className="view-text">{each.noOfSeats}</p>
                            )}
                          </Col>
                          <Col xs="5">
                            <p className="view-text rate-details">
                              {each.rates.map((rate) => (
                                <span className="space-rate" key={rate._id}>
                                  {rate.period} $ {rate.amount}{" "}
                                </span>
                              ))}
                            </p>
                          </Col>
                        </Row>
                      )}
                    </div>
                  ))}

                  {/* <Row>
                  <Col xs="3">
                    <FormGroup>
                      <Label htmlFor="name">Fixed Desk</Label>
                    </FormGroup>
                  </Col>
                  <Col xs="1">
                    <p className="view-text">15</p>
                  </Col>
                  <Col xs="8">
                    <p className="view-text rate-details">
                      Hourly $5 | Daily $20 | Weekly $80 | Monthly $200
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs="3">
                    <FormGroup>
                      <Label htmlFor="name">Private Office</Label>
                    </FormGroup>
                  </Col>
                  <Col xs="1">
                    <p className="view-text">5</p>
                  </Col>
                  <Col xs="8">
                    <p className="view-text rate-details">
                      Hourly $5 | Daily $20 | Weekly $80 | Monthly $200
                    </p>
                  </Col>
                </Row>
                <Row> 
                  <Col xs="3">
                    <FormGroup>
                      <Label htmlFor="name">Conference Room</Label>
                    </FormGroup>
                  </Col>
                  <Col xs="1">
                    <p className="view-text">1</p>
                  </Col>
                  <Col xs="8">
                    <p className="view-text rate-details">
                      Hourly $5 | Daily $20 | Weekly $80 | Monthly $200
                    </p>
                  </Col>
                </Row>*/}
                  <Row>
                    <Col md="3">
                      <Label>Status</Label>
                    </Col>
                    <Col md="9" className="property-status">
                      <AppSwitch
                        className={"mx-1 details-status-switch"}
                        variant={"3d"}
                        outline={"alt"}
                        color={"primary"}
                        label
                        dataOn={"Active"}
                        dataOff={"Inactive"}
                        checked={propertyDetails.isActive}
                        onChange={this._onStatusChange}
                        disabled={this.state.changeStatusLoading}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Label>Verified</Label>
                    </Col>
                    <Col md="9" className="property-status">
                      <AppSwitch
                        className={"mx-1 details-status-switch"}
                        variant={"3d"}
                        outline={"alt"}
                        color={"primary"}
                        label
                        dataOn={"Yes"}
                        dataOff={"No"}
                        checked={propertyDetails.verified}
                        onChange={this._onVerificationChange}
                        disabled={this.state.changeVerifiedLoading}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Label>Amenities</Label>
                    </Col>
                    <Col md="9">
                      <p className="view-text rate-details">
                        {propertyDetails.facilities.map((each, index) => {
                          return (
                            <span className="amenity-title" key={index}>
                              {each}
                            </span>
                          );
                        })}
                      </p>
                      {!propertyDetails.facilities.length && (
                        <p className="view-text rate-details">
                          <em>Not Provided</em>
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Label>About</Label>
                    </Col>
                    <Col md="9">
                      <p className="view-text">{propertyDetails.description}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Label>Terms & Conditions</Label>
                    </Col>
                    <Col md="9">
                      <p className="view-text rate-details">
                        {propertyDetails.termsAndConditions && (
                          <a
                            rel="noopener noreferrer"
                            href={propertyDetails.termsAndConditions}
                            target="_blank"
                          >
                            View T&C
                          </a>
                        )}
                        {!propertyDetails.termsAndConditions && (
                          <em>Not Provided</em>
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Label>Working Days</Label>
                    </Col>
                    <Col md="9">
                      <p className="view-text rate-details">
                        {propertyDetails.holidays.workingDays.map((each) => {
                          return (
                            <span className="amenity-title" key={each}>
                              {each}
                            </span>
                          );
                        })}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Label>Holidays</Label>
                    </Col>
                    <Col md="9">
                      <p className="view-text rate-details holidays-name">
                        {propertyDetails.holidays.customDays
                          .concat(propertyDetails.holidays.recurringDays)
                          .map((each) => {
                            return (
                              <span className="amenity-title" key={each._id}>
                                {each.fullDate}{" "}
                                {each.label && `(${each.label})`}
                              </span>
                            );
                          })}
                      </p>
                      {!propertyDetails.holidays.customDays.concat(
                        propertyDetails.holidays.recurringDays
                      ).length && (
                        <p className="view-text rate-details">
                          <em>Not Provided</em>
                        </p>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Pictures</Label>
                      </FormGroup>
                    </Col>
                    <Col xs="12" className="property-images">
                      {propertyDetails.picUrls.map((each) => (
                        <p className="image-upload" key={each}>
                          <img src={each} alt="pic" />
                        </p>
                      ))}
                      {/* <p className="image-upload">                     
                      <img src="../../assets/img/spot-pic1.png" alt="pic" />
                    </p>  */}
                      {/*<p className="image-upload">                     
                      <img src="../../assets/img/spot-pic1.png" alt="pic" />
                    </p>
                    <p className="image-upload">                     
                      <img src="../../assets/img/spot-pic1.png" alt="pic" />
                    </p> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="4">
                      <FormGroup>
                        <Label htmlFor="name">Video</Label>
                      </FormGroup>
                    </Col>
                    <Col xs="12">
                      <div id="made-in-ny"></div>
                      {propertyDetails.videoUrls.map((url) => (
                        <video
                          width="320"
                          height="240"
                          controls
                          className="p-3"
                        >
                          <source src={url} type="video/mp4"></source>
                        </video>
                      ))}
                      {/* <p className="video-section"> */}
                      {/* <video width="320" controls className="p-3">
                        <source
                          src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                          type="video/mp4"
                        ></source>
                      </video> */}
                      {/* </p> */}
                      {/* {propertyDetails.videoUrls.map((url) => (
                        <iframe
                          title="test"
                          src="https://vimeo.com/701598143"
                          width="320"
                          height="240"
                          frameBorder="0"
                          allowFullScreen
                          allow="autoplay; encrypted-media"
                          controls="false"
                          autoPlay="true"
                        ></iframe>
                      ))} */}
                    </Col>
                  </Row>
                  <Row className="text-details">
                    <Col xs="12">
                      <p>Team Member list</p>
                    </Col>
                    <Col xs="12">
                      <Table
                        className="new-table"
                        responsive
                        bordered
                        style={{ fontWeight: 500 }}
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {subRentees.map((member) => (
                            <tr key={member._id}>
                              <td>{member.name.full}</td>
                              <td>{member.email}</td>
                              <td>
                                {member.countryCode} {member.phone}
                              </td>
                              <td style={{ fontSize: 14 }}>
                                {member.isActive ? "Active" : "Inactive"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
            {this.state.loadingData && (
              <Card>
                <CardBody>
                  <div className="loading-section no-border">
                    <i className="fa fa-spinner fa-spin"></i> &nbsp; Loading
                    Property Details..
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddPlace;
