import React, { Component } from "react";
import { format } from "date-fns";
import {
  Card,
  CardBody,
  Col,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Row,
  // Table,
  // FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Button,
} from "reactstrap";
import {
  getAllProperties,
  getAllBookings,
  cancelBooking,
} from "../../../http/http-calls";
import CustomTable from "../../../components/CustomTable";
import config from "../../../config/index";
import { Link } from "react-router-dom";
import { ToastsStore } from "react-toasts";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class Booking extends Component {
  state = {
    preferenceList: config.preferenceList,
    cycleTypeList: config.cycleTypeList,
    bookingStatus: config.bookingStatus,
    propertyList: [],
    bookingList: [],
    bookingListBackup: [],
    headerKeys: [
      { id: "name", label: "Booking ID" },
      { id: "placeName", label: "Place" },
      { id: "renterName", label: "Renter" },
      { id: "providerName", label: "Provider" },
      { id: "startDate", label: "Start Date" },
      { id: "rentType", label: "Cycle" },
      { id: "booking", label: "Booking" },
      { id: "status", label: "Status" },
      { id: "action", label: "Action" },
    ],
    loadingData: false,
    changeStatusLoading: false,
    filters: {
      place: "",
      status: "",
      seatType: "",
      cycleType: "",
      startDate: "",
      search: "",
    },
  };

  _parseFilters = () => {
    //   Check if search string is present
    if (this.props.location.search && this.props.location.search.length) {
      const queryParamsString = this.props.location.search;
      // Check if id key is available
      let { filters } = this.state;
      console.log(queryParamsString);
      if (queryParamsString.includes("?renter=")) {
        filters.renter = queryParamsString.split("?renter=")[1];
      }
      if (queryParamsString.includes("?provider=")) {
        filters.provider = queryParamsString.split("?provider=")[1];
      }
      if (queryParamsString.includes("?property=")) {
        filters.property = queryParamsString.split("?property=")[1];
      }
      this.setState({ filters });
    }
  };

  _getAllBookings = () => {
    // this._manageLoading("data", true);
    getAllBookings().then(
      (response) => {
        console.log(response);
        let bookingList = response.bookings.map((each) => {
          return {
            name: each._id,
            placeName: each._space._property
              ? each._space._property.buildingName
              : "",
            placeId: each._space._property ? each._space._property._id : "",
            renterName: each.isOffline
              ? each.renterName
              : each._renter
              ? each._renter.name.full
              : "",
            renterId: each._renter ? each._renter._id : "",
            providerName: each._rentee ? each._rentee.name.full : "",
            providerId: each._rentee ? each._rentee._id : "",
            startDate: each.startDate,
            rentType: each.rentType,
            booking: each._space.spaceType + " x " + each.noOfSeats,
            status: each.status,
            action: "",
            shortBookingId: each.shortBookingId,
          };
        });
        this.setState({ bookingList, bookingListBackup: bookingList });
        this._manageLoading("data", false);
        this._filterbookingList();
      },
      (error) => {
        console.log(error);
        this._manageLoading("data", false);
      }
    );
  };

  componentDidMount() {
    this._parseFilters();
    this._getAllProperties();
  }

  // componentWillMount() {
  //   setTimeout(()=>{
  //     this.props.history.replace('/dashboard/booking');
  //   },10000)
  // }

  _manageLoading = (key, value) => {
    let { loadingData, changeStatusLoading } = this.state;
    if (key === "data") {
      loadingData = value;
    } else if (key === "change-status") {
      changeStatusLoading = value;
    }
    this.setState({ loadingData, changeStatusLoading });
  };

  _formatDate(date) {
    return format(new Date(date), "MMM d, yyyy");
  }

  _getAllProperties() {
    this._manageLoading("data", true);
    getAllProperties().then(
      (response) => {
        console.log(response);
        let propertyList = response.Properties.map((each) => {
          return {
            id: each._id,
            name: each.buildingName,
          };
        });
        this.setState({ propertyList });
        this._getAllBookings();
        // this._manageLoading("data", false);
      },
      (error) => {
        console.log(error);
        this._manageLoading("data", false);
      }
    );
  }

  _gotoBookingDetails = (bookingId) => {
    this.props.history.push("booking-details/" + bookingId);
  };

  _dataFormat = (cell, row, header) => {
    // console.log(cell, row, header);
    if (header === "name") {
      return row.shortBookingId.toUpperCase();
    } else if (header === "placeName") {
      return <Link to={`./place-details/${row.placeId}`}>{cell}</Link>;
    } else if (header === "renterName") {
      return <Link to={`./renter-details/${row.renterId}`}>{cell}</Link>;
    } else if (header === "providerName") {
      return <Link to={`./provider-details/${row.providerId}`}>{cell}</Link>;
    } else if (header === "rentType") {
      return <span className="capitalize">{cell}</span>;
    } else if (header === "startDate") {
      return <span>{this._formatDate(cell)}</span>;
    } else if (header === "action") {
      return (
        <React.Fragment>
          <span>
            <i
              className="fa fa-eye cursor-pointer"
              aria-hidden="true"
              onClick={() => this._gotoBookingDetails(row.name)}
            ></i>
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {(row.status === "PendingPayment" || row.status === "Confirmed") && (
            <Button
              type="button"
              color="danger"
              disabled={this.state.changeStatusLoading}
              onClick={() => this._cancelBooking(row)}
            >
              Cancel
            </Button>
          )}
        </React.Fragment>
      );
    } else {
      return cell;
    }
  };

  _filterOnChange = ({ currentTarget }) => {
    let { filters } = this.state;
    filters[currentTarget.name] = currentTarget.value;
    this.setState({ filters });
    console.log(filters);
    this._filterbookingList();
  };

  _handleDateChange = (date) => {
    const { filters } = this.state;
    filters.startDate = date;
    this.setState({ filters });
    console.log(filters);
    this._filterbookingList();
  };

  _filterbookingList = () => {
    let filterConditions = [];
    // console.log(this.state);
    let { filters, bookingListBackup, bookingList } = this.state;
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filterConditions.push(key);
      }
    });
    console.log(filterConditions);
    bookingList = bookingListBackup;
    if (filterConditions.length) {
      if (filters.place) {
        bookingList = bookingList.filter((each) => {
          return each.placeName === filters.place;
        });
      }
      if (filters.status) {
        bookingList = bookingList.filter((each) => {
          return each.status === filters.status;
        });
      }
      if (filters.seatType) {
        bookingList = bookingList.filter((each) => {
          return each.booking
            .toLowerCase()
            .includes(filters.seatType.toLowerCase());
        });
      }
      if (filters.cycleType) {
        bookingList = bookingList.filter((each) => {
          return each.rentType === filters.cycleType.toLocaleLowerCase();
        });
      }
      if (filters.startDate) {
        bookingList = bookingList.filter((each) => {
          return (
            new Date(each.startDate).toLocaleDateString() ===
            filters.startDate.toLocaleDateString()
          );
        });
      }
      if (filters.search.trim().length) {
        bookingList = bookingList.filter((each) => {
          return (
            each.placeName
              .toLowerCase()
              .includes(filters.search.toLowerCase()) ||
            each.renterName
              .toLowerCase()
              .includes(filters.search.toLowerCase()) ||
            each.providerName
              .toLowerCase()
              .includes(filters.search.toLowerCase()) ||
            each.booking.toLowerCase().includes(filters.search.toLowerCase()) ||
            each.shortBookingId
              .toLowerCase()
              .includes(filters.search.toLowerCase())
          );
        });
      }
      if (filters.renter) {
        bookingList = bookingList.filter((each) => {
          return each.renterId === filters.renter;
        });
      }
      if (filters.provider) {
        bookingList = bookingList.filter((each) => {
          return each.providerId === filters.provider;
        });
      }
      if (filters.property) {
        bookingList = bookingList.filter((each) => {
          return each.placeId === filters.property;
        });
      }
      this.setState({ bookingList });
    } else {
      this.setState({ bookingList: bookingListBackup });
    }
  };

  _clearFilter = () => {
    let { filters } = this.state;
    filters = {
      place: "",
      status: "",
      seatType: "",
      cycleType: "",
      startDate: "",
      search: "",
    };
    this.setState({ filters }, () => {
      this._filterbookingList();
    });
  };

  _cancelBooking = (row) => {
    this._manageLoading("change-status", true);
    cancelBooking(row.name).then(
      (response) => {
        row.status = "Cancelled By Admin";
        this._manageLoading("change-status", false);
        console.log(response);
        ToastsStore.success("Booking Cancelled", 3000);
      },
      (error) => {
        console.log(error);
        this._manageLoading("change-status", false);
        ToastsStore.error(error.reason, 3000);
      }
    );
  };

  render() {
    return (
      <div className="animated fadeIn">
        <div className="page-heading">
          <h2>Bookings</h2>
        </div>
        <Row className="table-panel">
          <Col xs="12" lg="12">
            <Card>
              {!this.state.loadingData && (
                <CardBody>
                  <div className="table-top">
                    <Form onSubmit={this.handleOnSubmit} noValidate>
                      <InputGroup className="mb-3">
                        <Input
                          type="text"
                          name="search"
                          placeholder="Search"
                          autoComplete="off"
                          value={this.state.filters.search}
                          onChange={this._filterOnChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4 booking-start-date-filter">
                        <DatePicker
                          className="form-control"
                          autoComplete="off"
                          placeholderText="Select Date"
                          selected={this.state.filters.startDate}
                          name="startDate"
                          onSelect={this._handleDateChange}
                          dateFormat="MMM d, yyyy"
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fa fa-calendar"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <select
                          name="place"
                          id="preference"
                          className="form-control"
                          value={this.state.filters.place}
                          onChange={this._filterOnChange}
                        >
                          <option value="">Select Place</option>
                          {this.state.propertyList.map((each) => (
                            <option key={each.id} value={each.name}>
                              {each.name}
                            </option>
                          ))}
                        </select>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <select
                          name="status"
                          id="status"
                          className="form-control"
                          value={this.state.filters.status}
                          onChange={this._filterOnChange}
                        >
                          <option value="">Select Status</option>
                          {this.state.bookingStatus.map((each) => (
                            <option key={each} value={each}>
                              {each}
                            </option>
                          ))}
                        </select>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <select
                          name="seatType"
                          id="seatType"
                          className="form-control"
                          value={this.state.filters.seatType}
                          onChange={this._filterOnChange}
                        >
                          <option value="">Select Seat Type</option>
                          {this.state.preferenceList.map((each) => (
                            <option key={each} value={each}>
                              {each}
                            </option>
                          ))}
                        </select>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <select
                          name="cycleType"
                          id="cycleType"
                          className="form-control"
                          value={this.state.filters.cycleType}
                          onChange={this._filterOnChange}
                        >
                          <option value="">Select Cycle Type</option>
                          {this.state.cycleTypeList.map((each) => (
                            <option key={each} value={each}>
                              {each}
                            </option>
                          ))}
                        </select>
                      </InputGroup>
                      <InputGroup
                        className="mb-3 filter-icon"
                        onClick={this._clearFilter}
                      >
                        {/* <i className="fa fa-filter" aria-hidden="true"></i> */}
                        <i className="fa fa-eraser"></i>
                      </InputGroup>
                      &nbsp; &nbsp;
                      <ExcelFile
                        element={
                          <Button type="button" color="primary">
                            Export Data
                          </Button>
                        }
                        filename="Bookings"
                      >
                        <ExcelSheet
                          data={this.state.bookingList}
                          name="Bookings"
                        >
                          <ExcelColumn label="Booking ID" value="name" />
                          <ExcelColumn label="Place" value="placeName" />
                          <ExcelColumn label="Renter" value="renterName" />
                          <ExcelColumn label="Provider" value="providerName" />
                          <ExcelColumn
                            label="Start Date"
                            value={(col) => this._formatDate(col.startDate)}
                          />
                          <ExcelColumn label="Cycle" value="rentType" />
                          <ExcelColumn label="Booking" value="booking" />
                          <ExcelColumn label="Status" value="status" />
                        </ExcelSheet>
                      </ExcelFile>
                    </Form>
                  </div>
                  <CustomTable
                    tableData={this.state.bookingList}
                    headerKeys={this.state.headerKeys}
                    dataFormat={this._dataFormat}
                  ></CustomTable>
                </CardBody>
              )}
              {this.state.loadingData && (
                <CardBody>
                  <div className="loading-section list-loading">
                    <i className="fa fa-spinner fa-spin"></i> &nbsp; Loading
                    Bookings..
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Booking;
