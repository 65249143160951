import React, { Component } from "react";
import { Card, CardBody, Col, Row, FormGroup, Button } from "reactstrap";
import {getAllSettings, updateAllSettings} from '../../../http/http-calls';
import TextEditor from '../../../components/TextEditor';
import { ToastsStore } from "react-toasts";
import config from "../../../config";
class StaticContent extends Component {
  state = {
      languageList: config.languageConfig,
      selectedLanguage:'en',
      settings:{
        renterRefundPolicy: {},
        renteeRefundPolicy: {},
        privacyPolicy: {},
        termsAndConditions: {},
        renterFAQ: {},
        renteeFAQ: {}
      },
      isLoading: false      
  };

  _setContent(value,key) {
    let {settings,selectedLanguage} = this.state;
    settings[key][selectedLanguage] = value;
    this.setState({settings});
  }

  _onLanguageChange=({currentTarget})=>{
    let {selectedLanguage,settings} = this.state;
    selectedLanguage = currentTarget.value;
    this.setState({selectedLanguage,settings});
  }

  _saveContent = () => {
    console.log(this.state.settings);
    this._updateAllSettings();
  };

  _getAllContents(){
    getAllSettings()
    .then((response)=>{
      console.log(response);
      let settings = {
        renterRefundPolicy: response.settings.renterRefundPolicy,
        renteeRefundPolicy: response.settings.renteeRefundPolicy,
        privacyPolicy: response.settings.privacyPolicy,
        termsAndConditions: response.settings.termsAndConditions,
        renterFAQ: response.settings.renterFAQ,
        renteeFAQ: response.settings.renteeFAQ
      }
      this.setState({settings});
    },(error)=>{
      console.log(error);
    })
  }

  _updateAllSettings() {
    let {isLoading, settings} = this.state;
    console.log(settings);
    isLoading = true;
    this.setState({isLoading});
    updateAllSettings(this.state.settings)
    .then((response)=>{
      console.log(response);
      isLoading = false;
      this.setState({isLoading});
      ToastsStore.success("Static Content updated Successfully!", 3000);
    },(error)=>{
      console.log(error);
      isLoading = false;
      this.setState({isLoading});
    })
  }

  componentDidMount() {
    this._getAllContents();
  }

  render() {
    const { languageList, selectedLanguage, settings, isLoading } = this.state;
    console.log(settings.renterRefundPolicy);
    return (
      <div className="animated fadeIn">
        <div className="page-heading">
          <h2>Static Content</h2>
        </div>
        <Row>
        <Col xs="12" className="text-center">
          <label>Select Language</label>
            <select
              id="language"
              name="language"
              className="form-control language-select" 
              value={selectedLanguage}   
              onChange={this._onLanguageChange}          
            >              
              {languageList.map(item => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <Row className="static-content">          
          <Col xs="12" sm="6">
            <Card>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <label>Renter Refund Policy</label>
                    {/* <span>{settings.renterRefundPolicy}</span> */}
                    <TextEditor content={settings.renterRefundPolicy[selectedLanguage]} onChange={(e)=>this._setContent(e,'renterRefundPolicy')}></TextEditor>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <label>Provider Refund Policy</label>
                    <TextEditor content={settings.renteeRefundPolicy[selectedLanguage]} onChange={(e)=>this._setContent(e,'renteeRefundPolicy')}></TextEditor>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <label>Privacy Policy</label>
                    <TextEditor content={settings.privacyPolicy[selectedLanguage]} onChange={(e)=>this._setContent(e,'privacyPolicy')}></TextEditor>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <label>Terms & Conditions</label>
                    <TextEditor content={settings.termsAndConditions[selectedLanguage]} onChange={(e)=>this._setContent(e,'termsAndConditions')}></TextEditor>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <label>Renter FAQs</label>
                    <TextEditor content={settings.renterFAQ[selectedLanguage]} onChange={(e)=>this._setContent(e,'renterFAQ')}></TextEditor>                    
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <label>Provider FAQs</label>
                    <TextEditor content={settings.renteeFAQ[selectedLanguage]} onChange={(e)=>this._setContent(e,'renteeFAQ')}></TextEditor>                    
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className="button-panel">
                    <FormGroup>
                      <Button
                        color="primary"
                        className=""
                        onClick={this._saveContent}
                        disabled={isLoading}
                      >
                         { isLoading && <i className="fa fa-spinner fa-spin mr5"/>}
                        Update
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default StaticContent;
