import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Form,
  FormGroup,
  // FormText,
  // FormFeedback,
  Input,
  // Img,
  Button,
  InputGroupAddon,
  // InputGroupButtonDropdown,
  InputGroupText,
  Label,
  InputGroup,
} from "reactstrap";
import { AppSwitch } from "@coreui/react";
import {
  getUserDetails,
  changeUserStatus,
  updateUser,
  googlePlaceSearch,
} from "../../../http/http-calls";
import { uploadFileMultiPart } from "../../../http/http-service";

import { ToastsStore } from "react-toasts";
import config from "../../../config/index";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
class EditRenterDetails extends Component {
  state = {
    renterName: "",
    renterDetails: {},
    loadingData: false,
    changeStatusLoading: false,
    updateLoading: false,
    pictureLoading: false,
    photoIdProofLoading: false,
    preferenceList: config.preferenceList,
    languageList: config.languageList,
    countryCodeList: config.countryCodeList,
    roles: config.roles,
    errors: {},
    isDirty: {
      fullName: false,
      email: false,
      phone: false,
      pictureUrl: false,
      spacePreference: false,
      language: false,
      companyName: false,
      designation: false,
      dateOfBirth: false,
      isActive: false,
      address: false,
      photoIdProofUrl: false,
    },
    showSuggestionList: false,
    locationSuggestionList: [],
  };

  /**
   * To get the renter details by renter ID
   * @memberof EditRenterDetails
   */
  _getRenterDetails = (id) => {
    this._manageLoading("data", true);
    getUserDetails(id, { type: "Renter" }).then(
      (response) => {
        console.log(response);
        this.setState({ renterName: response.user.name.full });
        let renterDetails = {
          fullName: response.user.name.full,
          email: response.user.email,
          countryCode: response.user.countryCode,
          phone: response.user.phone,
          pictureUrl: response.user.pictureUrl,
          spacePreference: response.user.spacePreference,
          language: response.user.languagePreference,
          companyName: response.user.companyName,
          designation: response.user.designation,
          dateOfBirth: response.user.dateOfBirth
            ? new Date(response.user.dateOfBirth)
            : "",
          isActive: response.user.isActive,
          _id: response.user._id,
          address: response.user.address
            ? response.user.address.city + ", " + response.user.address.country
            : "",
          photoIdProofUrl: response.user.photoIdProofUrl,
        };
        this.setState({ renterDetails });
        this._manageLoading("data", false);
      },
      (error) => {
        console.log(error);
        this._manageLoading("data", false);
        ToastsStore.error(error.reason, 3000);
      }
    );
  };

  /**
   * Manage the loading to show and hide
   * @memberof EditRenterDetails
   */
  _manageLoading = (key, value) => {
    let {
      loadingData,
      changeStatusLoading,
      updateLoading,
      pictureLoading,
      photoIdProofLoading,
    } = this.state;
    if (key === "data") {
      loadingData = value;
    } else if (key === "change-status") {
      changeStatusLoading = value;
    } else if (key === "update") {
      updateLoading = value;
    } else if (key === "pictureUrl") {
      pictureLoading = value;
    } else if (key === "photoIdProofUrl") {
      photoIdProofLoading = value;
    }
    this.setState({
      loadingData,
      changeStatusLoading,
      updateLoading,
      pictureLoading,
      photoIdProofLoading,
    });
  };

  /**
   * On status change of the renter
   * @memberof EditRenterDetails
   */
  _onStatusChange = () => {
    let { renterDetails } = this.state;
    renterDetails.isActive = !renterDetails.isActive;
    this._manageLoading("change-status", true);
    changeUserStatus(renterDetails._id, {
      status: renterDetails.isActive,
    }).then(
      (response) => {
        console.log(response);
        this.setState({ renterDetails });
        this._manageLoading("change-status", false);
        ToastsStore.success("Status updated successfully!", 3000);
      },
      (error) => {
        console.log(error);
        this._manageLoading("change-status", false);
        ToastsStore.error(error.reason, 3000);
      }
    );
  };

  componentDidMount() {
    console.log(this.props.match.params.id);
    this._getRenterDetails(this.props.match.params.id);
  }

  /**
   * To handle the on change event
   * @memberof EditRenterDetails
   */
  _handleOnChange = ({ currentTarget }) => {
    const { renterDetails, isDirty } = this.state;
    renterDetails[currentTarget.name] = currentTarget.value;
    isDirty[currentTarget.name] = true;
    this.setState({ renterDetails });
    this._validateForm();
  };

  /**
   * To handle the date change event on datepicker
   * @memberof EditRenterDetails
   */
  _handleDateChange = (date) => {
    const { renterDetails, isDirty } = this.state;
    renterDetails.dateOfBirth = date;
    isDirty.dateOfBirth = true;
    this.setState({ renterDetails });
    this._validateForm();
  };

  /**
   * To handle the submit of the edit form and validate it
   * @memberof EditRenterDetails
   */
  _handleOnSubmit = (event) => {
    event.preventDefault();
    let { isDirty } = this.state;
    isDirty = {
      fullName: false,
      email: false,
      phone: false,
      pictureUrl: false,
      spacePreference: false,
      language: false,
      companyName: false,
      designation: false,
      dateOfBirth: false,
      isActive: false,
      address: false,
    };
    this.setState({ isDirty });
    console.log(this.state.isDirty);
    let errors = this._validateForm();
    console.log(errors);
    // this.setState({errors});
    if (!errors) {
      let data = Object.assign({}, this.state.renterDetails);
      data.name = {
        first: data.fullName.split(" ")[0],
        last: data.fullName.split(" ")[1],
      };
      data.dateOfBirth = data.dateOfBirth.toISOString();
      data.address = {
        city: data.address.split(", ")[0],
        country: data.address.split(", ")[1],
      };
      delete data.fullName;
      console.log("Make API call", data);
      this._updateUser(data._id, data);
    }
  };

  /**
   * To validate the form
   * @returns
   * @memberof EditRenterDetails
   */
  _validateForm() {
    const { renterDetails, errors, isDirty } = this.state;
    // console.log(renterDetails, isDirty);
    Object.keys(renterDetails).forEach((each) => {
      if (each === "fullName" && isDirty.fullName) {
        if (!renterDetails.fullName.trim().length) {
          errors.fullName = "Name is Required";
        } else {
          delete errors[each];
          isDirty.fullName = false;
        }
      } else if (each === "email" && isDirty.email) {
        if (!renterDetails.email.trim().length) {
          errors[each] = "Email is Required";
        } else if (
          renterDetails.email.trim().length &&
          !new RegExp(
            "^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$"
          ).test(renterDetails.email)
        ) {
          errors.email = "Please enter valid Email";
        } else {
          delete errors[each];
          isDirty.email = false;
        }
      } else if (each === "phone" && isDirty.phone) {
        if (!renterDetails.phone.trim().length) {
          errors.phone = "Phone number is Required";
        }
        //  else if(renterDetails.phone.trim().length !== 10){
        //   errors.phone = "Phone number must be of 10 digits";
        // }
        else {
          delete errors[each];
          isDirty.phone = false;
        }
      } else if (each === "dateOfBirth" && isDirty.dateOfBirth) {
        if (
          typeof renterDetails.dateOfBirth !== "object" ||
          !renterDetails.dateOfBirth
        ) {
          errors.dateOfBirth = "Date of birth is Required";
        } else if (renterDetails.dateOfBirth > new Date()) {
          errors.dateOfBirth = "Date of birth must be before today";
        } else {
          delete errors[each];
          isDirty.dateOfBirth = false;
        }
      } else if (each === "companyName" && isDirty.companyName) {
        if (!renterDetails.companyName.trim().length) {
          errors.companyName = "Company Name is Required";
        } else {
          delete errors[each];
          isDirty.companyName = false;
        }
      } else if (each === "designation" && isDirty.designation) {
        if (!renterDetails.designation.trim().length) {
          errors.designation = "Designation is Required";
        } else {
          delete errors[each];
          isDirty.designation = false;
        }
      } else if (each === "address" && isDirty.address) {
        if (!renterDetails.address.trim().length) {
          errors.address = "Location is Required";
        } else {
          delete errors[each];
          isDirty.address = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  /**
   * Make the API call to update the renter details
   * @memberof EditRenterDetails
   */
  _updateUser = (id, data) => {
    this._manageLoading("update", true);
    updateUser(id, data).then(
      (response) => {
        console.log(response);
        this._manageLoading("update", false);
        ToastsStore.success("Renter details updated successfully!", 3000);
        this.props.history.push(
          "../renter-details/" + this.state.renterDetails._id
        );
      },
      (error) => {
        console.log(error);
        ToastsStore.error(error.reason || "Something Went Wrond!", 3000);
        this._manageLoading("update", false);
      }
    );
    // this.props.history.push("renter-details");
  };

  /**
   * To search the place for address. Calling the Geo location service for the suggestions
   * @memberof EditRenterDetails
   */
  _searchPlace = ({ currentTarget }) => {
    const { renterDetails, isDirty } = this.state;
    console.log(currentTarget.value);
    renterDetails.address = currentTarget.value;
    isDirty.address = true;
    this.setState({ renterDetails });
    this._validateForm();
    googlePlaceSearch(renterDetails.address).then((response) => {
      console.log(response);
      this.setState({
        locationSuggestionList: response,
        showSuggestionList: true,
      });
    });
  };

  /**
   * Select a location form the suggestion list
   * @memberof EditRenterDetails
   */
  _selectLocation = (each) => {
    console.log(each);
    let { renterDetails, showSuggestionList, isDirty } = this.state;
    renterDetails.address = each;
    showSuggestionList = false;
    isDirty.address = true;
    this.setState({ renterDetails, showSuggestionList });
    this._validateForm();
  };

  /**
   * To open the file browser to select file
   * @memberof EditRenterDetails
   */
  _openFileBrowser = () => {
    this.refs.fileUploader.click();
  };

  /**
   * To handle the image change
   * @memberof EditRenterDetails
   */
  _imageChange = (event, key) => {
    // console.log(event.target.files[0]);
    const file = event.target.files[0];
    if (file) {
      this._uploadImage(event.target.files[0], key);
    } else {
      const { renterDetails } = this.state;
      renterDetails[key] = "";
      this.setState({ renterDetails });
    }
  };

  /**
   *
   * To upload the image to cloudinary
   * @memberof EditRenterDetails
   */
  _uploadImage = (file, key) => {
    var fd = new FormData();
    fd.append("file", file);
    this._manageLoading(key, true);
    uploadFileMultiPart(false, fd).then(
      (response) => {
        console.log(response.secure_url);
        const { renterDetails } = this.state;
        renterDetails[key] = response.secure_url;
        this.setState({ renterDetails });
        this._manageLoading(key, false);
        // this._validateForm();
      },
      (error) => {
        console.log(error);
        this._manageLoading(key, false);
        ToastsStore.error(error.reason || "Something Went Wrong!", 3000);
      }
    );
  };

  _backToDetails = () => {
    this.props.history.push(
      "../renter-details/" + this.state.renterDetails._id
    );
  };

  render() {
    let { renterDetails } = this.state;
    return (
      <div className="animated fadeIn">
        <div className="page-heading">
          <h2>Edit Renter</h2>
        </div>
        <Row className="table-panel booking-panel">
          <Col xs="12" sm="6">
            {!this.state.loadingData && renterDetails && (
              <Card>
                <CardHeader>
                  <strong>{this.state.renterName}</strong>
                  <Button color="secondary" onClick={this._backToDetails}>
                    <i className="fa fa-arrow-left"></i> &nbsp; Back
                  </Button>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this._handleOnSubmit} noValidate>
                    <Row>
                      <Col xs="6">
                        <img
                          src={
                            renterDetails.pictureUrl ||
                            "../../assets/img/avatars/user.png"
                          }
                          alt="renter"
                          width="70"
                          className={
                            this.state.pictureLoading
                              ? "disbale-file-select user-profile-image"
                              : "img-avatar user-profile-image"
                          }
                        />
                        {this.state.pictureLoading && (
                          <i className="fa fa-spinner fa-spin uploading-image-loading"></i>
                        )}
                        {!this.state.pictureLoading && (
                          <span
                            onClick={this._openFileBrowser}
                            className="update-profile-image"
                          >
                            <i className="fa fa-pencil"></i>
                            <input
                              type="file"
                              id="file"
                              ref="fileUploader"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) =>
                                this._imageChange(e, "pictureUrl")
                              }
                            />
                          </span>
                        )}
                      </Col>
                      <Col xs="6" className="app-switch-panel">
                        <span>Status : </span>
                        <AppSwitch
                          className={"mx-1 details-status-switch"}
                          variant={"3d"}
                          outline={"alt"}
                          color={"primary"}
                          label
                          dataOn={"Active"}
                          dataOff={"Inactive"}
                          checked={renterDetails.isActive}
                          onChange={this._onStatusChange}
                          disabled={this.state.changeStatusLoading}
                        />
                        {/* <span></span>Active */}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="name">Name</Label>
                          <Input
                            type="text"
                            id="name"
                            name="fullName"
                            placeholder="Enter your name"
                            value={renterDetails.fullName}
                            onChange={this._handleOnChange}
                            className={
                              this.state.errors && this.state.errors.fullName
                                ? "invalid-input"
                                : "valid-input"
                            }
                          />
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.fullName}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="email">Email</Label>
                          <Input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Enter your Email"
                            value={renterDetails.email}
                            onChange={this._handleOnChange}
                            autoComplete="off"
                            className={
                              this.state.errors && this.state.errors.email
                                ? "invalid-input"
                                : "valid-input"
                            }
                          />
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.email}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3">
                        <FormGroup>
                          <Label htmlFor="countryCode">Country Code</Label>
                          <FormGroup>
                            <Input
                              type="select"
                              name="countryCode"
                              id="countryCode"
                              value={renterDetails.countryCode}
                              onChange={this._handleOnChange}
                            >
                              {this.state.countryCodeList.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                      <Col xs="3">
                        <FormGroup>
                          <Label htmlFor="phone">Phone</Label>
                          <Input
                            type="number"
                            id="phone"
                            name="phone"
                            placeholder="Enter your Phone Number"
                            value={renterDetails.phone}
                            onChange={this._handleOnChange}
                            className={
                              this.state.errors && this.state.errors.phone
                                ? "invalid-input"
                                : "valid-input"
                            }
                          />
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.phone}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="dateOfBirth">Date of Birth</Label>
                          <InputGroup
                            className={
                              this.state.errors && this.state.errors.dateOfBirth
                                ? "invalid-input"
                                : "valid-input"
                            }
                          >
                            <DatePicker
                              className="form-control"
                              selected={renterDetails.dateOfBirth}
                              name="dateOfBirth"
                              onSelect={this._handleDateChange}
                              dateFormat="MMM d, yyyy"
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <i className="fa fa-calendar"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.dateOfBirth}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="companyName">Company</Label>
                          <Input
                            type="text"
                            id="companyName"
                            placeholder="Enter your Company"
                            required
                            name="companyName"
                            value={renterDetails.companyName}
                            onChange={this._handleOnChange}
                            className={
                              this.state.errors && this.state.errors.companyName
                                ? "invalid-input"
                                : "valid-input"
                            }
                          />
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.companyName}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="designation">Role</Label>
                          {/* <Input
                        type="text"
                        id="designation"
                        placeholder="Enter your Role"
                        name="designation"
                        required
                        value={renterDetails.designation}
                        onChange={this._handleOnChange}
                        className={this.state.errors && this.state.errors.designation ? 'invalid-input' : 'valid-input'}
                      /> */}
                          <Input
                            type="select"
                            name="designation"
                            id="designation"
                            value={renterDetails.designation}
                            onChange={this._handleOnChange}
                          >
                            {this.state.roles.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </Input>
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.designation}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="name">Location</Label>
                          <InputGroup
                            className={
                              this.state.errors && this.state.errors.address
                                ? "invalid-input"
                                : "valid-input"
                            }
                          >
                            <Input
                              type="text"
                              id="input1-group1"
                              name="input1-group1"
                              placeholder="Enter your Location"
                              autoComplete="off"
                              value={renterDetails.address}
                              onChange={this._searchPlace}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>
                                <i className="fa fa-map-marker"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.address}
                            </div>
                          )}
                          {this.state.locationSuggestionList.length !== 0 &&
                            this.state.showSuggestionList && (
                              <ul className="location-suggestion-ul">
                                {this.state.locationSuggestionList.map(
                                  (each) => (
                                    <li
                                      key={each.location}
                                      onClick={() =>
                                        this._selectLocation(each.location)
                                      }
                                    >
                                      <i className="fa fa-map-marker"></i>&nbsp;
                                      {each.location}
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="spacePreference">Preferences</Label>
                          <FormGroup>
                            <Input
                              type="select"
                              name="spacePreference"
                              id="spacePreference"
                              value={renterDetails.spacePreference}
                              onChange={this._handleOnChange}
                            >
                              {this.state.preferenceList.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="language">Languages</Label>
                          <FormGroup>
                            <Input
                              type="select"
                              name="language"
                              id="language"
                              value={renterDetails.language}
                              onChange={this._handleOnChange}
                            >
                              {this.state.languageList.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="language">Photo ID Proof</Label>
                          <FormGroup>
                            <Input
                              type="file"
                              name="photoIdProofUrl"
                              id="photoIdProofUrl"
                              accept="image/*"
                              disabled={this.state.photoIdProofLoading}
                              onChange={(e) =>
                                this._imageChange(e, "photoIdProofUrl")
                              }
                            ></Input>
                          </FormGroup>
                          {this.state.photoIdProofLoading && (
                            <i className="fa fa-spinner fa-spin uploading-id-loading"></i>
                          )}
                        </FormGroup>
                        {renterDetails.photoIdProofUrl &&
                          !this.state.photoIdProofLoading && (
                            <a
                              href={renterDetails.photoIdProofUrl}
                              target="_blank"
                              download
                              rel="noopener noreferrer"
                            >
                              Photo ID Proof
                            </a>
                          )}
                      </Col>
                    </Row>
                    <Row className="submit-panel">
                      <Col xs="12">
                        <FormGroup>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={this.state.updateLoading}
                          >
                            {this.state.updateLoading && (
                              <i className="fa fa-spinner fa-spin mr5"></i>
                            )}
                            Save
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            )}
            {this.state.loadingData && (
              <Card>
                <CardBody>
                  <div className="loading-section no-border">
                    <i className="fa fa-spinner fa-spin"></i> &nbsp; Loading
                    Renter Details..
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default EditRenterDetails;
