import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Input,
  Button,
  InputGroup
} from "reactstrap";
import { getAllSettings, updateAllSettings } from "../../../http/http-calls";
import { ToastsStore } from "react-toasts";

class BasicSettings extends Component {
  state = {
    commissionRatio: {
      vendor: "",
      platform: "",      
    },
    referredAmount: '',
    errors: {},
    isDirty: {
      vendor: false,
      platform: false,
      referredAmount: false
    },
    savingPercentages: false
  };
  _handleOnChange = ({ currentTarget },type) => {
    // console.log(currentTarget);
    let { commissionRatio, isDirty, referredAmount } = this.state;
    if(type === "referredAmount"){
      referredAmount = currentTarget.value;
      isDirty.referredAmount = true;
    }else{
      commissionRatio[currentTarget.name] = currentTarget.value;
      isDirty[currentTarget.name] = true;
    }       
    console.log(commissionRatio,referredAmount);
    this.setState({ commissionRatio,referredAmount });
    this._validateForm();
  };

  _handleOnSubmit = event => {
    event.preventDefault();
    const { isDirty } = this.state;
    isDirty.vendor = true;
    isDirty.platform = true;
    isDirty.referredAmount = true;
    this.setState({ isDirty });
    let errors = this._validateForm();
    if (!errors) {
      console.log("Make API call", this.state);
      this._updateAllSettings();
    }
  };

  _validateForm() {
    let { commissionRatio, errors, isDirty, referredAmount } = this.state;
    errors = {};
    console.log('gjhgjgh',commissionRatio, referredAmount, isDirty);
    Object.keys(commissionRatio).forEach(each => {
      if (each === "vendor" && isDirty.vendor) {
        if (!commissionRatio.vendor.trim().length) {
          errors.vendor = "vendor Percentage is Required";
        } else if (
          commissionRatio.vendor.trim() <= 0 ||
          commissionRatio.vendor.trim() >= 100
        ) {
          errors.vendor = "vendor Percentage should be within 1 to 100";
        } else {
          delete errors[each];
          isDirty.vendor = false;
        }
      } else if (each === "platform" && isDirty.platform) {
        if (!commissionRatio.platform.trim().length) {
          errors[each] = "Platform Percentage is Required";
        } else if (
          commissionRatio.platform.trim() <= 0 ||
          commissionRatio.platform.trim() >= 100
        ) {
          errors.platform = "Platform Percentage should be within 1 to 100";
        } else {
          delete errors[each];
          isDirty.platform = false;
        }
      }
      if (
        commissionRatio.vendor.trim().length &&
        commissionRatio.platform.trim().length &&
        Number(commissionRatio.vendor) + Number(commissionRatio.platform) !==
          100
      ) {
        console.log(
          "not 100",
          commissionRatio.vendor + commissionRatio.platform
        );
        errors.both =
          "Addition of vendor and platform percentage should be 100.";
      } else {
        errors.both = "";
        delete errors.both;
      }
    });
    if(isDirty.referredAmount){  
      console.log(referredAmount);
      if (!referredAmount.trim().length) {
        errors.referredAmount = "Referred amount is Required";
        console.log(errors);
        } else if ( referredAmount.trim() <= 0 ) {
          errors.referredAmount = "Referred amount should be within positive";
        } else {
          delete errors[referredAmount];
          isDirty.referredAmount = false;
        }      
    }
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  _getBasicSettings = () => {
    getAllSettings().then(
      response => {
        console.log("====>>>>", response.settings.commissionRatio.vendor);
        let commissionRatio = {
          vendor: String(response.settings.commissionRatio.vendor),
          platform: String(response.settings.commissionRatio.platform),
        };
        let referredAmount = String(response.settings.referredAmount)          
        console.log(commissionRatio);
        this.setState({ commissionRatio, referredAmount });
      },
      error => {
        console.log(error);
      }
    );
  };

  _updateAllSettings = () => {
    this._manageLoading(true);
    updateAllSettings({ commissionRatio: this.state.commissionRatio, referredAmount: this.state.referredAmount }).then(
      response => {
        console.log(response);
        this._manageLoading(false);
        ToastsStore.success("Updated successfully!", 3000);
      },
      error => {
        console.log(error);
        this._manageLoading(false);
        ToastsStore.error(error.reson, 3000);
      }
    );
  };

  _manageLoading(value) {
    let { savingPercentages } = this.state;
    savingPercentages = value;
    this.setState({ savingPercentages });
  }

  componentDidMount() {
    this._getBasicSettings();
  }

  render() {
    const { referredAmount } = this.state;    
    return (
      <Col xs="6" lg="12">
        <div className="page-heading">
          <h4>Partner-Platform Revenue Split</h4>
        </div>
        <Card>
          <CardBody>
            <Form onSubmit={this._handleOnSubmit} noValidate>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="vendor">Partner</Label>
                    <InputGroup
                      className={
                        this.state.errors && this.state.errors.vendor
                          ? "invalid-input"
                          : "valid-input"
                      }
                    >
                      <Input
                        type="number"
                        id="vendor"
                        name="vendor"
                        placeholder="Enter partner percentage"
                        value={this.state.commissionRatio.vendor}
                        onChange={(e)=>this._handleOnChange(e,'')}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>%</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {this.state.errors && (
                      <div className="validation-error">
                        {this.state.errors.vendor}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label htmlFor="platform">Platform</Label>
                    <InputGroup
                      className={
                        this.state.errors && this.state.errors.platform
                          ? "invalid-input"
                          : "valid-input"
                      }
                    >
                      <Input
                        type="number"
                        id="platform"
                        name="platform"
                        placeholder="Enter platform percentage"
                        value={this.state.commissionRatio.platform}
                        onChange={(e)=>this._handleOnChange(e,'')}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>%</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {this.state.errors && (
                      <div className="validation-error">
                        {this.state.errors.platform}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col xs="6" lg="12">
                {this.state.errors && (
                    <div className="validation-error text-center">
                      {this.state.errors.both}
                    </div>
                  )}
                <div className="page-heading">
                  <h4>Referral Bonus credit</h4>
                </div>
                <Card>
                  <CardBody>
                    <Row className="justify-content-md-center">
                      <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="name">Credit</Label>
                          <InputGroup className={
                            this.state.errors && this.state.errors.referredAmount
                              ? "invalid-input"
                              : "valid-input"
                            }>
                            <Input type="text" id="" name="referredAmount" placeholder="Referred Amount" value={referredAmount} onChange={(e)=>this._handleOnChange(e, 'referredAmount')}/>
                            <InputGroupAddon addonType="append">
                              <InputGroupText>$</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {this.state.errors && (
                      <div className="validation-error">
                        {this.state.errors.referredAmount}
                      </div>
                    )}
                        </FormGroup>
                      </Col>
                    </Row>                    
                  </CardBody>
                </Card>
              </Col>
              </Row>
              <Row className="submit-panel text-center">
                <Col xs="12">
                  
                  <FormGroup>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={this.state.savingPercentages}
                    >
                      {this.state.savingPercentages && (
                        <i className="fa fa-spinner fa-spin mr5"></i>
                      )}
                      Update
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>            
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default BasicSettings;
