import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Row,
  // Table,
  // FormGroup,
  Form,
  Input,
  Button,
  // InputGroupAddon,
  // InputGroupText,
  InputGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AppSwitch } from "@coreui/react";
import {
  getAllProperties,
  getAllAmenities,
  changePropertyStatus,
} from "../../../http/http-calls";
import CustomTable from "../../../components/CustomTable";
import config from "../../../config/index";
import { ToastsStore } from "react-toasts";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class Properties extends Component {
  state = {
    placeStatusList: config.userStatusList,
    propertyList: [],
    propertyListBackup: [],
    amenities: [],
    headerKeys: [
      { id: "name", label: "Place" },
      { id: "location", label: "Location" },
      { id: "provider", label: "Provider" },
      { id: "booking", label: "Bookings" },
      { id: "action", label: "Action" },
    ],
    loadingData: false,
    changeStatusLoading: false,
    filters: {
      amenity: "",
      isActive: "",
      search: "",
      provider: "",
    },
  };

  _parseFilters = () => {
    //   Check if search string is present
    if (this.props.location.search && this.props.location.search.length) {
      const queryParamsString = this.props.location.search;
      // Check if id key is available
      let { filters } = this.state;
      if (queryParamsString.includes("?provider=")) {
        filters.provider = queryParamsString.split("?provider=")[1];
      }
      this.setState({ filters });
    }
  };
  _gotoPlaceDetails = (propertyId) => {
    this.props.history.push("place-details/" + propertyId);
  };
  _gotoAddPlace = () => {
    this.props.history.push("add-place");
  };
  _manageLoading = (key, value) => {
    let { loadingData, changeStatusLoading } = this.state;
    if (key === "data") {
      loadingData = value;
    } else if (key === "change-status") {
      changeStatusLoading = value;
    }
    this.setState({ loadingData, changeStatusLoading });
  };

  _getAllAmenities = () => {
    this._manageLoading("data", true);
    getAllAmenities().then(
      (response) => {
        console.log(response);
        this.setState({ amenities: response.facilities });
        this._getAllProperties();
      },
      (error) => {
        console.log(error);
        this._manageLoading(false);
        ToastsStore.error(error.reason, 1500);
      }
    );
  };

  _getAllProperties() {
    getAllProperties().then(
      (response) => {
        console.log(response);
        this._manageLoading("data", false);
        let propertyList = response.Properties.map((each) => {
          return {
            id: each._id,
            name: each.buildingName,
            location: each.city + ", " + each.country,
            booking: each._booking ? each._booking.length : 0,
            isActive: each.isActive,
            facilities: each.facilities,
            provider: {
              name: each._rentee && each._rentee.name.full,
              id: each._rentee && each._rentee.id,
            },
            action: "",
          };
        });
        this.setState({ propertyList, propertyListBackup: propertyList });
        this._filterPropertyList();
      },
      (error) => {
        console.log(error);
        this._manageLoading("data", false);
      }
    );
  }

  componentDidMount() {
    this._parseFilters();
    this._getAllAmenities();
  }

  _onStatusChange = (row) => {
    console.log(row);
    row.isActive = !row.isActive;
    this._manageLoading("change-status", true);
    changePropertyStatus(row.id, {
      status: row.isActive,
    }).then(
      (response) => {
        console.log(response);
        // this.setState({ row });
        this._manageLoading("change-status", false);
        ToastsStore.success("Status updated successfully!", 3000);
      },
      (error) => {
        console.log(error);
        this._manageLoading("change-status", false);
        ToastsStore.error(error.reason, 3000);
      }
    );
  };

  _dataFormat = (cell, row, header) => {
    // console.log(cell, row, header);
    if (header === "booking") {
      return cell ? (
        <Link to={`/dashboard/booking?property=${row.id}`}>{cell}</Link>
      ) : (
        "No Bookings yet"
      );
    } else if (header === "provider") {
      // return <a href="/dashboard/provider-detaisl/"></a>
      return <Link to={`provider-details/${cell.id}`}>{cell.name}</Link>;
    } else if (header === "action") {
      return (
        <React.Fragment>
          <AppSwitch
            className={"mx-1 status-switch"}
            variant={"3d"}
            outline={"alt"}
            color={"primary"}
            checked={row.isActive}
            label
            dataOn={"Active"}
            dataOff={"Inactive"}
            onChange={() => this._onStatusChange(row)}
            disabled={this.state.changeStatusLoading}
          />
          <span>
            <i
              className="fa fa-eye cursor-pointer"
              aria-hidden="true"
              onClick={() => this._gotoPlaceDetails(row.id)}
            ></i>
          </span>
        </React.Fragment>
      );
    } else {
      return cell;
    }
  };

  _filterOnChange = ({ currentTarget }) => {
    let { filters } = this.state;
    filters[currentTarget.name] = currentTarget.value;
    this.setState({ filters });
    this._filterPropertyList();
  };

  _filterPropertyList = () => {
    let filterConditions = [];
    // console.log(this.state);
    let { filters, propertyListBackup, propertyList } = this.state;
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filterConditions.push(key);
      }
    });
    console.log(filterConditions, filters);
    propertyList = propertyListBackup;
    if (filterConditions.length) {
      if (filters.amenity) {
        propertyList = propertyList.filter((each) => {
          return each.facilities.find((item) => {
            return (
              item.title === filters.amenity ||
              item.title.en === filters.amenity
            );
          });
        });
      }
      if (filters.isActive) {
        propertyList = propertyList.filter((each) => {
          return each.isActive === JSON.parse(filters.isActive);
        });
      }
      if (filters.search.trim().length) {
        propertyList = propertyList.filter((each) => {
          return (
            each.name.toLowerCase().includes(filters.search.toLowerCase()) ||
            each.location.toLowerCase().includes(filters.search.toLowerCase())
          );
        });
      }
      if (filters.provider) {
        propertyList = propertyList.filter((each) => {
          return each.provider.id === filters.provider;
        });
      }
      this.setState({ propertyList });
    } else {
      this.setState({ propertyList: propertyListBackup });
    }
  };

  _clearFilter = () => {
    let { filters } = this.state;
    filters = {
      amenity: "",
      isActive: "",
      search: "",
    };
    this.setState({ filters }, () => {
      this._filterPropertyList();
    });
  };

  render() {
    return (
      <div className="animated fadeIn">
        <div className="page-heading">
          <h2>Places</h2>
        </div>
        <Row className="table-panel">
          <Col xs="12" lg="12">
            <Card>
              {!this.state.loadingData && (
                <CardBody>
                  <div className="table-top">
                    <Form onSubmit={this.handleOnSubmit} noValidate>
                      {/* <InputGroup className="mb-3 add-new-button">
                      <Button
                        type="button"
                        color="primary"
                        onClick={this.gotoAddPlace}
                      >
                        + Add New
                      </Button>
                    </InputGroup> */}
                      <InputGroup className="mb-3">
                        <Input
                          type="text"
                          name="search"
                          placeholder="Search"
                          autoComplete="search"
                          value={this.state.filters.search}
                          onChange={this._filterOnChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <select
                          id="amenity"
                          name="amenity"
                          className="form-control"
                          value={this.state.filters.amenity}
                          onChange={this._filterOnChange}
                        >
                          <option value="">Select Amenities</option>
                          {this.state.amenities.map((item, index) => (
                            <option key={index} value={item.title.en}>
                              {item.title.en}
                            </option>
                          ))}
                        </select>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <select
                          id="status"
                          name="isActive"
                          className="form-control"
                          value={this.state.filters.isActive}
                          onChange={this._filterOnChange}
                        >
                          <option value="">Status</option>
                          {this.state.placeStatusList.map((item) => (
                            <option
                              key={item}
                              value={item === "Active" ? true : false}
                            >
                              {item}
                            </option>
                          ))}
                        </select>
                      </InputGroup>
                      <InputGroup
                        className="mb-3 filter-icon"
                        onClick={this._clearFilter}
                      >
                        {/* <i className="fa fa-filter" aria-hidden="true"></i> */}
                        <i className="fa fa-eraser"></i>
                      </InputGroup>
                      &nbsp; &nbsp;
                      <ExcelFile
                        element={
                          <Button type="button" color="primary">
                            Export Data
                          </Button>
                        }
                        filename="Properties"
                      >
                        <ExcelSheet
                          data={this.state.propertyList}
                          name="Properties"
                        >
                          <ExcelColumn label="Name" value="name" />
                          <ExcelColumn label="Location" value="location" />
                          <ExcelColumn
                            label="Provider"
                            value={(col) => col.provider.name}
                          />
                          <ExcelColumn label="Bookings" value="booking" />
                          <ExcelColumn
                            label="Status"
                            value={(col) =>
                              col.isActive ? "Active" : "InActive"
                            }
                          />
                        </ExcelSheet>
                      </ExcelFile>
                      &nbsp; &nbsp;
                      <Button
                        type="button"
                        color="primary"
                        onClick={() => this._gotoAddPlace()}
                      >
                        Add Place
                      </Button>
                    </Form>
                  </div>
                  <CustomTable
                    tableData={this.state.propertyList}
                    headerKeys={this.state.headerKeys}
                    dataFormat={this._dataFormat}
                  ></CustomTable>
                </CardBody>
              )}
              {this.state.loadingData && (
                <CardBody>
                  <div className="loading-section list-loading">
                    <i className="fa fa-spinner fa-spin"></i> &nbsp; Loading
                    Property List..
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Properties;
