import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  Form,
  // FormFeedback,
  Input,
  // Img,
  Button,
  // InputGroupAddon,
  // InputGroupButtonDropdown,
  // InputGroupText,
  Label,
  InputGroup,
} from "reactstrap";
// import { AppSwitch } from '@coreui/react'
import {
  getPropertyDetails,
  getAllAmenities,
  googlePlaceSearch,
  updateProperty,
  getVimeoLink,
} from "../../../http/http-calls";
import { uploadFileMultiPart } from "../../../http/http-service";
import { ToastsStore } from "react-toasts";
class EditPlace extends Component {
  state = {
    pageName: "",
    loadingData: false,
    savingData: false,
    uploadingImage: false,
    uploadingVideo: false,
    propertyDetails: null,
    propertyName: "",
    amenities: [],
    providerList: [],
    errors: {},
    isDirty: {
      buildingName: false,
      address: false,
      picUrls: false,
      videoUrls: false,
      description: false,
      facilities: false,
    },
    showSuggestionList: false,
    locationSuggestionList: [],
    spaceType: ["Hot Desk", "Fixed Desk", "Private Office", "Conference Room"],
    rateTypes: ["hourly", "daily", "weekly", "monthly"],
    confIndexs: [],
  };
  componentDidMount() {
    this._setPageName(this.props.location.pathname);
    this._getAllAmenities();
  }

  _setPageName(path) {
    let pageName = path.split("/")[2];
    console.log(pageName);
    if (pageName === "edit-place") {
      pageName = "Place Edit";
    } else {
      pageName = "Place Add";
    }
    this.setState({ pageName });
  }

  _manageLoading = (key, value) => {
    let { loadingData, savingData, uploadingImage, uploadingVideo } =
      this.state;
    if (key === "load-data") {
      loadingData = value;
    } else if (key === "save-data") {
      savingData = value;
    } else if (key === "upload-image") {
      uploadingImage = value;
    } else if (key === "upload-video") {
      uploadingVideo = value;
    }
    this.setState({ loadingData, savingData, uploadingImage, uploadingVideo });
  };

  _getPropertyDetails = (id) => {
    getPropertyDetails(id).then(
      (response) => {
        console.log(response);
        this.setState({ propertyName: response.property.buildingName });
        let propertyDetails = {
          buildingName: response.property.buildingName,
          description: response.property.description,
          _id: response.property._id,
          address: response.property.city + ", " + response.property.country,
          picUrls: response.property.picUrls,
          videoUrls: response.property.videoUrls,
          _spaces: response.property._spaces,
          facilities: response.property.facilities,
        };
        let { amenities, rateTypes } = this.state;
        let confIndexs = [];
        amenities.forEach((each) => {
          let facility = propertyDetails.facilities.find((item) => {
            return (
              item.title === each.title.en || item.title.en === each.title.en
            );
          });
          if (facility) {
            each.isSelected = true;
          } else {
            each.isSelected = false;
          }
        });
        propertyDetails._spaces.forEach((space, spaceIndex) => {
          if (space.isActive && space.spaceType !== "Conference Room") {
            rateTypes.forEach((item, j) => {
              let rate = space.rates.find((e) => {
                return e.period === item;
              });
              if (!rate) {
                space.rates.push({ period: item, amount: 0, currency: "usd" });
              }
            });
          } else {
            confIndexs.push(spaceIndex);
            ["hourly", "daily"].forEach((item, j) => {
              let rate = space.rates.find((e) => {
                return e.period === item;
              });
              if (!rate) {
                space.rates.unshift({
                  period: item,
                  amount: 0,
                  currency: "usd",
                });
              }
            });
          }
        });
        // let _spaces=[]
        // spaceType.forEach((each, index)=>{
        //   if(propertyDetails._spaces[index]){
        //     rateTypes.forEach((item, j)=>{
        //       if(!propertyDetails._spaces[index].rates[j]) {
        //         propertyDetails._spaces[index].rates[j] = {period:item, amount:0};
        //       }
        //     })
        //     _spaces[index] = propertyDetails._spaces[index];
        //     } else{
        //       _spaces[index] = {
        //         spaceType: each,
        //         noOfSeats:0,
        //         rates:[{period:'hourly', amount:0}, {period:'daily', amount:0}, {period:'weekly', amount:0}, {period:'monthly', amount:0}]
        //       }
        //     }
        //   })
        // propertyDetails._spaces = _spaces;
        // console.log("_spaces", _spaces);
        this.setState({ propertyDetails, amenities, confIndexs });
        console.log("amenities", amenities);
        this._manageLoading("load-data", false);
      },
      (error) => {
        console.log(error);
        this._manageLoading("load-data", false);
      }
    );
  };

  _getAllAmenities = () => {
    this._manageLoading("load-data", true);
    getAllAmenities().then(
      (response) => {
        console.log(response);
        let amenities = response.facilities.map((each) => {
          delete each.addedOn;
          delete each._addedBy;
          delete each._id;
          return each;
        });
        this.setState({ amenities });
        this._getPropertyDetails(this.props.match.params.id);
      },
      (error) => {
        console.log(error);
        this._manageLoading("load-data", false);
        ToastsStore.error(error.reason, 1500);
      }
    );
  };

  _handleOnChange = ({ currentTarget }) => {
    const { propertyDetails, isDirty } = this.state;
    propertyDetails[currentTarget.name] = currentTarget.value;
    isDirty[currentTarget.name] = true;
    this.setState({ propertyDetails });
    this._validateForm();
  };

  _handleCheckChange = ({ currentTarget }, index) => {
    const { amenities, isDirty } = this.state;
    console.log(currentTarget, index);
    amenities[index].isSelected = !amenities[index].isSelected;
    isDirty[currentTarget.name] = true;
    this.setState({ amenities });
    this._validateForm();
  };

  _handleSpaceChange = ({ currentTarget }, key, index, rateIndex) => {
    console.log(currentTarget, key, index, rateIndex);
    const { propertyDetails } = this.state;
    if (key === "noOfSeats") {
      if (!currentTarget.value.match(/^[-+]?[0-9]+\.[0-9]+$/)) {
        propertyDetails._spaces[index][key] = currentTarget.value;
      }
    } else if (key === "rates") {
      propertyDetails._spaces[index][key][rateIndex].amount =
        currentTarget.value;
    }
    this.setState({ propertyDetails });
  };

  _fileChange = (event, key) => {
    const file = event.target.files[0];
    if (file) {
      this._uploadFile(event.target.files[0], key);
    } else {
    }
  };

  _uploadFile = (file, key) => {
    var fd = new FormData();
    fd.append("file", file);
    key === "videoUrls"
      ? this._manageLoading("upload-video", true)
      : this._manageLoading("upload-image", true);
    uploadFileMultiPart(
      false,
      fd,
      key === "videoUrls" ? "video" : "image"
    ).then(
      (response) => {
        console.log(response.secure_url);
        const { propertyDetails } = this.state;
        if (key === "videoUrls") {
          propertyDetails[key][0] = "";
          propertyDetails[key][0] = response.secure_url;
          // this._getVimeoLink(response.secure_url);
        } else {
          propertyDetails[key].push(response.secure_url);
        }
        this.setState({ propertyDetails });
        key === "videoUrls"
          ? this._manageLoading("upload-video", false)
          : this._manageLoading("upload-image", false);
      },
      (error) => {
        console.log(error);
        key === "videoUrls"
          ? this._manageLoading("upload-video", false)
          : this._manageLoading("upload-image", false);
        ToastsStore.error(error.reason || "Something Went Wrong!", 3000);
      }
    );
  };

  _removePropertyImage = (index) => {
    let { propertyDetails } = this.state;
    propertyDetails.picUrls.splice(index, 1);
    this.setState({ propertyDetails });
  };

  _searchPlace = ({ currentTarget }) => {
    const { propertyDetails, isDirty } = this.state;
    console.log(currentTarget.value);
    propertyDetails.address = currentTarget.value;
    isDirty.address = true;
    this.setState({ propertyDetails });
    this._validateForm();
    googlePlaceSearch(propertyDetails.address).then((response) => {
      console.log(response);
      this.setState({
        locationSuggestionList: response,
        showSuggestionList: true,
      });
    });
  };

  _selectLocation = (each) => {
    console.log(each);
    let { propertyDetails, showSuggestionList, isDirty } = this.state;
    propertyDetails.address = each;
    showSuggestionList = false;
    isDirty.address = true;
    this.setState({ propertyDetails, showSuggestionList });
    this._validateForm();
  };

  _validateForm() {
    const { propertyDetails, amenities, errors, isDirty } = this.state;
    console.log(propertyDetails, isDirty);
    Object.keys(propertyDetails).forEach((each) => {
      if (each === "buildingName" && isDirty.buildingName) {
        if (!propertyDetails.buildingName.trim().length) {
          errors.buildingName = "Building Name is Required";
        } else {
          delete errors[each];
          isDirty.buildingName = false;
        }
      } else if (each === "description" && isDirty.description) {
        if (!propertyDetails.description.trim().length) {
          errors.description = "Description is Required";
        } else {
          delete errors[each];
          isDirty.description = false;
        }
      } else if (each === "facilities" && isDirty.facilities) {
        if (
          !amenities.filter((each) => {
            return each.isSelected;
          }).length
        ) {
          console.log("here===>");
          errors.facilities = "Please select atleast one Amenity";
        } else {
          delete errors[each];
          isDirty.description = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  _handleOnSubmit = (event) => {
    event.preventDefault();
    let { isDirty } = this.state;
    isDirty = {
      buildingName: false,
      address: false,
      picUrls: false,
      videoUrls: false,
      description: false,
    };
    this.setState({ isDirty });
    console.log(this.state.isDirty);
    let errors = this._validateForm();
    console.log(errors);
    // this.setState({errors});
    if (!errors) {
      let data = Object.assign({}, this.state.propertyDetails);
      data.city = data.address.split(", ")[0];
      data.country = data.address.split(", ")[1];
      data.facilities = this.state.amenities.filter((each) => {
        return each.isSelected;
      });
      data._spaces = data._spaces.filter((each) => {
        return each.noOfSeats > 0;
      });
      data._spaces.forEach((each) => {
        each.noOfSeats = Number(each.noOfSeats);
        each.rates.forEach((item) => {
          item.amount = Number(item.amount);
        });
      });
      console.log("Make API call", data);
      this._updateProperty(data._id, data);
    }
  };

  _updateProperty = (id, data) => {
    this._manageLoading("save-data", true);
    updateProperty(id, data).then(
      (response) => {
        console.log(response);
        this._manageLoading("save-data", false);
        ToastsStore.success("Property updated successfully!", 3000);
        this.props.history.push(
          "../place-details/" + this.state.propertyDetails._id
        );
      },
      (error) => {
        console.log(error);
        ToastsStore.error(error.reason, 3000);
        this._manageLoading("save-data", false);
      }
    );
  };

  savePlaceDetails = () => {
    this.props.history.push("place-details");
  };

  _backToDetails = () => {
    this.props.history.push(
      "../place-details/" + this.state.propertyDetails._id
    );
  };

  _getVimeoLink = (link) => {
    getVimeoLink({ link }).then(
      (response) => {
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  render() {
    let { propertyDetails, propertyName, amenities } = this.state;
    return (
      <div className="animated fadeIn">
        <Row className="table-panel booking-panel edit-booking">
          <Col xs="12" sm="6">
            <div className="page-heading">
              <h2>{this.state.pageName}</h2>
            </div>
            {!this.state.loadingData && propertyDetails && (
              <Card>
                <CardHeader>
                  <strong>Place {propertyName}</strong>
                  <Button color="secondary" onClick={this._backToDetails}>
                    <i className="fa fa-arrow-left"></i> &nbsp; Back
                  </Button>
                </CardHeader>
                <Form onSubmit={this._handleOnSubmit} noValidate>
                  <CardBody>
                    <Row>
                      <Col xs="4">
                        <FormGroup>
                          <Label htmlFor="buildingName">Place</Label>
                          <FormGroup>
                            <Input
                              type="text"
                              name="buildingName"
                              id="buildingName"
                              value={propertyDetails.buildingName}
                              onChange={this._handleOnChange}
                              className={
                                this.state.errors &&
                                this.state.errors.buildingName
                                  ? "invalid-input"
                                  : "valid-input"
                              }
                            ></Input>
                            {this.state.errors && (
                              <div className="validation-error">
                                {this.state.errors.buildingName}
                              </div>
                            )}
                          </FormGroup>
                        </FormGroup>
                      </Col>
                      <Col xs="4">
                        <FormGroup>
                          <Label htmlFor="name">Location</Label>
                          <Input
                            type="text"
                            id="input1-group1"
                            name="address"
                            placeholder="Location"
                            value={propertyDetails.address}
                            onChange={this._searchPlace}
                          />
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.address}
                            </div>
                          )}
                          {this.state.locationSuggestionList.length !== 0 &&
                            this.state.showSuggestionList && (
                              <ul className="location-suggestion-ul">
                                {this.state.locationSuggestionList.map(
                                  (each) => (
                                    <li
                                      key={each.location}
                                      onClick={() =>
                                        this._selectLocation(each.location)
                                      }
                                    >
                                      <i className="fa fa-map-marker"></i>&nbsp;
                                      {each.location}
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                        </FormGroup>
                      </Col>
                    </Row>
                    {propertyDetails._spaces.map((each, index) => (
                      <div key={index}>
                        {each.isActive && (
                          <Row>
                            {each.spaceType !== "Conference Room" && (
                              <Col xs="12">
                                <Row className="seat-details">
                                  <Col xs="3">
                                    <FormGroup>
                                      <Label htmlFor="name">
                                        {each.spaceType + "(s)"}
                                      </Label>
                                    </FormGroup>
                                  </Col>
                                  <Col xs="4">
                                    <FormGroup>
                                      <InputGroup>
                                        <Input
                                          type="number"
                                          id="name"
                                          placeholder="Enter No. of Seats"
                                          name="noOfSeats"
                                          value={each.noOfSeats}
                                          onChange={(e) =>
                                            this._handleSpaceChange(
                                              e,
                                              "noOfSeats",
                                              index
                                            )
                                          }
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  {each.rates.map((item, j) => (
                                    <Col xs="3" key={j}>
                                      <FormGroup>
                                        <Label
                                          htmlFor="name"
                                          className="capitalize"
                                        >
                                          {item.period}
                                        </Label>
                                        <Input
                                          type="number"
                                          id="name"
                                          placeholder="Enter amount in $"
                                          disabled={each.noOfSeats <= 0}
                                          value={item.amount}
                                          onChange={(e) =>
                                            this._handleSpaceChange(
                                              e,
                                              "rates",
                                              index,
                                              j
                                            )
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                  ))}
                                </Row>
                              </Col>
                            )}
                            {each.spaceType === "Conference Room" && (
                              <Col xs="12">
                                <Row className="seat-details">
                                  <Col xs="6">
                                    <FormGroup>
                                      <Label htmlFor="name">
                                        Conference Room(s)
                                      </Label>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row className="conference-form">
                                  <Col xs="3">
                                    <FormGroup>
                                      <Label htmlFor="name">No of Seats</Label>
                                      <Input
                                        type="number"
                                        id="name"
                                        placeholder="0"
                                        value={each.noOfSeats}
                                        onChange={(e) =>
                                          this._handleSpaceChange(
                                            e,
                                            "noOfSeats",
                                            index
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col xs="3">
                                    <FormGroup>
                                      <Label htmlFor="name">No of Rooms</Label>
                                      <Input
                                        type="number"
                                        id="name"
                                        placeholder="0"
                                        value={each.conferenceRoomCapacity}
                                        onChange={(e) =>
                                          this._handleSpaceChange(
                                            e,
                                            "conferenceRoomCapacity",
                                            index
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  {each.rates.map((rate, rateIndex) => (
                                    <Col xs="3" key={rateIndex}>
                                      <FormGroup>
                                        <Label htmlFor="name">
                                          {rate.period} ({rate.currency})
                                        </Label>
                                        <Input
                                          type="text"
                                          id="name"
                                          placeholder="$"
                                          value={rate.amount}
                                          onChange={(e) =>
                                            this._handleSpaceChange(
                                              e,
                                              "rates",
                                              index,
                                              rateIndex
                                            )
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                  ))}
                                </Row>
                              </Col>
                            )}
                          </Row>
                        )}
                      </div>
                    ))}

                    {/* <Col xs="12" className="text-center">
                    <FormGroup>
                    <Button type="button" color="primary" >Add</Button>
                    </FormGroup>
                  </Col>
                  <button type="button" className="button-delete"><span className="button-inner"><i className="fa fa-trash" aria-hidden="true"></i></span></button> */}
                    <Row>
                      <Col md="3">
                        <Label>Amenities</Label>
                      </Col>
                      <Col md="12" className="facilities-panel">
                        {amenities.map((each, index) => (
                          <FormGroup key={index} check inline>
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id={`facility` + index}
                              name="facilities"
                              checked={each.isSelected}
                              onChange={(e) =>
                                this._handleCheckChange(e, index)
                              }
                            />
                            <Label
                              className="form-check-label"
                              check
                              htmlFor={`facility` + index}
                            >
                              {each.title.en}
                            </Label>
                          </FormGroup>
                        ))}
                        {this.state.errors && (
                          <div className="validation-error">
                            {this.state.errors.facilities}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label htmlFor="name">About</Label>
                          <Input
                            type="textarea"
                            name="description"
                            id="textarea-input"
                            rows="9"
                            placeholder="Content..."
                            value={propertyDetails.description}
                            onChange={this._handleOnChange}
                            className={
                              this.state.errors && this.state.errors.description
                                ? "invalid-input"
                                : "valid-input"
                            }
                          />
                          {this.state.errors && (
                            <div className="validation-error">
                              {this.state.errors.description}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="4">
                        <FormGroup>
                          <Label htmlFor="name">
                            Pictures &nbsp;
                            {this.state.uploadingImage && (
                              <i className="fa fa-spinner fa-spin"></i>
                            )}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs="8">
                        <FormGroup>
                          <InputGroup>
                            <Input
                              type="file"
                              id="name"
                              placeholder="0"
                              accept="image/*"
                              name="picUrls"
                              disabled={this.state.uploadingImage}
                              onChange={(e) => this._fileChange(e, "picUrls")}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col xs="12" className="edit-property">
                        {propertyDetails.picUrls.map((each, index) => (
                          <p className="image-upload" key={each}>
                            <img src={each} alt="pic" />
                            {propertyDetails.picUrls.length > 1 && (
                              <button
                                type="button"
                                className="button-delete"
                                onClick={() => this._removePropertyImage(index)}
                              >
                                <span className="button-inner">
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </button>
                            )}
                          </p>
                        ))}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="4">
                        <FormGroup>
                          <Label htmlFor="name">
                            Video &nbsp;
                            {/* {this.state.uploadingVideo && <i className="fa fa-spinner fa-spin"></i> } */}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs="8">
                        <FormGroup>
                          <InputGroup>
                            <Input
                              type="file"
                              id="name"
                              name="videoUrls"
                              placeholder="0"
                              accept="video/mp4,video/x-m4v,video/*"
                              disabled={this.state.uploadingVideo}
                              onChange={(e) => this._fileChange(e, "videoUrls")}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col xs="12">
                        <p className="video-section">
                          {!this.state.uploadingVideo && (
                            <video width="320" height="240" controls>
                              <source
                                src={propertyDetails.videoUrls[0]}
                              ></source>
                            </video>
                          )}
                          {this.state.uploadingVideo && (
                            <span>
                              {" "}
                              <i className="fa fa-spinner fa-spin"></i> &nbsp;
                              Uploading Video
                            </span>
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Row className="submit-panel">
                      <Col xs="12">
                        <FormGroup>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={this.state.savingData}
                          >
                            {this.state.savingData && (
                              <i className="fa fa-spinner fa-spin mr5"></i>
                            )}
                            Save
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Form>
              </Card>
            )}
            {this.state.loadingData && (
              <Card>
                <CardBody>
                  <div className="loading-section no-border">
                    <i className="fa fa-spinner fa-spin"></i> &nbsp; Loading
                    Property Details..
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default EditPlace;
