import React, { Component } from "react";
import { format } from "date-fns";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  Input,
  Table,
  Button,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AppSwitch } from "@coreui/react";
import {
  getUserDetails,
  changeUserStatus,
  addCredit,
} from "../../../http/http-calls";
import { ToastsStore } from "react-toasts";

class ViewRenterDetails extends Component {
  state = {
    renterDetails: null,
    loadingData: false,
    changeStatusLoading: false,
    credit: {
      amount: "",
      error: "",
    },
    loadingAddCredit: false,
  };

  /**
   * To go to edit renter page
   * @memberof ViewRenterDetails
   */
  _gotoEditRenter = () => {
    this.props.history.push("../edit-renter/" + this.state.renterDetails._id);
  };

  /**
   * To get the renter details by ID
   * @memberof ViewRenterDetails
   */
  _getRenterDetails = (id) => {
    this._manageLoading("data", true);
    getUserDetails(id, { type: "Renter" }).then(
      (response) => {
        console.log(response);
        response.user._favourites = response.user._favourites
          ? response.user._favourites
          : [];
        this.setState({ renterDetails: response.user });
        this._manageLoading("data", false);
      },
      (error) => {
        console.log(error);
        this._manageLoading("data", false);
        ToastsStore.error(error.reason, 3000);
      }
    );
  };

  /**
   * To manage the renter details
   * @memberof ViewRenterDetails
   */
  _manageLoading = (key, value) => {
    let { loadingData, changeStatusLoading, loadingAddCredit } = this.state;
    if (key === "data") {
      loadingData = value;
    } else if (key === "change-status") {
      changeStatusLoading = value;
    } else if (key === "add-credit") {
      loadingAddCredit = value;
    }
    this.setState({ loadingData, changeStatusLoading, loadingAddCredit });
  };

  componentDidMount() {
    console.log(this.props.match.params.id);
    this._getRenterDetails(this.props.match.params.id);
  }

  /**
   * To format the date
   * @param {*} date
   * @returns
   * @memberof ViewRenterDetails
   */
  _formatDate(date) {
    return date ? format(new Date(date), "MMM d, yyyy") : "N/A";
  }

  /**
   * To change the renter status
   * @memberof ViewRenterDetails
   */
  _onStatusChange = () => {
    let { renterDetails } = this.state;
    renterDetails.isActive = !renterDetails.isActive;
    this._manageLoading("change-status", true);
    changeUserStatus(renterDetails._id, {
      status: renterDetails.isActive,
    }).then(
      (response) => {
        console.log(response);
        this.setState({ renterDetails });
        this._manageLoading("change-status", false);
        ToastsStore.success("Status updated successfully!", 3000);
      },
      (error) => {
        console.log(error);
        this._manageLoading("change-status", false);
        ToastsStore.error(error.reason, 3000);
      }
    );
  };

  /**
   * To calculate the total credits
   * @memberof ViewRenterDetails
   */
  _calculateCredits = () => {
    let totalCredits = this.state.renterDetails.credits;
    // reduce((acc, each)=>{
    //   return acc = acc + each.amount;
    // },0);
    return totalCredits;
  };

  _downloadFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop();
    document.body.appendChild(link);
    console.log(link);
    link.click();
    // document.body.removeChild(link);
  };

  _backToList = () => {
    this.props.history.push("../renters");
  };

  _onCreditChange = ({ currentTarget }) => {
    let { amount, error } = this.state.credit;
    amount = currentTarget.value.trim();
    if (!amount || amount <= 0) {
      error = true;
      this.setState({ credit: { amount, error } });
    } else if (!amount.match(/^\d*(\.\d{0,2})?$/)) {
    } else {
      error = false;
      this.setState({ credit: { amount, error } });
    }
  };

  _addCreditToProvider = () => {
    let { amount, error } = this.state.credit;
    let { renterDetails } = this.state;
    if (!amount || amount <= 0) {
      error = true;
    } else {
      error = false;
    }
    this.setState({ credit: { amount, error } });
    if (this.state.credit.error) {
      return;
    }
    let data = {
      type: "all",
      receiver: this.props.match.params.id,
      amount: Number(this.state.credit.amount),
      remarks: "",
    };
    console.log(data);
    this._manageLoading("add-credit", true);
    addCredit(data).then(
      (response) => {
        console.log(response);
        renterDetails.credits = (renterDetails.credits + data.amount).toFixed(
          2
        );
        this.setState({ renterDetails });
        this._manageLoading("add-credit", false);
        this.setState({ credit: { amount: "", error: false } });
        ToastsStore.success("Credit added successfully!");
      },
      (error) => {
        console.log(error);
        this._manageLoading("add-credit", false);
        ToastsStore.error(error.reason, 3000);
      }
    );
  };

  render() {
    let { renterDetails, credit, loadingAddCredit } = this.state;
    return (
      <div className="animated fadeIn">
        <Row className="table-panel booking-panel">
          <Col xs="12" sm="6">
            <div className="page-heading">
              <h2>View Renter</h2>
            </div>
            {!this.state.loadingData && renterDetails && (
              <Card>
                <CardHeader className="add-credit-section new-credit-header">
                  <strong>
                    {renterDetails.name.full}
                    {this._calculateCredits() > 0 && (
                      <span>
                        <em>+</em> $ {this._calculateCredits()}{" "}
                        <em>Credits Available</em>
                      </span>
                    )}
                  </strong>
                  <FormGroup>
                    <Input
                      type="number"
                      id="credit"
                      placeholder="Add Credit"
                      value={credit.amount}
                      onChange={this._onCreditChange}
                      className={credit.error ? "invalid-input" : "valid-input"}
                    />
                    <Button
                      type="button"
                      color="primary"
                      disabled={loadingAddCredit}
                      onClick={() => this._addCreditToProvider()}
                    >
                      {loadingAddCredit && (
                        <i className="fa fa-spinner fa-spin mr5" />
                      )}
                      Add
                    </Button>
                  </FormGroup>
                  <div>
                    <Button color="secondary mr-3" onClick={this._backToList}>
                      <i className="fa fa-arrow-left"></i> &nbsp; Back to List
                    </Button>
                    <Button color="primary" onClick={this._gotoEditRenter}>
                      Edit
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="6">
                      <img
                        src={
                          renterDetails.pictureUrl ||
                          "../../assets/img/avatars/user.png"
                        }
                        className="img-avatar user-profile-image"
                        alt="renter"
                        width="70"
                      />
                    </Col>
                    <Col xs="6" className="app-switch-panel">
                      <span>Status : </span>
                      <AppSwitch
                        className={"mx-1 details-status-switch"}
                        variant={"3d"}
                        outline={"alt"}
                        color={"primary"}
                        label
                        dataOn={"Active"}
                        dataOff={"Inactive"}
                        checked={renterDetails.isActive}
                        onChange={this._onStatusChange}
                        disabled={this.state.changeStatusLoading}
                      />
                      {/* <span>Active</span> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="name">Name</Label>
                        <p className="view-text">{renterDetails.name.full}</p>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="name">Email</Label>
                        <p className="view-text">{renterDetails.email}</p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="name">Phone</Label>
                        <p className="view-text">
                          {renterDetails.countryCode}{" "}
                          {renterDetails.phone || "N/A"}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="name">Date of Birth</Label>
                        <p className="view-text">
                          {this._formatDate(renterDetails.dateOfBirth)}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="name">Company</Label>
                        <p className="view-text">
                          {renterDetails.companyName || "N/A"}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="name">Role</Label>
                        <p className="view-text">
                          {renterDetails.designation || "N/A"}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="name">Location</Label>
                        <p className="view-text">
                          {renterDetails.address && renterDetails.address.city},
                          {renterDetails.address &&
                            renterDetails.address.country}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="name">Preference</Label>
                        <p className="view-text">
                          {renterDetails.spacePreference || "N/A"}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <FormGroup>
                        <Label htmlFor="name">Language</Label>
                        <p className="view-text">
                          {renterDetails.languagePreference}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col xs="6" className="text-details">
                      <Label htmlFor="name"></Label>
                      <p>
                        Bookings
                        {renterDetails._booking.length !== 0 && (
                          <Link
                            to={`/dashboard/booking?renter=${renterDetails._id}`}
                          >
                            <span>{renterDetails._booking.length}</span>
                          </Link>
                        )}
                        {!renterDetails._booking.length && (
                          <em className="no-bookings">No Bookings yet.</em>
                        )}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6">
                      <Label htmlFor="photoIdProofUrl"> Photo ID Proof</Label>
                      <p>
                        {renterDetails.photoIdProofUrl ? (
                          <a
                            href={renterDetails.photoIdProofUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                          >
                            {renterDetails.photoIdProofUrl.split("/").pop()}
                          </a>
                        ) : (
                          <em>Not Provided</em>
                        )}
                      </p>
                    </Col>
                  </Row>
                  <div className="favourite-panel">
                    <h2>
                      Favourite Places &nbsp;
                      {this.state.renterDetails._favourites.length > 0 && (
                        <span>
                          {this.state.renterDetails._favourites.length}
                        </span>
                      )}
                      {!this.state.renterDetails._favourites.length && (
                        <em className="font14">No Favourite Places yet.</em>
                      )}
                    </h2>
                    {this.state.renterDetails._favourites.length > 0 && (
                      <Table responsive bordered>
                        <thead>
                          <tr>
                            <th>Place</th>
                            <th>Location</th>
                            <th>Provider</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.renterDetails._favourites.map((each) => (
                            <tr key={each._id}>
                              <td>
                                {" "}
                                <Link to={`../place-details/${each.id}`}>
                                  {each.buildingName}
                                </Link>
                              </td>
                              <td>{each.streetAddress}</td>
                              <td>
                                {" "}
                                <Link
                                  to={`../provider-details/${each._rentee.id}`}
                                >
                                  {each._rentee.name.full}
                                </Link>
                              </td>
                            </tr>
                          ))}
                          {/* <tr>
                      <td>ABC</td>
                      <td>111 St Mexico city</td>
                      <td>Pompeius Rene</td>
                    </tr>
                    <tr>
                      <td>ABC</td>
                      <td>111 St Mexico city</td>
                      <td>Pompeius Rene</td>
                    </tr>
                    <tr>
                      <td>ABC</td>
                      <td>111 St Mexico city</td>
                      <td>Pompeius Rene</td>
                    </tr>
                    <tr>
                      <td>ABC</td>
                      <td>111 St Mexico city</td>
                      <td>Pompeius Rene</td>
                    </tr> */}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </CardBody>
              </Card>
            )}
            {this.state.loadingData && (
              <Card>
                <CardBody>
                  <div className="loading-section no-border">
                    <i className="fa fa-spinner fa-spin"></i> &nbsp; Loading
                    Renter Details..
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default ViewRenterDetails;
