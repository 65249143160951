import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  Form,
  Input,
  Button,
  InputGroupAddon,
  InputGroupText,
  Label,
  InputGroup,
} from "reactstrap";
import {
  changeUserStatus,
  googlePlaceSearch,
  addUser,
  AddMultipleProviders,
  AddMultipleProvidersValidate,
} from "../../../http/http-calls";
import { uploadFileMultiPart } from "../../../http/http-service";
import { ToastsStore } from "react-toasts";
import CustomTable from "../../../components/CustomTable";
import config from "../../../config/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as xlsx from "xlsx";
import moment from "moment";
import ConfirmAction from "./ConfirmAction";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class AddRenteeDetails extends Component {
  state = {
    section: "Add multiple providers",
    providerName: "",
    providerDetails: {
      fullName: "",
      email: "",
      password: "",
      confirmPassword: "",
      countryCode: config.countryCodeList[0],
      phone: "",
      languagePreference: config.languageList[0],
      dateOfBirth: "",
      address: "",
      latitude: "",
      longitude: "",
      location: "",
    },
    loadingData: false,
    changeStatusLoading: false,
    updateLoading: false,
    pictureLoading: false,
    photoIdProofLoading: false,
    sheetValidationLoading: false,
    sheetUploadLoading: false,
    languageList: config.languageList,
    countryCodeList: config.countryCodeList,
    errors: {},
    isDirty: {
      fullName: false,
      email: false,
      password: false,
      confirmPassword: false,
      phone: false,
      pictureUrl: false,
      languagePreference: false,
      dateOfBirth: false,
      isActive: false,
      address: false,
      photoIdProofUrl: false,
    },
    showSuggestionList: false,
    locationSuggestionList: [],
    renterList: [],
    filterRenterList: [],
    filterStatus: "",
    renterListErrors: [],
    headerKeys: [
      { id: "Sl.No", label: "Sl.No" },
      { id: "Name", label: "Name" },
      { id: "Email", label: "Email" },
      { id: "Password", label: "Password" },
      { id: "Country Code", label: "Country Code" },
      { id: "Phone", label: "Phone" },
      { id: "Location", label: "Location" },
      { id: "Language preference", label: "Language preference" },
      { id: "Date of Birth", label: "Date of Birth" },
      { id: "action", label: "Errors" },
    ],
    confirmModal: { isOpen: false, data: "", isArray: false },
  };

  _manageLoading = (key, value) => {
    let {
      loadingData,
      changeStatusLoading,
      updateLoading,
      pictureLoading,
      photoIdProofLoading,
      sheetValidationLoading,
      sheetUploadLoading,
    } = this.state;
    if (key === "data") {
      loadingData = value;
    } else if (key === "change-status") {
      changeStatusLoading = value;
    } else if (key === "update") {
      updateLoading = value;
    } else if (key === "pictureUrl") {
      pictureLoading = value;
    } else if (key === "photoIdProofUrl") {
      photoIdProofLoading = value;
    } else if (key === "isValidating") {
      sheetValidationLoading = value;
    } else if (key === "isUploading") {
      sheetUploadLoading = value;
    }
    this.setState({
      loadingData,
      changeStatusLoading,
      updateLoading,
      pictureLoading,
      photoIdProofLoading,
      sheetValidationLoading,
      sheetUploadLoading,
    });
  };

  //   _onStatusChange = () => {
  //     let { providerDetails } = this.state;
  //     providerDetails.isActive = !providerDetails.isActive;
  //     this._manageLoading("change-status", true);
  //     changeUserStatus(providerDetails._id, {
  //       status: providerDetails.isActive,
  //     }).then(
  //       (response) => {
  //         console.log(response);
  //         this.setState({ providerDetails });
  //         this._manageLoading("change-status", false);
  //         ToastsStore.success("Status updated successfully!", 3000);
  //       },
  //       (error) => {
  //         console.log(error);
  //         this._manageLoading("change-status", false);
  //         ToastsStore.error(error.reason, 3000);
  //       }
  //     );
  //   };

  _handleOnChange = ({ currentTarget }) => {
    const { providerDetails, isDirty } = this.state;
    providerDetails[currentTarget.name] = currentTarget.value;
    isDirty[currentTarget.name] = true;
    this.setState({ providerDetails });
    this._validateForm();
  };

  _handleDateChange = (date) => {
    const { providerDetails, isDirty } = this.state;
    providerDetails.dateOfBirth = date;
    isDirty.dateOfBirth = true;
    this.setState({ providerDetails });
    this._validateForm();
  };

  _handleOnSubmit = (event) => {
    event.preventDefault();
    let { isDirty } = this.state;
    isDirty = {
      fullName: false,
      email: false,
      phone: false,
      pictureUrl: false,
      spacePreference: false,
      languagePreference: false,
      companyName: false,
      designation: false,
      dateOfBirth: false,
      isActive: false,
      address: false,
    };
    this.setState({ isDirty });
    console.log(this.state.isDirty);
    let errors = this._validateForm();
    console.log(errors);
    // this.setState({errors});
    console.log(errors);
    if (!errors) {
      let data = Object.assign({}, this.state.providerDetails);
      data.name = {
        first: data.fullName.split(" ")[0],
        last: data.fullName.split(" ")[1],
      };
      data.dateOfBirth =
        data.dateOfBirth.getFullYear() +
        "-" +
        (parseInt(data.dateOfBirth.getMonth()) + 1) +
        "-" +
        data.dateOfBirth.getDate();
      data.address = {
        city: data.address.split(", ")[0],
        country: data.address.split(", ")[1],
      };
      data.userType = "Rentee";
      data.isSocial = false;
      data.isFirstLogin = false;
      delete data.fullName;
      console.log("Make API call", data);
      this._updateUser(data);
    }
  };

  _validateForm() {
    const { providerDetails, errors, isDirty } = this.state;
    // console.log(providerDetails, isDirty);
    Object.keys(providerDetails).forEach((each) => {
      if (each === "fullName") {
        if (!providerDetails.fullName.trim().length) {
          errors.fullName = "Name is Required";
        } else {
          delete errors[each];
          isDirty.fullName = false;
        }
      } else if (each === "email") {
        if (!providerDetails.email.trim().length) {
          errors[each] = "Email is Required";
        } else if (
          providerDetails.email.trim().length &&
          !new RegExp(
            "^[a-zA-Z0-9]{1}[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$"
          ).test(providerDetails.email)
        ) {
          errors.email = "Please enter valid Email";
        } else {
          delete errors[each];
          isDirty.email = false;
        }
      } else if (each === "phone") {
        if (!providerDetails.phone.trim().length) {
          errors.phone = "Phone number is Required";
        }
        // else if(providerDetails.phone.trim().length !== 10){
        //   errors.phone = "Phone number must be of 10 digits";
        // }
        else {
          delete errors[each];
          isDirty.phone = false;
        }
      } else if (each === "dateOfBirth") {
        if (
          typeof providerDetails.dateOfBirth !== "object" ||
          !providerDetails.dateOfBirth
        ) {
          errors.dateOfBirth = "Date of birth is Required";
        } else if (providerDetails.dateOfBirth > new Date()) {
          errors.dateOfBirth = "Date of birth must be before today";
        } else {
          delete errors[each];
          isDirty.dateOfBirth = false;
        }
      } else if (each === "companyName") {
        if (!providerDetails.companyName.trim().length) {
          errors.companyName = "Company Name is Required";
        } else {
          delete errors[each];
          isDirty.companyName = false;
        }
      } else if (each === "designation") {
        if (!providerDetails.designation.trim().length) {
          errors.designation = "Designation is Required";
        } else {
          delete errors[each];
          isDirty.designation = false;
        }
      } else if (each === "address") {
        if (!providerDetails.address.trim().length) {
          errors.address = "Location is Required";
        } else {
          delete errors[each];
          isDirty.address = false;
        }
      } else if (each === "password") {
        if (!providerDetails.password.trim().length) {
          errors.password = "Password required";
        } else if (providerDetails.password.trim().length < 6) {
          errors.password = "Password must be atleast 6 characters";
        } else {
          delete errors[each];
          isDirty.password = false;
        }
      } else if (each === "confirmPassword") {
        if (!providerDetails.confirmPassword.trim().length) {
          errors.confirmPassword = "Please confirm password";
        } else if (
          providerDetails.password.trim() !==
          providerDetails.confirmPassword.trim()
        ) {
          errors.confirmPassword = "Password not matching";
        } else {
          delete errors[each];
          isDirty.confirmPassword = false;
        }
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length ? errors : null;
  }

  _updateUser = (data) => {
    this._manageLoading("update", true);
    addUser(data).then(
      (response) => {
        console.log(response);
        this._manageLoading("update", false);
        ToastsStore.success("Successfully added new provider!", 3000);
        this.setState({
          providerDetails: {
            fullName: "",
            email: "",
            password: "",
            confirmPassword: "",
            countryCode: config.countryCodeList[0],
            phone: "",
            languagePreference: config.languageList[0],
            dateOfBirth: "",
            address: "",
            latitude: "",
            longitude: "",
            location: "",
          },
        });
        // this.props.history.push(
        //   "../provider-details/" + this.state.providerDetails._id
        // );
      },
      (error) => {
        console.log(error);
        ToastsStore.error(error.reason.en || "Something Went Wrong!", 3000);
        this._manageLoading("update", false);
      }
    );
    // this.props.history.push("provider-details");
  };

  _searchPlace = ({ currentTarget }) => {
    const { providerDetails, isDirty } = this.state;
    console.log(currentTarget.value);
    providerDetails.address = currentTarget.value;
    isDirty.address = true;
    this.setState({ providerDetails });
    this._validateForm();
    googlePlaceSearch(providerDetails.address).then((response) => {
      console.log(response);
      this.setState({
        locationSuggestionList: response,
        showSuggestionList: true,
      });
    });
  };

  _selectLocation = (each) => {
    console.log(each);
    let { providerDetails, showSuggestionList, isDirty } = this.state;
    providerDetails.address = each.location;
    providerDetails.location = each.location;

    this.onSelectAddress("placeId", each.placeId, function (location) {
      //Do something with location
      if (location) {
        providerDetails.latitude = location.latitude;
        providerDetails.longitude = location.longitude;
      }
    });
    showSuggestionList = false;
    isDirty.address = true;
    this.setState({ providerDetails, showSuggestionList });
    this._validateForm();
  };

  //   to find lat and lng from place_id

  onSelectAddress(type, content, callback) {
    var geocoder = new window.google.maps.Geocoder();
    if (type === "placeId") {
      geocoder.geocode({ placeId: content }, function (results, status) {
        if (status == window.google.maps.GeocoderStatus.OK) {
          callback({
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          });
        } else {
          callback(null);
        }
      });
    } else if (type === "address") {
      geocoder.geocode({ address: content }, function (results, status) {
        console.log(status, results);
        if (status == window.google.maps.GeocoderStatus.OK) {
          callback({
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          });
        } else {
          callback(null);
        }
      });
    }
  }

  //   _openFileBrowser = () => {
  //     this.refs.fileUploader.click();
  //   };

  //   _imageChange = (event, key) => {
  //     // console.log(event.target.files[0]);
  //     const file = event.target.files[0];
  //     if (file) {
  //       this._uploadImage(event.target.files[0], key);
  //     } else {
  //       const { providerDetails } = this.state;
  //       providerDetails[key] = "";
  //       this.setState({ providerDetails });
  //     }
  //   };

  //   _uploadImage = (file, key) => {
  //     var fd = new FormData();
  //     fd.append("file", file);
  //     this._manageLoading(key, true);
  //     uploadFileMultiPart(false, fd).then(
  //       (response) => {
  //         console.log(response.secure_url);
  //         const { providerDetails } = this.state;
  //         providerDetails[key] = response.secure_url;
  //         this.setState({ providerDetails });
  //         this._manageLoading(key, false);
  //         // this._validateForm();
  //       },
  //       (error) => {
  //         console.log(error);
  //         this._manageLoading(key, false);
  //         ToastsStore.error(error.reason || "Something Went Wrong!", 3000);
  //       }
  //     );
  //   };

  _backToDetails = () => {
    this.props.history.push("providers");
  };

  // upload multiple providers
  _addMultipleProviders(json) {
    // this._manageLoading("isValidating", true);
    let data = [];
    json.forEach((provider) => {
      // let addressSplit = provider.Location?.split(",") ?? [];
      data.push({
        // name: {
        //   first: provider.Name?.split(" ")[0] ?? "",
        //   last: provider.Name?.split(" ")[1] ?? "",
        // },
        email: provider.Email ?? "",
        // password: provider.Password ?? "",
        // confirmPassword: provider.Password ?? "",
        // countryCode: provider["Country Code"] ?? "",
        // phone: provider.phone ?? "",
        // location: provider.Location ?? "",
        // latitude: provider.latitude ?? "",
        // longitude: provider.longitude ?? "",
        // languagePreference: provider["Language preference"] ?? "",
        // dateOfBirth: provider["Date of Birth"] ?? "",
        // userType: "Rentee",
        // referralCode: null,
        // isFirstLogin: null,
        // address: {
        //   city: addressSplit[addressSplit.length - 2]?.trim() ?? "",
        //   country: addressSplit[addressSplit.length - 1]?.trim() ?? "",
        // },
      });
    });

    return AddMultipleProvidersValidate({ rentees: data }).then(
      (response) => {
        console.log(response);
        // this._manageLoading("isValidating", false);
        return response;
      },
      (error) => {
        console.log(error);
        // this._manageLoading("isValidating", false);
        return error;
      }
    );
  }
  _uploadSheet = async (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // Generating json from sheet
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonKeys = xlsx.utils.sheet_to_json(worksheet, {
          header: 1,
        })[0];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });
        console.log(jsonKeys);
        console.log(json);
        // first index will contain headers
        if (json.length > 0) {
          // header validation: missing or invalid headers
          let missingKeyErrors = [];
          this.state.headerKeys.forEach((key, index) => {
            if (key.id !== "Sl.No" && key.id !== "action") {
              if (!jsonKeys.includes(key.id)) {
                missingKeyErrors.push(key.id);
              }
            }
          });
          this.setState({
            renterListErrors: { missingKeyErrors: missingKeyErrors },
          });
          console.log(this.state.renterListErrors);

          this._manageLoading("isValidating", true);
          // validating raws
          if (this.state.renterListErrors.missingKeyErrors.length === 0) {
            json.forEach((provider, index) => {
              provider["Sl.No"] = index + 1;
              if (!provider.hasOwnProperty("errors")) provider["errors"] = [];
              // name
              if (!provider.Name) {
                provider.errors.push("Name is mandatory");
              }
              // email
              if (!provider.Email) {
                provider.errors.push("Email is mandatory");
              } else {
                for (let i = index + 1; i < json.length; i++) {
                  if (json[i].Email === provider.Email) {
                    let errorText = "Duplicate Email Found";
                    json[i]["errors"] = [errorText];
                    if (!provider.errors.includes(errorText)) {
                      provider.errors.push(errorText);
                    }
                  }
                }
              }
              // password
              if (!provider.Password) {
                provider.errors.push("Password is mandatory");
              } else if (provider.Password.trim().length < 6) {
                provider.errors.push("Password must be atleast 6 characters");
              }
              // country code
              if (!provider["Country Code"]) {
                provider.errors.push("Country Code is mandatory");
              } else if (
                !["+1", "+52", "+54", "+91", "1", "52", "54", "91"].includes(
                  provider["Country Code"]
                )
              ) {
                provider.errors.push(
                  "Country Code must be either +1, +52, +54 or +91"
                );
              }
              // phone
              if (!provider.Phone) {
                provider.errors.push("Phone number is mandatory");
              }
              // location
              if (!provider.Location) {
                provider.errors.push("Location is mandatory");
              } else {
                setTimeout(() => {
                  console.log(index);
                  this.onSelectAddress(
                    "address",
                    provider.Location,
                    function (location) {
                      //Do something with location
                      if (location) {
                        provider.latitude = location.latitude;
                        provider.longitude = location.longitude;
                      } else {
                        provider.errors.push("Invalid Location");
                      }
                    }
                  );
                  if (index === json.length - 1)
                    this._manageLoading("isValidating", false);
                }, 1000 * index);
              }
              // location preference
              if (!provider["Language preference"]) {
                provider.errors.push("Language preference is mandatory");
              } else if (
                !["english", "spanish", "portuguese"].includes(
                  provider["Language preference"].toLowerCase()
                )
              ) {
                provider.errors.push(
                  "Language preference must be either english, spanish or portuguese"
                );
              }
              // DOB
              if (!provider["Date of Birth"]) {
                provider.errors.push("Date of birth is mandatory");
              } else if (!moment(provider["Date of Birth"]).isValid()) {
                provider.errors.push("Invalid Date of Birth");
              } else {
                console.log(
                  provider["Date of Birth"],
                  moment(provider["Date of Birth"]).isValid()
                );
                provider["Date of Birth"] = moment(
                  provider["Date of Birth"]
                ).format("YYYY-MM-DD");
              }

              // check if any error catched
              // provider.errors.length > 0
              //   ? (provider["isError"] = "Error")
              //   : (provider["isError"] = "");
            });
            let isExist = this._addMultipleProviders(json);
            isExist.then((result) => {
              console.log(this.state.sheetValidationLoading);
              console.log("isExist", result);
              if (result.error) {
                result.rentees.forEach((rentee) => {
                  if (rentee.isExist) {
                    json.forEach((provider) => {
                      if (
                        provider.Email === rentee.email &&
                        !provider.errors.includes("Duplicate Email Found")
                      ) {
                        provider.errors.push("Email already registered");
                      }
                    });
                  }
                });
              }
            });

            this.setState({ renterList: json, filterRenterList: json });
            console.log(this.state.renterList);
          } else {
            let { confirmModal } = this.state;
            confirmModal.isOpen = true;
            confirmModal.data =
              "Missing headers: " +
              this.state.renterListErrors.missingKeyErrors.join(",");
            confirmModal.isArray = false;
            this.setState({ confirmModal });
          }
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  _openModal = (raw) => {
    console.log(raw);
    let { confirmModal } = this.state;
    if (raw.errors.length > 0) {
      confirmModal = { data: raw.errors, isOpen: true, isArray: true };
    }
    this.setState({ confirmModal });
  };
  _dataFormat = (cell, row, header) => {
    // console.log(cell, row, header);
    if (header === "action") {
      if (row.errors?.length > 0) {
        return (
          <React.Fragment>
            <span>
              <i
                className="fa fa-eye cursor-pointer"
                aria-hidden="true"
                onClick={() => this._openModal(row)}
              ></i>
            </span>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <span>No Error</span>
          </React.Fragment>
        );
      }
    } else {
      return cell;
    }
  };
  _onModalToggle = (action) => {
    let { confirmModal } = this.state;
    if (action === "close") {
      confirmModal.isOpen = false;
      this.setState({ confirmModal });
    }
  };
  _filterOnChange = ({ currentTarget }) => {
    const { renterList } = this.state;
    let filterRenterList = renterList;
    if (currentTarget.value === "Error") {
      filterRenterList = renterList.filter((each) => {
        return each.errors.length > 0;
      });
    }
    this.setState({
      filterRenterList: filterRenterList,
      filterStatus: currentTarget.value,
    });
  };
  _clearFilter = () => {
    this.setState({
      filterRenterList: this.state.renterList,
      filterStatus: "",
    });
  };

  // download sample sheet

  _createMultipleProviders = () => {
    let { renterList, confirmModal } = this.state;
    let isValidated = true;
    for (let i = 0; i < renterList.length; i++) {
      if (renterList[i].errors.length > 0) {
        console.log(i);
        isValidated = false;
        break;
      }
    }
    if (isValidated) {
      let data = [];
      renterList.forEach((provider) => {
        let addressSplit = provider.Location?.split(",") ?? [];
        data.push({
          name: {
            first: provider.Name?.split(" ")[0] ?? "",
            last: provider.Name?.split(" ")[1] ?? "",
          },
          email: provider.Email ?? "",
          password: provider.Password ?? "",
          confirmPassword: provider.Password ?? "",
          countryCode: provider["Country Code"] ?? "",
          phone: provider.Phone ?? "",
          location: provider.Location ?? "",
          latitude: provider.latitude ?? "",
          longitude: provider.longitude ?? "",
          languagePreference: provider["Language preference"] ?? "",
          dateOfBirth: provider["Date of Birth"] ?? "",
          userType: "Rentee",
          referralCode: null,
          isFirstLogin: null,
          address: {
            city: addressSplit[addressSplit.length - 2]?.trim() ?? "",
            country: addressSplit[addressSplit.length - 1]?.trim() ?? "",
          },
        });
      });
      console.log(data);

      this._manageLoading("isUploading", true);
      return AddMultipleProviders({ rentees: data }).then(
        (response) => {
          console.log(response);

          ToastsStore.success("Successfully added new providers!", 3000);
          this._manageLoading("isUploading", false);
          this.props.history.push("providers");
        },
        (error) => {
          console.log(error);

          ToastsStore.error(error?.reason?.en || "Something Went Wrong!", 3000);
          this._manageLoading("isUploading", false);
        }
      );
    } else {
      confirmModal.isOpen = true;
      confirmModal.data = "Please clear all the errors";
      confirmModal.isArray = false;
      this.setState({ confirmModal });
    }
  };
  render() {
    let { providerDetails, confirmModal } = this.state;
    return (
      <div className="animated fadeIn">
        {/* <div style={{ display: "flex", width: "100vw" }}>
          <div style={{ flex: "1", textAlign: "center" }}>
            <span> Add provider</span>
          </div>
          <div style={{ flex: "1", textAlign: "center" }}>
            Add multiple providers
          </div>
        </div> */}
        <div className="page-heading">
          <h2>{this.state.section}</h2>
        </div>
        {this.state.section === "Add provider" ? (
          <Row className="table-panel booking-panel">
            <Col xs="12" sm="6">
              {!this.state.loadingData && providerDetails && (
                <Card>
                  <CardHeader>
                    <Button
                      color="primary"
                      onClick={() =>
                        this.setState({
                          section: "Add multiple providers",
                        })
                      }
                    >
                      Add Multiple Providers
                    </Button>
                    <Button color="secondary" onClick={this._backToDetails}>
                      <i className="fa fa-arrow-left"></i> &nbsp; Back
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this._handleOnSubmit} noValidate>
                      {/* <Row>
                      <Col xs="6">
                        <img
                          src={
                            providerDetails.pictureUrl ||
                            "../../assets/img/avatars/user.png"
                          }
                          alt="provider"
                          width="70"
                          className={
                            this.state.pictureLoading
                              ? "disbale-file-select"
                              : "img-avatar"
                          }
                        />
                        {this.state.pictureLoading && (
                          <i className="fa fa-spinner fa-spin uploading-image-loading"></i>
                        )}
                        {!this.state.pictureLoading && (
                          <span
                            onClick={this._openFileBrowser}
                            className="update-profile-image"
                          >
                            <i className="fa fa-pencil"></i>
                            <input
                              type="file"
                              id="file"
                              ref="fileUploader"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) =>
                                this._imageChange(e, "pictureUrl")
                              }
                            />
                          </span>
                        )}
                      </Col>
                      <Col xs="6" className="app-switch-panel">
                        <span>Status :</span>
                        <AppSwitch
                          className={"mx-1 details-status-switch"}
                          variant={"3d"}
                          outline={"alt"}
                          color={"primary"}
                          label
                          dataOn={"Active"}
                          dataOff={"Inactive"}
                          checked={providerDetails.isActive}
                          onChange={this._onStatusChange}
                          disabled={this.state.changeStatusLoading}
                        />
                      </Col>
                    </Row> */}
                      <Row>
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="name">Name</Label>
                            <Input
                              type="text"
                              id="name"
                              name="fullName"
                              placeholder="Enter your name"
                              value={providerDetails.fullName}
                              onChange={this._handleOnChange}
                              className={
                                this.state.errors && this.state.errors.fullName
                                  ? "invalid-input"
                                  : "valid-input"
                              }
                            />
                            {this.state.errors && (
                              <div className="validation-error">
                                {this.state.errors.fullName}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="name">Email</Label>
                            <Input
                              type="text"
                              id="name"
                              name="email"
                              placeholder="Enter your Email"
                              value={providerDetails.email}
                              onChange={this._handleOnChange}
                              autoComplete="off"
                              className={
                                this.state.errors && this.state.errors.email
                                  ? "invalid-input"
                                  : "valid-input"
                              }
                            />
                            {this.state.errors && (
                              <div className="validation-error">
                                {this.state.errors.email}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="name">Password</Label>
                            <Input
                              type="text"
                              id="password"
                              name="password"
                              placeholder="Enter password"
                              value={providerDetails.password}
                              onChange={this._handleOnChange}
                              className={
                                this.state.errors && this.state.errors.password
                                  ? "invalid-input"
                                  : "valid-input"
                              }
                            />
                            {this.state.errors && (
                              <div className="validation-error">
                                {this.state.errors.password}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="name">Confirm Password</Label>
                            <Input
                              type="text"
                              id="confirmPassword"
                              name="confirmPassword"
                              placeholder="Confirm password"
                              value={providerDetails.confirmPassword}
                              onChange={this._handleOnChange}
                              autoComplete="off"
                              className={
                                this.state.errors &&
                                this.state.errors.confirmPassword
                                  ? "invalid-input"
                                  : "valid-input"
                              }
                            />
                            {this.state.errors && (
                              <div className="validation-error">
                                {this.state.errors.confirmPassword}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="3">
                          <FormGroup>
                            <Label htmlFor="countryCode">Country Code</Label>
                            <FormGroup>
                              <Input
                                type="select"
                                name="countryCode"
                                id="countryCode"
                                value={providerDetails.countryCode}
                                onChange={this._handleOnChange}
                              >
                                {this.state.countryCodeList.map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </FormGroup>
                        </Col>
                        <Col xs="3">
                          <FormGroup>
                            <Label htmlFor="name">Phone</Label>
                            <Input
                              type="text"
                              id="name"
                              name="phone"
                              placeholder="Enter your Phone Number"
                              value={providerDetails.phone}
                              onChange={this._handleOnChange}
                              className={
                                this.state.errors && this.state.errors.phone
                                  ? "invalid-input"
                                  : "valid-input"
                              }
                            />
                            {this.state.errors && (
                              <div className="validation-error">
                                {this.state.errors.phone}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="dateOfBirth">Date of Birth</Label>
                            <InputGroup
                              className={
                                this.state.errors &&
                                this.state.errors.dateOfBirth
                                  ? "invalid-input"
                                  : "valid-input"
                              }
                            >
                              <DatePicker
                                className="form-control"
                                selected={providerDetails.dateOfBirth}
                                name="dateOfBirth"
                                onSelect={this._handleDateChange}
                                dateFormat="MMM d, yyyy"
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fa fa-calendar"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                            {this.state.errors && (
                              <div className="validation-error">
                                {this.state.errors.dateOfBirth}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* <Row>
                  <Col xs="6">
                    <FormGroup>
                      <Label htmlFor="name">Company</Label>
                      <Input
                        type="text"
                        id="name"
                        placeholder="Enter your Company"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <Label htmlFor="name">Role</Label>
                      <Input
                        type="text"
                        id="name"
                        placeholder="Enter your Role"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row> */}
                      <Row>
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="name">Location</Label>
                            <InputGroup
                              className={
                                this.state.errors && this.state.errors.address
                                  ? "invalid-input"
                                  : "valid-input"
                              }
                            >
                              <Input
                                type="text"
                                id="input1-group1"
                                name="input1-group1"
                                placeholder="Enter your Location"
                                autoComplete="off"
                                value={providerDetails.address}
                                onChange={this._searchPlace}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fa fa-map-marker"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                            {this.state.errors && (
                              <div className="validation-error">
                                {this.state.errors.address}
                              </div>
                            )}
                            {this.state.locationSuggestionList.length !== 0 &&
                              this.state.showSuggestionList && (
                                <ul className="location-suggestion-ul">
                                  {this.state.locationSuggestionList.map(
                                    (each) => (
                                      <li
                                        key={each.placeId}
                                        onClick={() =>
                                          this._selectLocation(each)
                                        }
                                      >
                                        <i className="fa fa-map-marker"></i>
                                        &nbsp;
                                        {each.location}
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <Label htmlFor="languagePreference">
                              Languages
                            </Label>
                            <FormGroup>
                              <Input
                                type="select"
                                name="languagePreference"
                                id="languagePreference"
                                value={providerDetails.languagePreference}
                                onChange={this._handleOnChange}
                              >
                                {this.state.languageList.map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </FormGroup>
                        </Col>
                        {/* <Col xs="6">
                        <FormGroup>
                          <Label htmlFor="language">Photo ID Proof</Label>
                          <FormGroup>
                            <Input
                              type="file"
                              name="photoIdProofUrl"
                              id="photoIdProofUrl"
                              accept="image/*"
                              disabled={this.state.photoIdProofLoading}
                              onChange={(e) =>
                                this._imageChange(e, "photoIdProofUrl")
                              }
                            ></Input>
                          </FormGroup>
                          {this.state.photoIdProofLoading && (
                            <i className="fa fa-spinner fa-spin uploading-id-loading"></i>
                          )}
                        </FormGroup>
                        {providerDetails.photoIdProofUrl &&
                          !this.state.photoIdProofLoading && (
                            <a
                              href={providerDetails.photoIdProofUrl}
                              target="_blank"
                              download
                              rel="noopener noreferrer"
                            >
                              Photo ID Proof
                            </a>
                          )}
                      </Col> */}
                      </Row>
                      <Row className="submit-panel">
                        <Col xs="12">
                          <FormGroup>
                            <Button
                              type="submit"
                              color="primary"
                              disabled={this.state.updateLoading}
                            >
                              {this.state.updateLoading && (
                                <i className="fa fa-spinner fa-spin mr5"></i>
                              )}
                              Save
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              )}
              {this.state.loadingData && (
                <Card>
                  <CardBody>
                    <div className="loading-section no-border">
                      <i className="fa fa-spinner fa-spin"></i> &nbsp; Loading
                      Provider Details..
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        ) : (
          <Row>
            <Row className="table-panel">
              <Col xs="12" lg="12">
                <CardBody>
                  <div className="table-top">
                    <Form>
                      {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    > */}
                      <Button
                        color="primary"
                        onClick={() =>
                          this.setState({
                            section: "Add provider",
                          })
                        }
                      >
                        Add provider through portal
                      </Button>
                      <InputGroup className="m-2">
                        <Label
                          onChange={(e) => this._uploadSheet(e)}
                          htmlFor="importExcel"
                          style={{ cursor: "pointer" }}
                          disabled={
                            this.state.sheetValidationLoading ||
                            this.state.sheetUploadLoading
                          }
                        >
                          <span
                            style={{
                              backgroundColor: "#20a8d8",
                              color: "white",
                              borderRadius: "4px",
                              padding: "8px",
                            }}
                          >
                            <i
                              class="fa fa-file-excel-o"
                              aria-hidden="true"
                              style={{ paddingRight: "8px" }}
                            />
                            {this.state.sheetValidationLoading
                              ? "Validating..."
                              : "Import Sheet"}
                          </span>
                          <Input
                            type="file"
                            id="importExcel"
                            placeholder="0"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            name="excelFile"
                            disabled={
                              this.state.sheetValidationLoading ||
                              this.state.sheetUploadLoading
                            }
                            hidden
                          />
                        </Label>
                      </InputGroup>
                      <ExcelFile
                        element={
                          <Button type="button" color="primary">
                            <i
                              class="fa fa-download"
                              style={{ paddingRight: "8px" }}
                              aria-hidden="true"
                            />
                            Sample Sheet
                          </Button>
                        }
                        filename="Providers"
                      >
                        <ExcelSheet data={[]} name="Providers">
                          <ExcelColumn label="Name" value="Name" />
                          <ExcelColumn label="Email" value="Email" />
                          <ExcelColumn label="Password" value="Password" />
                          <ExcelColumn
                            label="Country Code"
                            value="Country Code"
                          />
                          <ExcelColumn label="Phone" value="Phone" />
                          <ExcelColumn label="Location" value="Location" />
                          <ExcelColumn
                            label="Language preference"
                            value="Language preference"
                          />
                          <ExcelColumn
                            label="Date of Birth"
                            value="Date of Birth"
                          />
                        </ExcelSheet>
                      </ExcelFile>
                      {/* <InputGroup className="mb-3">
                      <Label
                        onChange={(e) => this._downloadSampleSheet(e)}
                        htmlFor="importExcel"
                      >
                        <span
                          style={{
                            backgroundColor: "#20a8d8",
                            color: "white",
                            borderRadius: "6px",
                            padding: "10px",
                          }}
                        >
                          Sample Sheet
                        </span>
                      </Label>
                    </InputGroup> */}
                      <InputGroup className="mb-3">
                        <select
                          name="status"
                          id="status"
                          className="form-control"
                          value={this.state.filterStatus}
                          onChange={this._filterOnChange}
                        >
                          <option value="">Select Status</option>
                          <option key={"Error"} value={"Error"}>
                            Error
                          </option>
                        </select>
                      </InputGroup>
                      <InputGroup
                        className="mb-3 filter-icon"
                        onClick={this._clearFilter}
                      >
                        <i className="fa fa-eraser"></i>
                      </InputGroup>
                      <Button
                        className="m-3"
                        color="primary"
                        onClick={this._createMultipleProviders}
                        disabled={
                          this.state.sheetUploadLoading ||
                          this.state.sheetValidationLoading ||
                          this.state.renterList.length === 0
                        }
                      >
                        {this.state.sheetUploadLoading && (
                          <i className="fa fa-spinner fa-spin mr5"></i>
                        )}
                        Save
                      </Button>
                      {/* </div> */}
                    </Form>
                    {/* </div> */}
                    <CustomTable
                      tableData={
                        !this.state.sheetValidationLoading
                          ? this.state.filterRenterList ?? []
                          : []
                      }
                      headerKeys={this.state.headerKeys}
                      dataFormat={this._dataFormat}
                    ></CustomTable>
                  </div>
                </CardBody>
              </Col>
            </Row>
            <ConfirmAction
              isOpen={confirmModal.isOpen}
              data={confirmModal.data}
              isArray={confirmModal.isArray}
              toggle={this._onModalToggle}
            ></ConfirmAction>
          </Row>
        )}
      </div>
    );
  }
}

export default AddRenteeDetails;
