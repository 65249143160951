import React, { Component } from 'react';
import { format } from 'date-fns';
import moment from "moment";
import {
  Card,
  CardBody,
  Col,
  Row,
  // Table,
  Form,
  Input,
  // InputGroupAddon,
  // InputGroupText,
  InputGroup,
  // Button
} from "reactstrap";
import { getBookingModificationList } from "../../../http/http-calls";
import CustomTable from "../../../components/CustomTable";
import config from '../../../config';
import {Link} from 'react-router-dom';
// import { ToastsStore } from "react-toasts";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import  CustomDateRangePicker  from '../../../components/date-range-picker';

class RequestList extends Component {
  state={
    bookingRequests:[],
    bookingRequestBackup:[],
    headerKeys: [
      { id: "name", label: "Booking ID" },
      { id: "startDate", label: "Start Date" },
      { id: "placeName", label: "Place" },
      { id: "renterName", label: "Renter" },
      { id: "requestDate", label: "Request Date" },
      { id: "status", label: "Status" },
      { id: "action", label: "Action" }
    ],
    filters: {      
      status: "",
      dateRange: {
        startDate:null,
        endDate:null,
        focusedInput:null,
        startDateId:'startDate',
        endDateId:'endDate'
      },
      search: ""
    },
    bookingRequestStatus: config.bookingRequestStatus,
    loadingData: false
  }

  _formatDate(date){
    return format(new Date(date),'MMM d, yyyy');
  }

  _manageLoading = (key, value) => {
    let { loadingData } = this.state;
    if (key === "data") {
      loadingData = value;
    }
    this.setState({ loadingData });
  };


  _filterOnChange = ({ currentTarget }) => {
    let { filters } = this.state;
    filters[currentTarget.name] = currentTarget.value;
    this.setState({ filters });
    console.log(filters);
    this._filterbookingRequestList();
  };

  // _handleDateChange = (date) => {
  //   const { filters } = this.state;
  //   filters.startDate = date
  //   this.setState({ filters });
  //   console.log(filters);
  //   // this._filterbookingRequests();
  // }

  _getBookingModificationList=()=>{
    this._manageLoading('data',true);
    getBookingModificationList()
    .then((response)=> {
      console.log('response :', response);
      this._manageLoading('data',false);    
      let { bookingRequests } = this.state;
      bookingRequests = response.bookingRequests.map((each)=>{
        return {
          name: each._previousBooking.shortBookingId,
          startDate: each.startDate,
          placeName: each._property.buildingName,
          placeId: each._property._id,
          renterName: each._renter.name.full,
          renterId: each._renter._id,
          requestDate: each.createdAt,
          status: each.status,
          _id: each._id
        }
      })
      this.setState({ bookingRequests, bookingRequestBackup: bookingRequests });  
    },(error)=> {
      console.log('error :', error);
      this._manageLoading('data',false);  
    })
  }

  _goToRequestDetails=(id)=>{
    this.props.history.push('request-details/' + id);
  }

  _dataFormat = (cell, row, header) => {
    // console.log(cell, row, header);
    if (header === "placeName") {
      return <Link to={`./place-details/${row.placeId}`}>{cell}</Link>
    } else if(header === "renterName"){
      return <Link to={`./renter-details/${row.renterId}`}>{cell}</Link>
    } else if(header === "startDate" || header === "requestDate"){
      return <span>{this._formatDate(cell)}</span>
    } else if (header === "name") {
      return cell.toUpperCase();
    }else if (header === "action") {
      return (
        <React.Fragment>
          <span>
            <i
              className="fa fa-eye cursor-pointer"
              aria-hidden="true"
              onClick={() => this._goToRequestDetails(row._id)}
            ></i>
          </span>         
        </React.Fragment>
      );
    } else {
      return cell;
    }
  };

  _filterbookingRequestList = () => {
    let filterConditions = [];
    // console.log(this.state);
    let { filters, bookingRequestBackup, bookingRequests } = this.state;
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        filterConditions.push(key);
      }
    });
    console.log(filterConditions);
    bookingRequests = bookingRequestBackup;
    if (filterConditions.length) {      
      if (filters.status) {
        bookingRequests = bookingRequests.filter(each => {
          return each.status === filters.status;
        });
      }
      if(filters.dateRange.startDate && filters.dateRange.endDate){
        bookingRequests =bookingRequests.filter(each => {
           // console.log('====>>',filters.dateRange.startDate.toDate(), each.date, isAfter((filters.dateRange.startDate.toDate()), each.date))
           // return (filters.dateRange.startDate.toDate()) >= new Date(each.date);
           return moment(moment(each.requestDate).format('YYYY-MM-DD')).isBetween(filters.dateRange.startDate.format('YYYY-MM-DD'), filters.dateRange.endDate.format('YYYY-MM-DD'), null, '[]');
         });
       }
      if (filters.search.trim().length) {
        bookingRequests = bookingRequests.filter(each => {
          return (
            each.placeName.toLowerCase().includes(filters.search.toLowerCase()) ||
            each.renterName.toLowerCase().includes(filters.search.toLowerCase()) ||
            each.name.toLowerCase().includes(filters.search.toLowerCase())
          );
        });
      }      
      this.setState({ bookingRequests });
    } else {
      this.setState({ bookingRequests: bookingRequestBackup });
    }
  };

  _clearFilter = () => {
    let { filters } = this.state;
    filters = {
      status: "",
      dateRange: {
        startDate:null,
        endDate:null,
        focusedInput:null,
        startDateId:'startDate',
        endDateId:'endDate'
      },
      search: ""
    };
    this.setState({ filters }, () => {
      this._filterbookingRequestList();
    });
  };

  _onDatesChange=(startDate, endDate)=>{
    console.log('on date change', startDate, endDate);
    let { filters } = this.state;
    filters.dateRange.startDate = startDate;
    filters.dateRange.endDate = endDate;
    this.setState({ filters });
    if(filters.dateRange.startDate && filters.dateRange.endDate){
      console.log('range selction complete');
      this._filterbookingRequestList();
    }
  }

  _onFocusChange = (input) =>{
    console.log(input);
    let { filters } = this.state;
    filters.dateRange.focusedInput = input;
    this.setState({ filters })
  }

  componentDidMount(){
    console.log('getBookingModificationList :::::::::');    
    this._getBookingModificationList();
  }

  
  
  render() { 
    const { bookingRequests, headerKeys, filters, loadingData } = this.state;    
    return ( <div className="animated fadeIn">
    <div className="page-heading">
      <h2>Booking Modification Requests</h2>
    </div>
    <Row className="table-panel">
      <Col xs="12" lg="12">
        <Card>
        {!loadingData &&<CardBody>
          <div className="table-top">
              <Form onSubmit={this.handleOnSubmit} noValidate>                               
                {/* <InputGroup className="mb-4 booking-start-date-filter">
                  <DatePicker className="form-control" 
                  autoComplete="off"
                  placeholderText="Select Date"
                  selected={this.state.filters.startDate} name="startDate"
                  onSelect={this._handleDateChange} 
                  dateFormat="MMM d, yyyy"
                    />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="fa fa-calendar"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  </InputGroup> */}
                Request Date : &nbsp;
                <CustomDateRangePicker dateRange={filters.dateRange} onDatesChange={this._onDatesChange}
                     onFocusChange={this._onFocusChange}></CustomDateRangePicker>  
                <InputGroup className="mb-3">
                  <select
                    name="status"
                    id="status"
                    className="form-control"
                    value={this.state.filters.status}
                    onChange={this._filterOnChange}
                  >
                    <option value="">Select Status</option>
                    {this.state.bookingRequestStatus.map((each)=>
                        <option key={each} value={each}>{each}</option>
                    )}
                  </select>
                </InputGroup>  
                <InputGroup className="mb-3">
                  <Input
                    type="text"
                    name="search"
                    placeholder="Search"
                    autoComplete="off"
                    value={this.state.filters.search}
                    onChange={this._filterOnChange}
                  />
                </InputGroup>                                      
                <InputGroup className="mb-3 filter-icon" onClick={this._clearFilter}>
                  {/* <i className="fa fa-filter" aria-hidden="true"></i> */}
                  <i className="fa fa-eraser"></i>
                </InputGroup>                     
              </Form>
          </div>                        
          <CustomTable
            tableData={bookingRequests}
            headerKeys={headerKeys}
            dataFormat={this._dataFormat}
          ></CustomTable>            
          </CardBody>}
          { loadingData && 
            <CardBody> 
            <div className="loading-section list-loading">
              <i className="fa fa-spinner fa-spin"></i> &nbsp;
              Loading Booking Requests..
            </div> 
            </CardBody>
          }
        </Card>
      </Col>
    </Row>
  </div> );
  }
}
 
export default RequestList;
