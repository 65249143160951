import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Row,
  // Table,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AppSwitch } from "@coreui/react";
import { getAllRentees, changeUserStatus } from "../../../http/http-calls";
import CustomTable from "../../../components/CustomTable";
import config from "../../../config/index";
import { ToastsStore } from "react-toasts";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class Rentee extends Component {
  state = {
    preferenceList: config.preferenceList,
    languageList: config.languageList,
    userStatusList: config.userStatusList,
    providerList: [],
    providerListBackup: [],
    headerKeys: [
      { id: "name", label: "Name" },
      { id: "phone", label: "Phone" },
      { id: "email", label: "Email" },
      { id: "booking", label: "bookings" },
      { id: "places", label: "Places" },
      { id: "languagePreference", label: "Language Preference" },
      { id: "action", label: "Action" },
    ],
    loadingData: false,
    changeStatusLoading: false,
    filters: {
      spacePreference: "",
      languagePreference: "",
      isActive: "",
      search: "",
    },
  };
  /**
   * Go to rentee details page by provider ID
   * @memberof Rentee
   */
  _gotoRenteeDetails = (providerId) => {
    this.props.history.push("provider-details/" + providerId);
  };
  _gotoAddProvider = () => {
    this.props.history.push("add-provider");
  };

  /**
   * Get all rentee
   * @memberof Rentee
   */
  _getAllRentees() {
    this._manageLoading("data", true);
    getAllRentees().then(
      (response) => {
        console.log(response);
        this._manageLoading("data", false);
        let providerList = response.rentees.map((each) => {
          return {
            id: each._id,
            name: each.name.full,
            email: each.email,
            phone:
              each.countryCode && each.phone
                ? each.countryCode + " " + each.phone
                : "N/A",
            address: each.address
              ? each.address.city + ", " + each.address.country
              : "Not Provided",
            booking: each._booking ? each._booking.length : 0,
            places: each._property.length,
            isActive: each.isActive,
            languagePreference: each.languagePreference,
            action: "",
          };
        });
        this.setState({ providerList, providerListBackup: providerList });
      },
      (error) => {
        console.log(error);
        this._manageLoading("data", false);
      }
    );
  }

  componentDidMount() {
    this._getAllRentees();
  }

  /**
   * To manage loading
   * @memberof Rentee
   */
  _manageLoading = (key, value) => {
    let { loadingData, changeStatusLoading } = this.state;
    if (key === "data") {
      loadingData = value;
    } else if (key === "change-status") {
      changeStatusLoading = value;
    }
    this.setState({ loadingData, changeStatusLoading });
  };

  /**
   * On Status Change
   * @memberof Rentee
   */
  _onStatusChange = (row) => {
    console.log(row);
    row.isActive = !row.isActive;
    this._manageLoading("change-status", true);
    changeUserStatus(row.id, {
      status: row.isActive,
    }).then(
      (response) => {
        console.log(response);
        // this.setState({ row });
        this._manageLoading("change-status", false);
        ToastsStore.success("Status updated successfully!", 3000);
      },
      (error) => {
        console.log(error);
        this._manageLoading("change-status", false);
        ToastsStore.error(error.reason, 3000);
      }
    );
  };

  /**
   * Custom column format for according to header
   * @memberof Rentee
   */
  _dataFormat = (cell, row, header) => {
    // console.log(cell, row, header);
    if (header === "booking") {
      return cell ? (
        <Link to={`/dashboard/booking?provider=${row.id}`}>{cell}</Link>
      ) : (
        "No Bookings yet"
      );
    } else if (header === "places") {
      return cell ? (
        <Link to={`/dashboard/properties?provider=${row.id}`}>{cell}</Link>
      ) : (
        "No Places yet"
      );
    } else if (header === "action") {
      return (
        <React.Fragment>
          <AppSwitch
            className={"mx-1 status-switch"}
            variant={"3d"}
            outline={"alt"}
            color={"primary"}
            checked={row.isActive}
            label
            dataOn={"Active"}
            dataOff={"Inactive"}
            onChange={() => this._onStatusChange(row)}
            disabled={this.state.changeStatusLoading}
          />
          <span>
            <i
              className="fa fa-eye cursor-pointer"
              aria-hidden="true"
              onClick={() => this._gotoRenteeDetails(row.id)}
            ></i>
          </span>
        </React.Fragment>
      );
    } else {
      return cell;
    }
  };

  _filterOnChange = ({ currentTarget }) => {
    let { filters } = this.state;
    filters[currentTarget.name] = currentTarget.value;
    this.setState({ filters });
    this._filterProviderList();
  };

  _filterProviderList = () => {
    let filterConditions = [];
    // console.log(this.state);
    let { filters, providerListBackup, providerList } = this.state;
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filterConditions.push(key);
      }
    });
    console.log(filterConditions);
    providerList = providerListBackup;
    if (filterConditions.length) {
      if (filters.languagePreference) {
        providerList = providerList.filter((each) => {
          return each.languagePreference === filters.languagePreference;
        });
      }
      if (filters.isActive) {
        providerList = providerList.filter((each) => {
          return each.isActive === JSON.parse(filters.isActive);
        });
      }
      if (filters.search.trim().length) {
        providerList = providerList.filter((each) => {
          return (
            each.name.toLowerCase().includes(filters.search.toLowerCase()) ||
            each.email.toLowerCase().includes(filters.search.toLowerCase()) ||
            each.phone.toLowerCase().includes(filters.search.toLowerCase())
          );
        });
      }
      this.setState({ providerList });
    } else {
      this.setState({ providerList: providerListBackup });
    }
  };

  _clearFilter = () => {
    let { filters } = this.state;
    filters = {
      spacePreference: "",
      languagePreference: "",
      isActive: "",
      search: "",
    };
    this.setState({ filters }, () => {
      this._filterProviderList();
    });
  };

  render() {
    return (
      <div className="animated fadeIn">
        <div className="page-heading">
          <h2>Provider</h2>
        </div>
        <Row className="table-panel">
          <Col xs="12" lg="12">
            <Card>
              {!this.state.loadingData && (
                <CardBody>
                  <div className="table-top">
                    <Form onSubmit={this.handleOnSubmit} noValidate>
                      <InputGroup className="mb-3">
                        <Input
                          type="text"
                          name="search"
                          placeholder="Search"
                          autoComplete="search"
                          value={this.state.filters.search}
                          onChange={this._filterOnChange}
                        />
                      </InputGroup>
                      {/* <InputGroup className="mb-3">
                      <select
                        name="select"
                        id="preference"
                        className="form-control"
                      >
                        <option value="0">Select Preference</option>
                        <option value="1">Hot Desk</option>
                        <option value="2">Fixed Desk</option>
                        <option value="3">Private Office</option>
                        <option value="4">Conference Room</option>
                      </select>
                    </InputGroup> */}
                      <InputGroup className="mb-3">
                        <select
                          id="language"
                          name="languagePreference"
                          className="form-control"
                          value={this.state.filters.languagePreference}
                          onChange={this._filterOnChange}
                        >
                          <option value="">Select Language</option>
                          {this.state.languageList.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <select
                          id="status"
                          name="isActive"
                          className="form-control"
                          value={this.state.filters.isActive}
                          onChange={this._filterOnChange}
                        >
                          <option value="">Status</option>
                          {this.state.userStatusList.map((item) => (
                            <option
                              key={item}
                              value={item === "Active" ? true : false}
                            >
                              {item}
                            </option>
                          ))}
                        </select>
                      </InputGroup>
                      <InputGroup
                        className="mb-3 filter-icon"
                        onClick={this._clearFilter}
                      >
                        {/* <i className="fa fa-filter" aria-hidden="true"></i> */}
                        <i className="fa fa-eraser"></i>
                      </InputGroup>
                      &nbsp;&nbsp;
                      <ExcelFile
                        element={
                          <Button type="button" color="primary">
                            Export Data
                          </Button>
                        }
                        filename="Providers"
                      >
                        <ExcelSheet
                          data={this.state.providerList}
                          name="Providers"
                        >
                          <ExcelColumn label="Name" value="name" />
                          <ExcelColumn label="Email" value="email" />
                          <ExcelColumn label="Phone" value="phone" />
                          <ExcelColumn label="Bookings" value="booking" />
                          <ExcelColumn label="Places" value="places" />
                          <ExcelColumn
                            label="Language Preference"
                            value="languagePreference"
                          />
                          <ExcelColumn
                            label="Status"
                            value={(col) =>
                              col.isActive ? "Active" : "InActive"
                            }
                          />
                        </ExcelSheet>
                      </ExcelFile>
                      &nbsp; &nbsp;
                      <Button
                        type="button"
                        color="primary"
                        onClick={() => this._gotoAddProvider()}
                      >
                        Add Provider
                      </Button>
                    </Form>
                  </div>

                  <CustomTable
                    tableData={this.state.providerList}
                    headerKeys={this.state.headerKeys}
                    dataFormat={this._dataFormat}
                  ></CustomTable>
                </CardBody>
              )}
              {this.state.loadingData && (
                <CardBody>
                  <div className="loading-section list-loading">
                    <i className="fa fa-spinner fa-spin"></i> &nbsp; Loading
                    Provider List..
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Rentee;
